import { IDevice } from '@piceasoft/core';
import { AIGradingImageTypes } from "@piceasoft/core";
import { DeviceTypes } from "@piceasoft/core";

export const endpoints = {
    v1: {
        common: {
            devices: {
                devices: "v1/devices",
                manufacturers: "v1/devices/manufacturers",
                models: (manufacturer: string): string => {
                    return "v1/devices/models/" + manufacturer
                },
                configurations: "v1/devices/configurations"
            }
        },
        postSticker: "http://localhost:5145/print",
        requestSignIn: "v1/account/sign-in",
        requestSignOut: "v1/account/sign-out",
        profileLanguage: (newLanguage: string): string => {
            return "v1/account/profile/language/" + newLanguage
        },
        requestWorkplace: "v1/workplace",
        getEnvironmentConfiguration: "/v1/environment/configuration",
        getJsApiLink: (processId: number) => "/v1/environment/jsapi/" + processId.toString(),
        getServiceHub: (channelId: number) => "v1/workplace/services/channelId/" + channelId,
        service: (id: string) => "/v1/workplace/services/" + id,
        // grade parameter is added to the method, in order to be able to get the final price
        // from Trading Vendor API in case of using Grade categories
        offerProviderOffers: "v1/offers/provider",
        notifyProvider: (processId: number, transactionId: string, providerCode: string, controlledTransactionNumber: string | null) => {
            return `v1/offers/notify/${processId}/${transactionId}/${providerCode}/${controlledTransactionNumber}`    
        },
        getProviderPromo: (processId: number, providerCode: string, device: IDevice, grade?: string) => {
            const params = new URLSearchParams();
            if (device.manufacturer) {
                params.append("manufacturer", device.manufacturer);
            }
            if (device.name) {
                params.append("device", device.name);
            }
            if (device.configuration) {
                params.append("configuration", device.configuration);
            }
            if (device.group) {
                params.append("group", device.group);
            }
            if (device.attributes && device.attributes['imei']) {
                params.append("imei", device.attributes['imei']);
            }
            if (device.attributes && device.attributes['sn']) {
                params.append("sn", device.attributes['sn']);
            }
            if (grade) {
                params.append("grade", grade);
            }
            return `v1/promotions/provider/${processId}/${providerCode}/?` + params.toString()
        },
        setControlTransactionId: (transactionId: string, controlTransactionId: string) => "v1/transaction/set_control_transaction/" + transactionId + "/" + controlTransactionId,
        getTransaction: (uid: string) => "/v1/transaction/" + uid,
        getContract: (uid: string, templateId?: number) => {
            const params = new URLSearchParams();
            params.append('uid', uid)
            if(templateId){
                params.append("documentId", templateId.toString())
            }
            return "/v1/contract/?" + params.toString()
        },
        sendContractToCustomer: (uid: string, templateId?: number) => {
            const params = new URLSearchParams();
            params.append('uid', uid)
            if(templateId){
                params.append("documentId", templateId.toString())
            }
            return "/v1/contract/send/?" + params.toString()
        },
        aiGradingRemote: (moduleIndex: number, transactionUid: string, serviceId?: number, lang?: string) => {
            let params = new URLSearchParams();
            if (lang) {
                params.append('lang', lang)
            }
            if (serviceId) {
                params.append('serviceId', serviceId.toString())
            }
            return `${document.location.origin}/transaction/aigrading/${transactionUid}/${moduleIndex}/?` + params.toString()
        },
        grading: {
            ai: (transactionId: string, imageType: AIGradingImageTypes, rotateImage?: boolean) => {
                if (rotateImage === undefined)
                    rotateImage = false;

                return `/v1/grading/ai/${transactionId}/${imageType}/?rotateImage=${rotateImage}`
            },
            config: '/v1/grading/config'
        },
        identification: {
            imeiLockout: (imei: string, period: number) => {
                return `/v1/identification/imei_lockout/${imei}/${period.toString()}`
            },
            global: (search: string, serviceId?: number, types?: DeviceTypes[]) => {
                const params = new URLSearchParams();
                if (serviceId) {
                    params.append("serviceId", serviceId?.toString());
                }
                if (types) {
                    params.append("types", types.join("-"));
                }
                return "/v1/identification/global/" + search + "/?" + params.toString()
            },
            transaction: (search: string) => "/v1/identification/transaction/" + search,
            deviceCompletedTransactions: "/v1/identification/device_completed_transactions",
            scannerRemote: (processId: number, index: number, serviceId?: string, lang?: string) => {
                let params = new URLSearchParams();
                if (serviceId) {
                    params.append('serviceId', serviceId)
                }
                if (lang) {
                    params.append('lang', lang)
                }
                return `${document.location.origin}/identification/scanner/${processId}/${index}/?` + params.toString()
            },
            scannerRemoteUpload: (processId: number) => {
                return "/v1/identification/scanner_remote_upload/" + processId
            },
            imei: (imei: string, processId: number) => {
                return `/v1/identification/imei/${imei}/${processId}`
            },
            modelByImei: (imei: string, processId: number) => {
                return `/v1/identification/modelByImei/${imei}/${processId}`
            }
        },
        downloadPhoto: (url: string, name?: string): string => {
            let params = new URLSearchParams();
            params.append('url', url)
            if (name && name.trim().length > 0) {
                params.append('name', name)
            }
            console.log("/v1/files/download?" + params.toString());
            return "/v1/files/download?" + params.toString();
        },
        uploadFile: (container: string, noPreview?: boolean): string => {
            const params = new URLSearchParams();
            if (noPreview) {
                params.append("noPreview", "true")
            }
            return "/v1/files/upload/" + container + "?" + params.toString();
        },
        apiFile: (file_id: string) => {
            return "v1/files/api_download?fileId=" + file_id
        },
        process: {
            initializationRequest: (serviceId: number, link?: string): string => {
                const params = new URLSearchParams();
                if (link) {
                    params.append("link", link);
                }
                return "/v1/workplace/initialize/" + serviceId + "/?" + params.toString()
            },
            setLink: (processId: number): string => "/v1/workplace/process/" + processId,
            requestTransaction: (processId: number, discountId?: string, sku?: string): string => {
                const params = new URLSearchParams();
                if (discountId) {
                    params.append("discountId", discountId);
                }
                if (sku) {
                    params.append("sku", sku);
                }
                return "/v1/transaction/" + processId + "/?" + params.toString()
            },
            updateTransaction: (uid: string): string => "/v1/transaction/" + uid,
            addLog: (processId: number): string => "/v1/workplace/process/" + processId,
            requestOffer: (serviceId: number, processId: number, manufacturer: string, device: string, configuration: string, notify: boolean, group?: string, imei?: string, uid?: string): string => {
                const params = new URLSearchParams({
                    manufacturer: manufacturer,
                    device: device,
                    configuration: configuration,
                    notify: `${notify}`
                });
                if (group) params.append("group", group);
                if (imei) params.append("imei", imei);
                if (uid) params.append("uid", uid);
                return "/v1/offers/" + serviceId + "/" + processId + "/?" + params.toString();
            },
            addEvent: (uid: string): string => "/v1/transaction/event/" + uid,
            addEvents: (uid: string): string => "/v1/transaction/events/" + uid,
            session: {
                setId: "v1/process/session",
                clearId: (process_id: number) => "v1/process/session/" + process_id.toString(),
                save: (process_id: number, completed: boolean) => "v1/process/" + process_id.toString() + "/" + (completed.valueOf()),
                restore: (process_id: number) => "v1/process/" + process_id.toString()
            },
            photoModeration: (uid: string): string => `/v1/transaction/getModeration/${uid}`
            
        },
        notifications: {
            notify: (message: string, userId: string, title?: string, type?: string,): string => {
                const params = new URLSearchParams();
                params.append("message", message);
                if (userId) {
                    params.append("userId", userId);
                }
                if (title) {
                    params.append("title", title);
                }
                if (type) {
                    params.append("type", type);
                }
                return "/v1/notifications/notify" + "?" + params.toString();
            }
        },
        logs: (from?: string, to?: string): string => {
            const params = new URLSearchParams();
            if (from && to) {
                params.append("from", from)
                params.append("to", to)
            }
            return "/v1/logs" + "?" + params.toString();
        },
        picea: {
            diagnostics: (uid: any): string => "v1/picea/reporting/diagnostics/" + uid,
            verify: (uid: any): string => "v1/picea/reporting/verify/" + uid
        },
        overview: {
            transaction: (uid: string): string => {
                return "/v1/transaction/" + uid
            },
            transactionTechLog: (transactionId: string): string => {
                return "/v1/transaction/tech_log/" + transactionId
            }
        },
        googleMaps: {
            getApiKey: "/v1/googlemaps/getApiKey"
        }
    },
    widget: {
        getRunOptions: (serviceId: string) : string => {
            return `/widget/get-run-options?serviceHash=${serviceId}`
        }
    },
    validationjwt: {
        requestValidationJwtToken: "/v1/picea/validation/grading_api_token",
    },
    webDiagnostics: {
        getOptions: (serviceId: string) : string => {
            return `/webdgs/get-options?serviceHash=${serviceId}`
        }
    }
}
