import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { useSelector } from 'react-redux'
import { IStore } from '../core/store'
import Layout from '../components/layout/Layout'
import { IStackStyles, Spinner, Stack } from "@fluentui/react";

const WidgetContainer = lazy(() => import("../screens/widget"));
const Warehouse = lazy(() => import('../screens/warehouse'))
const Upload = lazy(() => import('../components/process/Upload'))
const Login = lazy(() => import('../screens/login'))
const Log = lazy(() => import('../screens/logScreen'))
const Home = lazy(() => import('../screens/home'))
const Process = lazy(() => import('../screens/process'))
const ScannerRemoteUpload = lazy(() => import('../components/process/identifications/scanner/ScannerRemoteUpload'))
const AIGradingRemoteUpload = lazy(() => import('../components/process/inspections/aigrading/pivots/AIGradingRemoteUpload'))
const WebBasedDgs = lazy(() => import( '../screens/webbaseddevicecheck/WebBasedDgs'))

type TProps = {
    isAuthenticated: boolean
}

const spinnerStyles: IStackStyles = {
    root: {
        minWidth: 320,
        paddingTop: 4,
    }
};
const SpinnerCentered = () => (
    <Stack
        grow
        verticalAlign="center"
        horizontalAlign="center"
        styles={ spinnerStyles }
    >

        <Spinner/>
    </Stack>
);
const MainRouter: React.FC<TProps> = (props) => {

    const options = useSelector((s: IStore) => s.workplace.options)

    let routePaths = ['/', '/index.html', '/process/:id', '/process/restore/:session_id/:connection_type'];

    if (options?.history?.enabled) {
        routePaths.push('/log');
    }

    if (options?.warehouse?.enabled) {
        routePaths.push('/warehouse');
    }

    return (
        <Suspense fallback={<SpinnerCentered />}>
            <Switch>
                <Route exact path={routePaths}>
                    {props.isAuthenticated ? (
                        <Layout>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/index.html' component={Home} />
                            <Route exact path='/process/restore/:session_id/:connection_type' render={(props) => {
                                return (
                                    <Process
                                        session_id={props.match.params.session_id}
                                        connection_type={Number.parseInt(props.match.params.connection_type)}
                                    />
                                )
                            }} />
                            <Route exact path='/process/:id' component={Process} />
                            <Route exact path='/log' component={Log} />
                            <Route exact path='/warehouse' component={Warehouse} />
                        </Layout>
                    ) : (
                        <Route render={(props) => {
                            const search = props.location.search
                            const params = new URLSearchParams(search)
                            const domain = params.get('domain')
                            return (
                                <Login domain={domain ?? undefined} />
                            )
                        }} />
                    )}
                </Route>
                <Route exact path={['/photographic/:transactionUid']}>
                    <Route exact path='/photographic/:transactionUid' render={(props) => {
                        const search = props.location.search
                        const params = new URLSearchParams(search)
                        const serviceId = params.get('processId')
                        const lang = params.get('lang')
                        const indexStr = params.get('index');
                        // parse index, defaults to 1
                        const index = indexStr !== null ? Number.parseInt(indexStr) : 1;
                        return <Upload transactionUid={props.match.params.transactionUid} serviceId={serviceId} lang={lang ?? undefined} index={index} />
                    }} />
                </Route>
                <Route exact path={['/identification/scanner/:processId/:index']}>
                    <Route exact path='/identification/scanner/:processId/:index' render={(props) => {
                        const search = props.location.search
                        const params = new URLSearchParams(search)
                        const serviceId = params.get('serviceId')
                        const lang = params.get('lang')
                        return <ScannerRemoteUpload
                            processId={Number.parseInt(props.match.params.processId)}
                            serviceId={serviceId ? Number.parseInt(serviceId) : null}
                            index={Number.parseInt(props.match.params.index)}
                            lang={lang ?? undefined} />
                    }} />
                </Route>
                <Route exact path={['/transaction/aigrading/:transactionUid/:moduleIndex']}>
                    <Route exact path='/transaction/aigrading/:transactionUid/:moduleIndex' render={(props) => {
                        const search = props.location.search
                        const params = new URLSearchParams(search)
                        const lang = params.get('lang')
                        const serviceId = params.get('serviceId')
                        return <AIGradingRemoteUpload
                            index={Number.parseInt(props.match.params.moduleIndex)}
                            transactionUid={props.match.params.transactionUid}
                            serviceId={Number.parseInt(serviceId ?? "")}
                            lang={lang ?? undefined} />
                    }} />
                </Route>
                <Route exact path={['/widget']}>
                    <Route exact path='/widget' render={(props) => {
                        const search = props.location.search
                        const params = new URLSearchParams(search)
                        const workflow = params.get('workflow')
                        const sessionId = params.get('session_id')
                        const authToken = params.get('token')
                        const serviceId = params.get('service_id')
                        const deviceId = params.get('device_id')
                        const colorPalette = params.get('colorPalette') as 'light' | 'dark' | 'auto' | undefined
                        return (
                            <WidgetContainer
                                workflow={workflow ?? undefined}
                                sessionId={sessionId ?? undefined}
                                deviceId={deviceId ?? undefined}
                                workflowId={serviceId ?? undefined}
                                authToken={authToken ?? undefined}
                                colorPalette={colorPalette ?? 'light'}
                            />
                        )
                    }} />
                </Route>
                <Route exact path={['/webdgs']}>
                    <Route exact path='/webdgs' render={(props) => {
                        const search = props.location.search
                        const params = new URLSearchParams(search)
                        const sessionId = params.get('session_id')
                        const serviceId = params.get('service_id')
                        const procId = params.get( 'process_id');
                        const lang = params.get( "lang");
                        return <WebBasedDgs sessionId={ sessionId ?? undefined } 
                                            workflowId={ serviceId ?? undefined }
                                            processId={ procId ?? undefined}
                                            lang={ lang ?? undefined}
                                />
                    }} />
                </Route>
            </Switch>
        </Suspense>
    )
}

export default MainRouter
