import {
    IProcess, IStore, ContentTransferStages
} from ".";
import { IControl, IContract, IGrade, IResultConfig, IResourcesConfig, IPreOfferConfig, IPostOfferConfig, IIdentification, IInspectionConfig, IInspectionModuleConfig, IContractConfig, IStageRequirementsConfig, IAssessmentConfig, CatalogTypes } from '@piceasoft/core';
import { strings } from "../../localization/strings";
import { PiceaConnectionTypes } from "../scripts/picea.typings";
import { IWorkflow, IInspectionModuleState } from "@piceasoft/core";
import { IInspectionConfigUI, IIdentificationConfig, IControlConfig, ProcessStages, PiceaConnectionStatuses, IAssessment } from "@piceasoft/core";
import { IContentTransferState } from "./typings/IContentTransferState";
export const selector = {
    process: {
        getModuleState: <T>(state: IStore, index: number): T => {
            return state.process.current?.transaction.assessment.modules.find(i => i.index === index)?.state as unknown as T
        },
        // getModuleCommonState: (state: IStore, index: number): IInspectionModuleState | undefined => {
        //     return state.process.current?.transaction.assessment.modules.find(i => i.index === index)?.state as unknown as IInspectionModuleState
        // },
        getAssessmentGrade: (state: IStore): IGrade | undefined => {
            return state.process.current?.workflow.grades.find(i => i.code === state.process.current?.transaction.assessment.grade);
        },
        getCatalogType: (state: IStore): CatalogTypes => {
            if (!state.process.current?.workflow) return CatalogTypes.Tradein;
            /** if flow is using grade DiscountCatalogs, consider catalog type as GradeDiscount, otherwise - Tradein */
            return state.process.current?.workflow?.useDiscountCatalogs ? CatalogTypes.GradeDiscount : CatalogTypes.Tradein;
        },
        getCancelOptions: (state: IStore): Array<string> | undefined => {
            switch (state.process.current?.stage) {
                case ProcessStages.Identification:
                    return state.process.current?.workflow.identification.cancelOptions;
                case ProcessStages.Assessment:
                    return state.process.current?.workflow.assessment?.cancelOptions;
                case ProcessStages.Contract:
                    return state.process.current?.workflow.contract?.cancelOptions;
                case ProcessStages.Control:
                    return state.process.current?.workflow.control?.cancelOptions;
            }
        },
        getCancelText: (state: IStore): string => {
            switch (state.process.current?.stage) {
                case ProcessStages.Resources: return state.process.current?.workflow?.resources?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                case ProcessStages.Identification: return state.process.current?.workflow?.identification?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                case ProcessStages.PreOffer: return state.process.current?.workflow?.preOffer?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                case ProcessStages.Assessment: return state.process.current?.workflow?.assessment?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                case ProcessStages.PostOffer: return state.process.current?.workflow?.postOffer?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                case ProcessStages.Contract: return state.process.current?.workflow?.contract?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                case ProcessStages.Control: return state.process.current?.workflow?.control?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                //  Content transfer is run as a single "stage", but the device connection and selection part is configured as Identification
                //  So deeper content transfer state information is needed to determine if text should come from Identification or ContentTransfer
                case ProcessStages.ContentTransfer:
                    if( state.process.current?.contentTransfer?.stage !== undefined && state.process.current?.contentTransfer?.stage !== ContentTransferStages.DeviceSelections) // device selection done
                        return state.process.current?.workflow?.contentTransfer?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                    else
                        return state.process.current?.workflow?.identification?.ui?.buttons?.cancel?.text ?? strings.PROCESS.TRANSACTION.CANCEL
                default: return strings.PROCESS.TRANSACTION.CANCEL
            }
        },
        getBackText: (state: IStore): string => {
            switch (state.process.current?.stage) {
                //  Content transfer is run as a single "stage", but the device connection and selection part is configured as Identification
                //  So deeper content transfer state information is needed to determine if text should come from Identification or ContentTransfer
                //  For content transfer, there is customizable back button only in device selection.
                case ProcessStages.ContentTransfer:
                    if( state.process.current?.contentTransfer?.stage !== undefined && state.process.current?.contentTransfer?.stage !== ContentTransferStages.DeviceSelections) // device selection done
                        return strings.BUTTONS.TEXT.BACK;
                    else
                        return state.process.current?.workflow?.identification?.ui?.buttons?.back?.text ?? strings.BUTTONS.TEXT.BACK;
            }
            return strings.BUTTONS.TEXT.BACK;
        },
        getTransactionTitle: (state: IStore): string | undefined => {
            switch (state.process.current?.stage) {
                case ProcessStages.Resources: return state.process.current?.workflow?.resources?.ui?.stageTitle ?? strings.PROCESS.TRANSACTION.RESOURCES
                case ProcessStages.Identification: return state.process.current?.workflow?.identification?.ui?.stageTitle ?? strings.PROCESS.TRANSACTION.IDENTIFICATION
                case ProcessStages.PreOffer: return state.process.current?.workflow?.preOffer?.ui?.stageTitle ?? strings.PROCESS.TRANSACTION.PRE_OFFER
                case ProcessStages.Contract: {
                    const val = state.process.current?.workflow?.contract?.ui?.stageTitle
                    if( val)
                        return val;
                    return state.process.current?.transaction.number ? strings.PROCESS.TRANSACTION.NUMBER_SIGN + state.process.current?.transaction.number : undefined
                }
                default: return state.process.current?.transaction.number ? strings.PROCESS.TRANSACTION.NUMBER_SIGN + state.process.current?.transaction.number : undefined
            }
        },
        getNextText: (process: IProcess | undefined, isLastStage: boolean): string => {
            let text: string
            if( process == undefined)
                return strings.PROCESS.TRANSACTION.CLOSE_TRANSACTION;
            switch (process.stage) {
                case ProcessStages.Resources:
                    text = process.workflow.resources?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Identification:
                    text = process.workflow.identification?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.PreOffer:
                    text = process.workflow.preOffer?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Assessment:
                    text = process.workflow.assessment?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.PostOffer:
                    text = process.workflow.postOffer?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Contract:
                    text = process.workflow.contract?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Control:
                    text = process.workflow.control?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Result:
                    text = process.workflow.result?.ui?.buttons?.next?.text ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.CLOSE_TRANSACTION);
                    break;
                //  Content transfer is run as a single "stage", but the device connection and selection part is configured as Identification
                //  So deeper content transfer state information is needed to determine if text should come from Identification or ContentTransfer
                case ProcessStages.ContentTransfer:
                    if( process?.contentTransfer?.stage !== undefined && process?.contentTransfer?.stage !== ContentTransferStages.DeviceSelections) // device selection done
                        text = process.workflow.contentTransfer?.ui?.buttons?.next?.text ?? strings.PROCESS.TRANSACTION.CONTINUE;
                    else
                        text = process.workflow.identification?.ui?.buttons?.next?.text ?? strings.PROCESS.TRANSACTION.CONTINUE;
                    break;
                default:
                    text = strings.PROCESS.TRANSACTION.CLOSE_TRANSACTION;
                    break;
            }

            return text;
        },
        getNextTooltipText: (stage: ProcessStages, workflow: IWorkflow, isLastStage: boolean): string => {
            let text: string
            switch (stage) {
                case ProcessStages.Resources:
                    text = workflow.resources?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Identification:
                    text = workflow.identification?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.PreOffer:
                    text = workflow.preOffer?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Assessment:
                    text = workflow.assessment?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE_REGISTRATION : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.PostOffer:
                    text = workflow.postOffer?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE_REGISTRATION : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Contract:
                    text = workflow.contract?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE_REGISTRATION : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Control:
                    text = workflow.control?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE_REGISTRATION : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                case ProcessStages.Result:
                    text = workflow.result?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE_REGISTRATION : strings.PROCESS.TRANSACTION.CLOSE_TRANSACTION);
                    break;
                case ProcessStages.ContentTransfer:
                    text = workflow.identification?.ui?.buttons?.next?.tooltip ?? (!isLastStage ? strings.PROCESS.TRANSACTION.CONTINUE : strings.PROCESS.TRANSACTION.FINISH_REGISTRATION);
                    break;
                default:
                    text = strings.PROCESS.TRANSACTION.CLOSE_TRANSACTION;
                    break;
            }

            return text;
        },
        getNextTeachingText: (stage: ProcessStages | undefined, workflow: IWorkflow | undefined, isLastStage: boolean): string | undefined => {
            if( stage === undefined || workflow === undefined) 
                return undefined;
            if (!isLastStage) {
                switch (stage) {
                    case ProcessStages.Resources: return workflow.resources?.ui?.buttons?.next?.teaching;
                    case ProcessStages.Identification: return workflow.identification?.ui?.buttons?.next?.teaching;
                    case ProcessStages.PreOffer: return workflow.preOffer?.ui?.buttons?.next?.teaching;
                    case ProcessStages.Assessment: return workflow.assessment?.ui?.buttons?.next?.teaching;
                    case ProcessStages.PostOffer: return workflow.postOffer?.ui?.buttons?.next?.teaching;
                    case ProcessStages.Contract: return workflow.contract?.ui?.buttons?.next?.teaching;
                    case ProcessStages.Control: return workflow.control?.ui?.buttons?.next?.teaching;
                    case ProcessStages.Result: return workflow.result?.ui?.buttons?.next?.teaching;
                }
            }
        },
        getStageData: (state: IStore, stageType?: ProcessStages): IIdentification | IAssessment | IContract | IControl | IContentTransferState | null | undefined => {
            switch (stageType) {
                case ProcessStages.Identification: return state.process.current?.identification;
                case ProcessStages.Assessment: return state.process.current?.transaction.assessment;
                case ProcessStages.Contract: return state.process.current?.transaction.contract;
                case ProcessStages.Control: return state.process.current?.transaction.control;
                case ProcessStages.ContentTransfer: return state.process.current?.contentTransfer;
                case undefined: return undefined;
                default: return null
            }
        },
        getStageIndexData: (state: IStore, stageIndex: number): IIdentification | IAssessment | IContract | IControl | IContentTransferState | null | undefined => {
            const stageType = state.process.current?.workflow.stages?.find(i => i.index === stageIndex)?.type
            return selector.process.getStageData(state, stageType)
        },
        getStageInspectionModules: (process: IProcess, stageType?: ProcessStages): IInspectionModuleState[] | null | undefined => {
            switch (stageType) {
                case ProcessStages.Assessment: return process.transaction.assessment.modules;
                case ProcessStages.Control: return process.transaction.control.modules;
                case ProcessStages.PostOffer: return process.transaction.postOffer.modules;
                case undefined: return undefined;
                default: return null
            }
        },
        getStageIndexInspectionModules: (process: IProcess, stageIndex: number): IInspectionModuleState[] | null | undefined => {
            const stageType = process.workflow.stages?.find(i => i.index === stageIndex)?.type
            return selector.process.getStageInspectionModules(process, stageType)
        },
        getCurrentStageIndexInspectionModules: (process?: IProcess): IInspectionModuleState[] | null | undefined => {
            if (!process) return null;
            return selector.process.getStageIndexInspectionModules(process, process.stageIndex)
        }
    },

    workflow: {
        getGrade: (state: IStore, grade: string | undefined, moduleIndex?: number): IGrade | undefined => {
            // Если используются категории грейдов            
            if (grade && state.process.current?.workflow.useGradesCategories) {
                // Получаем категорию модуля
                const gradesCategory = state.process.current?.workflow.assessment?.modules?.find(m => m.index === moduleIndex)?.config.gradesCategory;
                // Если грейд передан с категорией (CATEGORY-GRADE)
                const _grade = grade.indexOf("-") > -1 ? grade.split("-")[1] : grade;
                return state.process.current?.workflow?.gradesCategories?.find(gc => gc.code === gradesCategory)?.grades.find(g => g.code === _grade)
            } else {
                return state.process.current?.workflow.grades?.find(i => i.code === grade);
            }
        },
        getGrades: (state: IStore): IGrade[] => {
            return state.process.current?.workflow.grades ?? []
        },
        getStageConfig: (state: IStore, stageType?: ProcessStages): IResourcesConfig | IIdentificationConfig | IPreOfferConfig | IAssessmentConfig | IPostOfferConfig | IContractConfig | IControlConfig | IResultConfig | undefined => {
            switch (stageType) {
                case ProcessStages.Resources: return state.process.current?.workflow.resources;
                case ProcessStages.Identification: return state.process.current?.workflow.identification;
                case ProcessStages.PreOffer: return state.process.current?.workflow.preOffer;
                case ProcessStages.Assessment: return state.process.current?.workflow.assessment;
                case ProcessStages.PostOffer: return state.process.current?.workflow.postOffer;
                case ProcessStages.Contract: return state.process.current?.workflow.contract;
                case ProcessStages.Control: return state.process.current?.workflow.control;
                case ProcessStages.Result: return state.process.current?.workflow.result;
                case ProcessStages.ContentTransfer: return state.process.current?.workflow.contentTransfer;
                default: return undefined
            }
        },
        getStageIndexConfig: (state: IStore, stageIndex: number): IResourcesConfig | IIdentificationConfig | IPreOfferConfig | IAssessmentConfig | IPostOfferConfig | IContractConfig | IControlConfig | IResultConfig | undefined => {
            const stageType = state.process.current?.workflow.stages?.find(i => i.index === stageIndex)?.type
            return selector.workflow.getStageConfig(state, stageType)
        },
        getStageIndexRequirementsConfig: (state: IStore, stageIndex?: number): IStageRequirementsConfig | undefined => {
            const stageType = state.process.current?.workflow.stages?.find(i => i.index === stageIndex)?.type
            switch (stageType) {
                case ProcessStages.Assessment: return (selector.workflow.getStageConfig(state, stageType) as IAssessmentConfig)?.requirements
                case ProcessStages.Identification: return (selector.workflow.getStageConfig(state, stageType) as IIdentificationConfig)?.requirements
            }
        },        
        getStageIndexConfigWithRequirements: (state: IStore, stageIndex?: number): IAssessmentConfig | IIdentificationConfig | undefined => {
            const stageType = state.process.current?.workflow.stages?.find(i => i.index === stageIndex)?.type
            switch (stageType) {
                case ProcessStages.Assessment: return (selector.workflow.getStageConfig(state, stageType) as IAssessmentConfig)
                case ProcessStages.Identification: return (selector.workflow.getStageConfig(state, stageType) as IIdentificationConfig)
            }
        },        
        getModuleConfig: <T>(state: IStore, index: number): T | undefined => {
            return state.process.current?.workflow.assessment?.modules.find(m => m.index === index) as unknown as T;
        },
        getModuleCommonConfig: (state: IStore, index: number): IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>> | undefined => {
            return state.process.current?.workflow.assessment?.modules.find(m => m.index === index) as IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>;
        },
        getStageInspectionConfig: (process: IProcess, stageType?: ProcessStages): IAssessmentConfig | IControlConfig | IPostOfferConfig | null | undefined => {
            switch (stageType) {
                case ProcessStages.Assessment: return process.workflow.assessment;
                case ProcessStages.Control: return process.workflow.control;
                case ProcessStages.PostOffer: return process.workflow.postOffer;
                case undefined: return undefined;
                default: return null
            }
        },
        getStageIndexInspectionConfig: (process: IProcess, stageIndex: number): IAssessmentConfig | IControlConfig | IPostOfferConfig | null | undefined => {
            const stageType = process.workflow.stages?.find(i => i.index === stageIndex)?.type
            return selector.workflow.getStageInspectionConfig(process, stageType)
        },
        getCurrentStageIndexInspectionConfig: (process?: IProcess): IAssessmentConfig | IControlConfig | IPostOfferConfig | null | undefined => {
            if (!process) return null;
            return selector.workflow.getStageIndexInspectionConfig(process, process.stageIndex)
        },
    },

    environment: {
        getPiceaConnectionIsConnected: (state: IStore, connection_type?: PiceaConnectionTypes): boolean => {
            switch (connection_type) {
                case PiceaConnectionTypes.OTF: return state.environment.picea?.connections.otf.status === PiceaConnectionStatuses.Connected;
                case PiceaConnectionTypes.USB: return state.environment.picea?.connections.usb.status === PiceaConnectionStatuses.Connected;
                case PiceaConnectionTypes.NCD: return state.environment.picea?.connections.ncd.status === PiceaConnectionStatuses.Connected;
                default: return false
            }
        },
    }
}
