import {strings} from './../../localization/strings';
import {AppThunkAction} from "..";
import {uploadFileType} from "../../components/process/inspections/photographic/Photographic";
import {endpoints} from "../api/endpoints";
import {getCurrentTime} from "../helpers/client-time";
import {getLocalizedIdentificationMethod, inspectionDescriber} from "../helpers/localization";
import {consoleStyles} from "../scripts/console";
import {eraserSupportReasonDescriber, verifyDescriber, verifyStatusDescriber} from "../scripts/picea.describer";
import {
    deviceDescriber,
    DeviceErrors,
    eraserErrorDescriber,
    errorDescriber,
    GenericErrors,
    PiceaGetApiError
} from "../scripts/picea.errors";
import {
    ErasureOperationCompletedData,
    ISessionResultsCallbackData,
    ISessionResultsCallbackFunc,
    OperationStatus,
    PiceaApplicationStateEventArgs,
    PiceaApplicationStates,
    PiceaConnectionTypes,
    PiceaDevice,
    PiceaDevices,
    PiceaError
} from "../scripts/picea.typings";
import {InspectionTypes, IProcess, IStore} from "../store";
import {
    ChannelTypes,
    compareVersion2,
    definitions,
    DeviceAttributes,
    DiagnosticCases,
    DiagnosticsModes,
    DiagnosticStatuses,
    generateUUID,
    IdentificationMethods,
    IDevice,
    IDiagnosticCaseResult,
    IDiagnosticDetails,
    IDiagnostics,
    IDiagnosticsConfig,
    IDiagnosticSetResult,
    IDiagnosticsPiceaCaseConfig,
    IDiagnosticsPiceaConfig,
    IDiagnosticsPiceaMobileConfig,
    IDiagnosticsPiceaSetConfig,
    IDiagnosticsPiceaTestConfig,
    IDiscount,
    IErase,
    IEraseConfig,
    IEraseDefaultConfig,
    IFieldsData,
    IFileRequest,
    IGradeWithCategory,
    IIdentification,
    IIdentificationWarning,
    IImeiLockoutConfig,
    IInspectionModuleState,
    IInspectionWarning,
    IModerationMessage,
    Inspections,
    InspectionStatuses,
    IOfferHub,
    IOfferProviderResponse,
    IPhotographic,
    IPiceaDiagnosticTest,
    IPiceaVerify,
    IProcessStageState,
    IPromoProviderResponse,
    IProviderGrade,
    ISelectedPromo,
    ISoftwareConfig,
    ITransaction,
    ITransactionDevice,
    ITransactionValue,
    IUploadFile,
    OfferProviderTypes,
    OperationTypes,
    PiceaConnectionStatuses,
    ProcessStages,
    PromoProviderTypes,
    PromotionPriceTypes,
    ServiceTypes,
    SoftwareModes,
    TransactionKeys,
    TransactionLogFlags,
    VerifyChecks,
    VerifyStatuses
} from '@piceasoft/core';
import {selector} from "../store/selector";
import {api, api as _api} from '../api/api';
import {KnownAction as TeachingKnownAction} from './teaching-actions';
import {MessageBarType} from '@fluentui/react';
import {browserName, browserVersion} from "react-device-detect";
import {localizedHttpErrors} from '../../localization/helpers/http';
import {IResponseResult} from '../store/typings/IResponseResult';
import {
    findSelectedOffer,
    getAssesmentGradeCategoryResults,
    getPriceWithCommission,
    validatePromotion
} from '../../components/helpers/offer';
import {deviceBuild} from '../scripts/picea';
import {IDiagnosticsPiceaMobileCaseOptions} from '../store/typings/IDiagnosticsPiceaMobileSetConfig';
import {getApiHostname, getPiceaLang} from '../helpers/common';
import {
    getAssessmentOperationDetails,
    getOfferOperationDetails,
    getPhotoModerationOperationDetails,
    prepareAddOperation
} from '../helpers/operationDetails';
import {SessionStatus} from "../store/typings/SessionStatus";
import {IProcessMessage, ProcessMessageCodes} from '../store/typings/IProcess';
import {PiceaKeys} from '../store/typings/PiceaKeys';
import {PiceaSessionState} from '../store/typings/PiceaSessionState';
import {getURLParameters} from '../helpers/urls';
import {mobileIdentificationGuard} from '../helpers/mobile-identification-guard';
import useGrades from "../hooks/useGrades";
import {ISoftware} from "@piceasoft/core/dist/interfaces/ISoftware";

export interface InitializationRequestAction { type: 'PROCESS_INITIALIZATION_REQUEST' }
export interface InitializationIsReadyAction { type: 'PROCESS_INITIALIZATION_IS_READY', instance: IProcess }
export interface InitializationFailAction { type: 'PROCESS_INITIALIZATION_FAIL', error: string }

export interface ProcessSessionAction { type: 'PROCESS_SESSION', session_id: string }
export interface ProcessSessionClearAction { type: 'PROCESS_SESSION_CLEAR' }

export interface ProcessSyncStartAction { type: 'PROCESS_SYNC_START' }
export interface ProcessSyncStopAction { type: 'PROCESS_SYNC_STOP' }
export interface ProcessSyncUpdateAction { type: 'PROCESS_SYNC_UPDATE_STATE' }

export interface ProcessMessageAction { type: 'PROCESS_MESSAGE', message: IProcessMessage }
export interface ProcessMessageBlockerDialogToggleAction { type: 'PROCESS_MESSAGE_BLOCKER_DIALOG_TOGGLE' }
export interface ProcessMessageClearAction { type: 'PROCESS_MESSAGE_CLEAR', force?: boolean, code?: ProcessMessageCodes }

export interface WithoutControlledTransactionAction { type: 'PROCESS_WITHOUT_CONTROLLED_TRANSACTION' }
export interface ReceiveControlledTransactionAction { type: 'PROCESS_RECEIVE_CONTROLLED_TRANSACTION', data: ITransaction }
export interface ClearControlledTransactionAction { type: 'PROCESS_CLEAR_CONTROLLED_TRANSACTION' }

export interface NextStageIndexAction { type: 'PROCESS_NEXT_STAGE_INDEX', nextIndex: number }
export interface ReceiveStageCompletedAction { type: 'PROCESS_RECEIVE_STAGE_COMPLETED', completed?: boolean }
export interface ReceiveStageActionAction { type: 'PROCESS_RECEIVE_STAGE_ACTION', stageAction?: () => void }
export interface ReceiveBackActionAction { type: 'PROCESS_RECEIVE_BACK_ACTION', backAction?: () => void }
export interface ReceivePreviousStageAction { type: 'PROCESS_RECEIVE_PREVIOUS_STAGE' }

export interface ReceiveIdentificationAction { type: 'PROCESS_RECEIVE_IDENTIFICATION', moduleIndex: number, device: IDevice, method: IdentificationMethods, isAuthenticated: boolean, isConnected: boolean, stamp?: string, warning?: IIdentificationWarning }
export interface ClearIdentificationAction { type: 'PROCESS_CLEAR_IDENTIFICATION' }

export interface ClearOffersAction { type: 'PROCESS_CLEAR_OFFERS' }

export interface LockStagePiceasoftModulesAction { type: 'PROCESS_TRANSACTION_STAGE_UNLOCK_PICEASOFT_MODULES', stageIndex?: number, fromModuleIndex?: number }
export interface UnlockStagePiceasoftModulesAction { type: 'PROCESS_TRANSACTION_STAGE_LOCK_PICEASOFT_MODULES', stageIndex?: number }

export interface RequestPreOfferAction { type: 'PROCESS_REQUEST_PRE_OFFER' }
export interface ReceivePreOfferAction { type: 'PROCESS_RECEIVE_PRE_OFFER', offer: IOfferHub }

export interface ReceiveInspectionModuleAction { type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_STATE', index: number, result: InspectionTypes }
export interface ReceiveInspectionModuleWarningAction { type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_WARNING', index: number, warning: IInspectionWarning | undefined }

export interface ChangeConnectionStatusAction { type: 'PROCESS_IDENTIFICATION_CHANGE_CONNECTION_STATUS', status: boolean, stamp?: string }

export interface UpdateTransactionIdentityAction { type: 'PROCESS_TRANSACTION_UPDATE_IDENTITY', identity: ITransactionDevice }
export interface InspectionModuleResetFilesAction { type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RESET_FILES', index: number }
export interface InspectionModuleChangeStatusAction { type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_CHANGE_STATUS', index: number, status: InspectionStatuses, wasAborted?: boolean, canAutorun?: boolean }
export interface InspectionModuleUploadProgressAction { type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_UPLOAD_PROGRESS', index: number, uploadProgress: number }
export interface TrackInspectionModuleAction { type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_TRACK_LAUNCH', index: number }

export interface ReceiveAssessmentAction { type: 'PROCESS_TRANSACTION_ASSESSMENT_RECEIVE_RESULT', grade: string, price: number }

export interface PrepareOfferProvidersStateAction { type: 'PROCESS_PREPARE_OFFER_PROVIDERS_STATE' }
export interface RequestProviderOfferAction { type: 'PROCESS_REQUEST_PROVIDER_OFFER', code: string, providerType: OfferProviderTypes }
export interface ReceiveProviderOfferAction { type: 'PROCESS_RECEIVE_PROVIDER_OFFER', providerCode: string, providerType: OfferProviderTypes, response: IResponseResult<IOfferProviderResponse>, gradesMap?: IProviderGrade[] }
export interface SelectProviderOfferAction { type: 'PROCESS_TRANSACTION_PROVIDER_OFFER_SELECT', providerCode?: string, price?: number, priceWithoutCommission?: number, currency?: string, organizationId?: string, sku?: string }

export interface PreparePromoProvidersStateAction { type: 'PROCESS_PREPARE_PROMO_PROVIDERS_STATE' }
export interface RequestProviderPromoAction { type: 'PROCESS_REQUEST_PROVIDER_PROMO', code: string, providerType: PromoProviderTypes }
export interface ReceiveProviderPromoAction { type: 'PROCESS_RECEIVE_PROVIDER_PROMO', providerCode: string, providerType: PromoProviderTypes, response: IResponseResult<IPromoProviderResponse>, gradesMap?: IProviderGrade[] }
export interface SelectProviderPromoAction { type: 'PROCESS_TRANSACTION_PROVIDER_PROMO_SELECT', providerCode?: string, price?: number, currency?: string, organizationId?: string }

export interface ReceiveDiscountAction { type: 'PROCESS_TRANSACTION_RECEIVE_DISCOUNT', discount: IDiscount }
export interface RemoveDiscountAction { type: 'PROCESS_TRANSACTION_REMOVE_DISCOUNT' }
export interface ReceiveProviderPromotionAction { type: 'PROCESS_TRANSACTION_RECEIVE_PROMOTION', promo: ISelectedPromo, isValid: boolean }
export interface RemoveProviderPromotionAction { type: 'PROCESS_TRANSACTION_REMOVE_PROMOTION' }
export interface CancelProcessAction { type: 'PROCESS_CANCEL' }
export interface RequestTransactionAction { type: 'PROCESS_REQUEST_TRANSACTION' }
export interface ReceiveTransactionAction { type: 'PROCESS_RECEIVE_TRANSACTION', transaction: ITransaction }


export interface SetIdentificationUIDAction { type: 'PROCESS_IDENTIFICATION_SET_UID', uid: string }

export interface ReceiveVerifyAction { type: 'PROCESS_RECEIVE_VERIFY', checks: IPiceaVerify[] }

export interface ReceiveContractAction { type: 'PROCESS_TRANSACTION_RECEIVE_CONTRACT', data: IFieldsData, completeStage: boolean }
export interface PrefillContractAction { type: 'PROCESS_TRANSACTION_PREFILL_CONTRACT', data: IFieldsData}

export interface StartTransactionFetchAction { type: 'PROCESS_TRANSACTION_FETCH_START' }
export interface StopTransactionFetchAction { type: 'PROCESS_TRANSACTION_FETCH_STOP' }

export interface SetCurrentOperationAction { type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes }

export interface SetImeiLockedAction { type: 'PROCESS_IMEI_LOCKED', imeiLocked: boolean }

export interface SetSessionStateAction { type: 'PROCESS_SET_PICEA_SESSION_STATE', session_state: PiceaSessionState }

export interface ClearTransactionIdentity { type: 'CLEAR_TRANSACTION_IDENTITY' }

export type KnownAction =
    InitializationRequestAction | InitializationIsReadyAction | InitializationFailAction |
    ProcessSessionAction | ProcessSessionClearAction |
    ProcessSyncStartAction | ProcessSyncStopAction | ProcessSyncUpdateAction |
    ProcessMessageAction | ProcessMessageBlockerDialogToggleAction | ProcessMessageClearAction |
    WithoutControlledTransactionAction | ReceiveControlledTransactionAction | ClearControlledTransactionAction |
    NextStageIndexAction | ReceiveStageCompletedAction | ReceiveStageActionAction | ReceiveBackActionAction | ReceivePreviousStageAction |
    ReceiveIdentificationAction | ClearIdentificationAction |
    ClearOffersAction |
    LockStagePiceasoftModulesAction | UnlockStagePiceasoftModulesAction |
    RequestPreOfferAction | ReceivePreOfferAction |
    ReceiveInspectionModuleAction | ReceiveInspectionModuleWarningAction |
    ChangeConnectionStatusAction |
    UpdateTransactionIdentityAction | InspectionModuleResetFilesAction | InspectionModuleChangeStatusAction | InspectionModuleUploadProgressAction |
    ReceiveAssessmentAction |
    TrackInspectionModuleAction |
    PreparePromoProvidersStateAction | RequestProviderPromoAction | ReceiveProviderPromoAction | SelectProviderPromoAction |
    PrepareOfferProvidersStateAction | RequestProviderOfferAction | ReceiveProviderOfferAction | SelectProviderOfferAction |
    ReceiveDiscountAction | RemoveDiscountAction |
    ReceiveProviderPromotionAction | RemoveProviderPromotionAction |
    CancelProcessAction | RequestTransactionAction | ReceiveTransactionAction |
    SetIdentificationUIDAction |
    ReceiveVerifyAction |
    ReceiveContractAction |
    PrefillContractAction |
    StartTransactionFetchAction | StopTransactionFetchAction |
    SetCurrentOperationAction |
    SetImeiLockedAction | SetSessionStateAction |
    ClearTransactionIdentity

/** diag API object, received from Piceasoft Online API */
let current_picea_api_diagnostics: any;
/** verify API object, received from Piceasoft Online API */
let current_picea_api_verify: any;
let current_picea_api_erase: any;

/** Indicates that DGS startup process has begin, but we don't know the startup result (succeeded/failed) yet. Waiting resp from device. */
let is_about_to_start_diagnostics = false;
let is_start_diagnostics = false;
/** flag that Verify is already starting */
let is_start_verify = false;
let is_start_erase = false;
/** flag that Diag is already stopping */
let stop_diagnostics: boolean
/** flag that Verify is already stopping */
let stop_verify: boolean
let stop_erase: boolean

type CallbackType = () => void;
/** additional callback function to be called when dgs is aborted */
let stop_diagnostics_cb : CallbackType | undefined;
/** additional callback function to be called when verify is aborted */
let stop_verify_cb : CallbackType | undefined;

const imei_lock_check_data:[{ imei: string; lastCheckTime: number, locked: boolean, resultPromise?: Promise<boolean> }?] = [];

let session_result_listeners: ISessionResultsCallbackFunc[] = [];

let piceaOneExecutionContext: { 
    index: number, 
    device_id: string | undefined, 
    inspection: Inspections, 
    operationUid: number 
} | undefined = undefined; 

/** flag that checks if requestTransaction is already called */
let isTransactionFetching = false;

export const actionCreators = {
    /** Инициализирует новый процеѝѝ указанного ѝервиѝа (по идентификатору ѝервиѝа). */
    initializationRequest: (serviceId: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.process) {
            dispatch({ type: 'PROCESS_INITIALIZATION_REQUEST' });
            const apiResponse = await _api.v1.workplace.getProcess(serviceId, appState.environment.picea?.link)
            if (apiResponse.successed && apiResponse.data) {
                const stagesConfig = apiResponse.data.workflow?.stages
                if (stagesConfig && stagesConfig.length > 0) {
                    apiResponse.data.stages = apiResponse.data.workflow.stages as IProcessStageState[]
                    if (apiResponse.data.stages.length >= 2) {
                        if (apiResponse.data.stages[0].type === ProcessStages.Identification && apiResponse.data.stages[1].type === ProcessStages.ContentTransfer) {
                            // drop the Identification stage, jump directly to ContentTransfer
                            apiResponse.data.stages.shift()
                        }

                    }
                }
                dispatch({ type: 'PROCESS_INITIALIZATION_IS_READY', instance: apiResponse.data });
                dispatch(actionCreators.addLog("ENVIRONMENT", "BROWSER", {
                    browser: browserName,
                    version: browserVersion
                }));

                if (!appState.process.current?.offerProviders) {
                    dispatch(actionCreators.prepareOfferProvidersState())
                }

                if (!appState.process.current?.promoProviders) {
                    dispatch(actionCreators.preparePromoProvidersState())
                }

                if (stagesConfig && stagesConfig.length > 0) {
                    dispatch(actionCreators.updateProcessSyncState())
                    dispatch(actionCreators.nextStageV3())
                }

            } else {
                dispatch({ type: 'PROCESS_INITIALIZATION_FAIL', error: strings.ACTIONS_MESSAGES.PROCESS.PROCESS_INITIALIZATION_PROBLEM });
            }
        }
    },

    receiveInitializationFailMessage: (error: string): AppThunkAction<KnownAction | AppThunkAction<TeachingKnownAction>> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.process) {
            dispatch({ type: 'PROCESS_INITIALIZATION_FAIL', error: error });
        }
    },

    updateProcessSyncState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.debug("%c" + "LOGIC updateProcessSyncState()", consoleStyles.logic);
        console.log({ state: appState.process.current })
        if (appState && appState.process) {
            dispatch({ type: 'PROCESS_SYNC_UPDATE_STATE' });
        }
    },

    /** Save transaction state. */
    saveProcessState: (callback?: (successed: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC saveProcessState()", consoleStyles.logic);
        const state = getState().process
        console.log({ state: state.sync })
        const ignoreFetching = state.sync.fetchTimestamp ? ((Date.now() - state.sync.fetchTimestamp) / 1000) > 120 : false
        if (state.current?.session_id && state.current.stage > 0) {
            if ((state.sync.isFetching && !ignoreFetching) || !state.sync.haveToBeSynced) return;
            dispatch(actionCreators.startTransactionFetch())
            dispatch({ type: 'PROCESS_SYNC_START' })
            dispatch(actionCreators.setPiceaOnlineSessionData((successed: boolean) => {
                if (callback) {
                    callback(successed)
                }
                dispatch({ type: 'PROCESS_SYNC_STOP' })
                dispatch(actionCreators.stopTransactionFetch())
            }))
            return;
        } else {
            if (callback) {
                callback(true)
            }
        }
    },

    openPiceaOnlineSession: (device_id: string, connection_type: PiceaConnectionTypes, callback?: (succeeded: boolean, session_id?: string) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const appState = getState();
        console.debug("%c" + "LOGIC openPiceaOnlineSession()", consoleStyles.logic);
        console.log(appState.process.current)
        if (appState && (
            (appState.process.current &&
                !appState.process.current?.session_id &&
                (appState.process.current.session_state === undefined || appState.process.current.session_state === PiceaSessionState.CLOSED)))
        ) {

            const session_type = appState.process.current?.service.serviceType ?? ServiceTypes.Generic;
            const channel_type = appState.process.current?.service.channel ?? ChannelTypes.Retail

            interface ISite {
                site_id?: string,
                type: number,
                type_string: string,
                name?: string,
                address?: string,
                city?: string,
                country?: string,
                external_id?: string,
                group?: object,
                company?: object
            }

            let site: ISite = {
                site_id: appState.workplace.point?.id,
                type: 1,
                type_string: 'Store',
                name: appState.workplace.point?.name,
                address: appState.workplace.point?.address,
                city: appState.workplace.point?.city,
                country: appState.workplace.point?.country,
                external_id: appState.workplace.point?.code
            };

            if (appState.workplace.container?.id && appState.workplace.container?.name) {
                site.group = { // Folder
                    group_id: appState.workplace.container?.id,
                    type: 1,
                    type_string: 'Store',
                    name: appState.workplace.container?.name
                }
            }
            if (appState.workplace.company?.id && appState.workplace.company?.name) {
                site.company = {
                    company_id: appState.workplace.company?.id,
                    name: appState.workplace.company?.name,
                    address: appState.workplace.company?.address,
                    tax_id: appState.workplace.company?.itn
                }
            }

            const config = {
                channel_type: channel_type > 0 ? channel_type : ChannelTypes.Retail, // For old clients when channel is not defined
                connection_type: connection_type,
                device_id: device_id,
                session_type: session_type > 10 ? session_type : ServiceTypes.Generic, // For old clients when service type is not defined
                workflow_uid: appState.process.current?.service?.uid,
                workflow_name: appState.process.current?.service.name,
                site : site,
                transaction_id: appState.process.current?.transaction.number
            }

            console.info({ config });
            dispatch(actionCreators.addLog("PICEA", "CALL openPiceaOnlineSession()", { config }));

            interface IOpenSessionCallbackData {
                status: number
                session_id: string
            }

            dispatch(actionCreators.setSessionState(PiceaSessionState.OPENING));

            window.ONLINE_API.openSession(config, (error: PiceaGetApiError, type: number, data: IOpenSessionCallbackData) => {
                console.debug("%c" + "Picea™ Online Services: ONLINE_API.openSession()", consoleStyles.piceaCollback);
                console.info({ error, data });
                dispatch(actionCreators.addLog("PICEA", "CALLBACK openPiceaOnlineSession()", {error, data}));
                dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.SESSION_START });

                if (error) {
                    dispatch(actionCreators.setSessionState(PiceaSessionState.CLOSED));
                    if (error.details.error !== -2146435052) { // Device is not supported (for back compatibility)
                        dispatch({
                            type: 'PROCESS_MESSAGE',
                            message: {
                                text: errorDescriber(error.details?.error ?? GenericErrors.UNEXPECTED_ERROR),
                                type: MessageBarType.error,
                                onRetryAction: () => {
                                    dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                    dispatch(actionCreators.openPiceaOnlineSession(device_id, connection_type, callback));
                                }
                            }
                        });
                    }
                    else {
                        if (callback) callback(false, undefined);
                    }
                    return;
                }

                if (data.status !== 0) {
                    console.info("openSession failed, status: " + data.status);
                    dispatch(actionCreators.setSessionState(PiceaSessionState.CLOSED));
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: {
                            text: `Cannot open session (status: ${data.status} )`,
                            type: MessageBarType.error,
                            onRetryAction: () => {
                                dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                dispatch(actionCreators.openPiceaOnlineSession(device_id, connection_type));
                            }
                        }
                    });

                    if (callback) callback(false, undefined);
                    return;
                }

                if (!data.session_id) {
                    console.info("Cannot open session (missing session_id)");
                    dispatch(actionCreators.setSessionState(PiceaSessionState.CLOSED));
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                         message: {
                            text: `Cannot open session (missing session_id)`,
                            type: MessageBarType.error,
                            onRetryAction: () => {
                                dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                dispatch(actionCreators.openPiceaOnlineSession(device_id, connection_type));
                            }
                        }
                    });

                    if (callback) callback(false, undefined);
                    return;
                }

                console.debug("%c" + "LOGIC openPiceaOnlineSession() session opened: " + data.session_id, consoleStyles.logic);

                dispatch(actionCreators.setSessionState(PiceaSessionState.OPENED));
                dispatch({ type: 'PROCESS_SESSION', session_id: data.session_id });
                dispatch(actionCreators.updateCurrentTransaction([
                            { key: TransactionKeys.RESUME_SESSION_ID, value: data.session_id}, 
                            { key: TransactionKeys.RESUME_CONNECTION_TYPE, value: connection_type}
                        ]));
                if (callback) callback(true, data.session_id);
            })
        }
        else {
            console.info("openPiceaOnlineSession(): not opening!");
            if (callback) callback(false, undefined);
        }
    },

    clearSession: (): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch) => {
        dispatch({ type: 'PROCESS_SESSION_CLEAR' });
    },

    closePiceaOnlineSession: ( sessionStatus?: SessionStatus, callback?: (succeeded: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const appState = getState();
        console.debug("%c" + "LOGIC closePiceaOnlineSession()", consoleStyles.logic);

        const onDone = (succeeded: boolean) => {
            console.log("closePiceaOnlineSession done!");
            // clear all session related stuff, regardless succeeded status
            dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
            dispatch(actionCreators.setSessionState(PiceaSessionState.CLOSED));
            dispatch(actionCreators.clearSession());
            dispatch(actionCreators.clearIdentification());
            dispatch(actionCreators.clearControlledTransaction());
            if(appState.process.current?.stage && [ProcessStages.Identification, ProcessStages.PreOffer].includes(appState.process.current?.stage)) {
                if(appState.process.current.transaction.identity) {
                    console.info("Removing transaction identity.")
                    dispatch(actionCreators.clearTransactionIdentify());
                }
            }
            if (callback) callback(succeeded);
        }

        // in content transfer case identification uses target device, but session was opened with source one. So use device_id from source here.
        const device_id = appState.process.current?.contentTransfer?.selectedSource?.device_id ?? appState.process.current?.identification?.stamp;
        if (appState && appState.process && device_id && appState.process.current?.session_id) {
            const config = {
                device_id: device_id,
                session_id: appState.process.current?.session_id,
                session_status: sessionStatus ?? 0,
                last_operation : appState.process.current?.currentOperation,
                transaction_id: appState.process.current?.transaction.number
            }

            console.info({ config });
            dispatch(actionCreators.addLog("PICEA", "closePiceaOnlineSession()", { config }));


            interface ICloseSessionCallbackData {
                status: number
            }

            if (window.ONLINE_API) {
                window.ONLINE_API.closeSession(config, (error: PiceaGetApiError, type: number, data: ICloseSessionCallbackData) => {
                    console.debug("%c" + "Picea™ Online Services: ONLINE_API.closeSession()", consoleStyles.piceaCollback);
                    console.info({ error, data });
                    onDone(true);
                });
            }
            else {
                // no api?
                onDone(false);
            }
        }
        else {
            console.log("no session, nothing to close");
            onDone(false);
        }
    },
   
    addPiceaOnlineOperation: ( operationType: OperationTypes, operationDetails: any, startDate?: Date): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const appState = getState();
        console.debug("%c" + "LOGIC addPiceaOnlineOperation()", consoleStyles.logic);
        dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: operationType });
        if (appState && appState.process && appState.process.current?.identification?.stamp && appState.process.current?.session_id) {
            prepareAddOperation(appState.process.current, operationType, operationDetails, startDate, getConnectionTypeByIdentificationMethod(appState.process.current.identification.method), dispatch);
        }
    },

    setPiceaOnlineSessionData: (callback?: (successed: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const appState = getState();
        console.debug("%c" + "LOGIC setPiceaOnlineProcessData()", consoleStyles.logic);
        if (appState && appState.process.sync.state && appState.process.current?.identification?.stamp && appState.process.current?.session_id) {

            let connectionType = PiceaConnectionTypes.NCD
            if( appState.environment.picea?.connections.otf.status === PiceaConnectionStatuses.Connected) {
                connectionType = PiceaConnectionTypes.OTF
            }
            else if( appState.environment.picea?.connections.usb.status === PiceaConnectionStatuses.Connected) {
                connectionType = PiceaConnectionTypes.USB
            }

            const config = {
                session_id: appState.process.current.session_id,
                key: 'ONLINE_PROCESS',
                data: appState.process.current,
                connection_type: connectionType
            }
            console.info({ config });

            interface ISetSessionCallbackData {
                status: number
            }

            window.ONLINE_API.setSessionData(config, (error: PiceaGetApiError, type: number, data: ISetSessionCallbackData) => {
                console.debug("%c" + "Picea™ Online Services: ONLINE_API.setSessionData()", consoleStyles.piceaCollback);
                console.info({ error, data, url: `${location.origin}/process/restore/${config.session_id}/${connectionType}` });

                if (error) {
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message:{
                            text: errorDescriber(error.details.error ?? GenericErrors.UNEXPECTED_ERROR),
                            type: MessageBarType.error,
                            onRetryAction: () => {
                                dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                dispatch(actionCreators.setPiceaOnlineSessionData(callback));
                            }
                        }
                    });
                    if (callback) {
                        callback(false)
                    }
                    return;
                }

                if (data.status !== 0) {
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: {
                            text: `Cannot set session data (status: ${data.status} )`,
                            type: MessageBarType.error,
                            onRetryAction: () => {
                                dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                dispatch(actionCreators.setPiceaOnlineSessionData(callback));
                            }
                        }
                    });
                    if (callback) {
                        callback(false)
                    }
                    return;
                }

                //  Update connection type for restore.
                dispatch(actionCreators.updateCurrentTransaction([
                    { key: TransactionKeys.RESUME_CONNECTION_TYPE, value: connectionType}
                ]));

                if (callback) {
                    callback(true)
                }
            })
        }

        callback?.(true)
    },

    getPiceaOnlineSessionData: (session_id: string, connection_type: PiceaConnectionTypes): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {

        const appState = getState();
        if (appState) {

            console.debug("%c" + "LOGIC getPiceaOnlineProcessData(session_id, connection_type)", consoleStyles.logic);
            
            const config = {
                session_id: session_id,
                key: 'ONLINE_PROCESS',
                connection_type: connection_type
            }

            console.info({ config });

            interface IGetSessionCallbackData {
                status: number,
                json_data: IProcess
            }

            window.ONLINE_API.getSessionData(config, (error: PiceaGetApiError, type: number, data: IGetSessionCallbackData) => {
                console.debug("%c" + "Picea™ Online Services: ONLINE_API.getSessionData()", consoleStyles.piceaCollback);
                console.info({ error, data });

                if (error) {

                    dispatch({ type: 'PROCESS_INITIALIZATION_FAIL', error: errorDescriber(error.details.error ?? GenericErrors.UNEXPECTED_ERROR) });
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: {
                            text: errorDescriber(error.details.error ?? GenericErrors.UNEXPECTED_ERROR),
                            type: MessageBarType.error,
                            onRetryAction: () => {
                                dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                dispatch(actionCreators.getPiceaOnlineSessionData(session_id, connection_type));
                            }
                        }
                    });
                    return;
                }

                if (data.status !== 0) {
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: {
                            text: `Cannot get session data (status: ${data.status} )`,
                            type: MessageBarType.error,
                            onRetryAction: () => {
                                dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                                dispatch(actionCreators.getPiceaOnlineSessionData(session_id, connection_type));
                            }
                        }
                    });
                    return;
                }

                if (data.json_data.identification) {
                    data.json_data.identification.isConnected = false;
                }

                dispatch({ type: 'PROCESS_INITIALIZATION_IS_READY', instance: data.json_data });
            })

        }
    },

    setPiceaMobileDiagnosticModuleConfigData: (session_id: string, connection_type: PiceaConnectionTypes, module_index: number, callback: (error: PiceaGetApiError, data: { status: number }) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const appState = getState();
        const moduleConfig = selector.workflow.getCurrentStageIndexInspectionConfig(appState.process.current)?.modules.find(i => i.index === module_index)
        if (appState.process.current?.transaction.uid && moduleConfig?.type === Inspections.Diagnostics) {
            const inspectionConfig = moduleConfig.config as IDiagnosticsConfig
            if (inspectionConfig.mode === DiagnosticsModes.PiceaMobile) {
                const diagConfig = inspectionConfig.config as IDiagnosticsPiceaMobileConfig
                interface IPiceaMobileConfig {
                    cases: {
                        id: number,
                        options?: IDiagnosticsPiceaMobileCaseOptions
                    }[]
                }

                let preparedConfig: IPiceaMobileConfig = { cases: [] }

                diagConfig.tests[0].sets.forEach(s => {
                    s.cases.forEach(c => {
                        preparedConfig.cases.push({ id: c.id, options: c.options })
                    })
                })

                if (preparedConfig.cases.length > 0) {

                    console.debug("%c" + "LOGIC setPiceaMobileDiagnosticModuleConfigData(session_id, connection_type, module_index)", consoleStyles.logic);
                    const identification = appState.process.current?.identification;
                    
                    const config = {
                        session_id: session_id,
                        key: PiceaKeys.PICEAMOBILE_DIAGNOSTICS,
                        data: {
                            channelType: 1,
                            returnUrl: "",
                            piceaMobileReturnURL: "",
                            transactionUid: appState.process.current?.transaction.uid,
                            language: getPiceaLang((appState.environment.lang ?? "en").substring(0, 2)),
                            operation: [
                                {
                                    type: "diagnostics",
                                    configuration: preparedConfig
                                }
                            ],
                            manual_device_identifiers: {
                                imei: identification?.device.attributes[DeviceAttributes.IMEI] ?? "",
                                imei2: identification?.device.attributes[DeviceAttributes.IMEI2] ?? "",
                                SN: identification?.device.attributes[DeviceAttributes.SN] ?? ""
                            }
                        },
                        connection_type: connection_type,
                    }

                    console.info({ config });

                    interface ISetSessionCallbackData {
                        status: number
                    }

                    window.ONLINE_API.setSessionData(config, (error: PiceaGetApiError, type: number, data: ISetSessionCallbackData) => {
                        console.debug("%c" + "Picea™ Online Services: ONLINE_API.setSessionData()", consoleStyles.piceaCollback);
                        console.info({ error, data });

                        callback(error, data)
                    })

                } else {
                    dispatch(actionCreators.receiveProcessMessage({text: strings.PROCESS.PROCESS_CONFIGURATION_ERROR, type: MessageBarType.error}))
                }
            }
            else {
                dispatch(actionCreators.receiveProcessMessage({text: strings.PROCESS.PROCESS_CONFIGURATION_ERROR, type: MessageBarType.error}))
            }
        } else {
            callback({ error: { error: -1, error_text: strings.PROCESS.PROCESS_CONFIGURATION_ERROR }, details: { error: -1, message: strings.PROCESS.PROCESS_CONFIGURATION_ERROR }, status: -1 }, { status: -1 })
        }
    },
    
    setPiceaMobileVerifyModuleConfigData: (session_id: string, connection_type: PiceaConnectionTypes, module_index: number, callback: (error: PiceaGetApiError, data: { status: number }) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        
        const appState = getState();
        const moduleConfig = selector.workflow.getCurrentStageIndexInspectionConfig(appState.process.current)?.modules.find(i => i.index === module_index)
        
        if (appState.process.current?.transaction.uid && moduleConfig?.type === Inspections.Software) {
            const inspectionConfig = moduleConfig.config as ISoftwareConfig
            
            if (inspectionConfig.mode === SoftwareModes.PiceaMobile && inspectionConfig.config) {

                let checks: Array<any> = []
                inspectionConfig.config.checks.map((configCheck) => {
                    checks.push({
                        id: configCheck.id,
                        options: {}
                    })
                })

                if (checks.length > 0) {
                    console.debug("%c" + "LOGIC setPiceaMobileVerifyModuleConfigData(session_id, connection_type, module_index)", consoleStyles.logic);
                    const identification = appState.process.current?.identification;
                    const product_id = appState?.environment?.picea?.script ? getURLParameters(appState?.environment?.picea?.script).product_id : ""
                    
                    const config = {
                        session_id: session_id,
                        key: PiceaKeys.PICEAMOBILE_VERIFY,
                        data: {
                            channelType: 1,
                            returnUrl: "",
                            piceaMobileReturnURL: "",
                            transactionUid: appState.process.current?.transaction.uid,
                            product_id: product_id,
                            language: getPiceaLang((appState.environment.lang ?? "en").substring(0, 2)),
                            operation: [
                                {
                                    type: "verify",
                                    configuration: { checks: checks }
                                }
                            ],
                            manual_device_identifiers: {
                                imei: identification?.device.attributes[DeviceAttributes.IMEI] ?? "",
                                imei2: identification?.device.attributes[DeviceAttributes.IMEI2] ?? "",
                                SN: identification?.device.attributes[DeviceAttributes.SN] ?? ""
                            }
                        },
                        connection_type: connection_type,
                    }

                    console.info({ config });

                    interface ISetSessionCallbackData {
                        status: number
                    }

                    window.ONLINE_API.setSessionData(config, (error: PiceaGetApiError, type: number, data: ISetSessionCallbackData) => {
                        console.debug("%c" + "Picea™ Online Services: ONLINE_API.setSessionData()", consoleStyles.piceaCollback);
                        console.info({ error, type, data });

                        callback(error, data)
                    })

                } else {
                    dispatch(actionCreators.receiveProcessMessage({text: strings.PROCESS.PROCESS_CONFIGURATION_ERROR, type: MessageBarType.error}))
                }
            }
            else {
                dispatch(actionCreators.receiveProcessMessage({text: strings.PROCESS.PROCESS_CONFIGURATION_ERROR, type: MessageBarType.error}))
            }
        } else {
            callback({ error: { error: -1, error_text: strings.PROCESS.PROCESS_CONFIGURATION_ERROR }, details: { error: -1, message: strings.PROCESS.PROCESS_CONFIGURATION_ERROR }, status: -1 }, { status: -1 })
        }
    },

    addSessionResultsListener:(callbackFunc: ISessionResultsCallbackFunc) : AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const index = session_result_listeners.indexOf(callbackFunc);
        if (index === -1) {
            session_result_listeners.push(callbackFunc);
        }

        // make sure we have registered, by re-registering
        if (window.ONLINE_API.onSessionResults){
            window.ONLINE_API.onSessionResults((error: PiceaGetApiError, type: number, data: ISessionResultsCallbackData) => {
                console.debug("%c" + "Picea™ Online Services: useSessionResultsListeners ONLINE_API.onSessionResults", consoleStyles.piceaCollback);
                session_result_listeners.forEach(func => func(data));
            });
        }

    },

    removeSessionResultsListener:(callbackFunc: ISessionResultsCallbackFunc) : AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        const index = session_result_listeners.indexOf(callbackFunc);
        if (index !== -1) {
            session_result_listeners.splice(index, 1);
        }
    },
    
    onPiceaMobileDiagnosticSessionResult: (module_index: number, data: ISessionResultsCallbackData): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        console.debug("%c" + "Picea™ Online Services: dgs ONLINE_API.onSessionResult(callback)", consoleStyles.piceaCollback);
        console.info({ data });

        const appState = getState();

        const identification = appState.process.current?.identification;
        const session_id = appState.process.current?.session_id;

        const sender = selector.workflow.getCurrentStageIndexInspectionConfig(appState.process.current)?.modules.find(m => m.index === module_index)?.config?.ui?.title ?? strings.INSPECTIONS.DIAGNOSTICS.TITLE;

        const moduleConfig = (selector.workflow.getCurrentStageIndexInspectionConfig(appState.process.current)?.modules.find(m => m.index === module_index)?.config as IDiagnosticsConfig)

        const grades = appState.process.current?.workflow.useGradesCategories ? (appState.process.current.workflow.gradesCategories?.find(gc => gc.code === moduleConfig.gradesCategory)?.grades ?? []) : appState.process.current?.workflow?.grades;
        const categories = appState.process.current?.workflow.gradesCategories;

        /** highest grade. Do not use in grade cathegories!!! */
        const bestGradeIndex = grades?.sort((a, b) => a.index - b.index)[0]?.index as number;

        if (session_id && identification && moduleConfig) {

            dispatch(actionCreators.changeInspectionModuleStatus(module_index, InspectionStatuses.Run, {
                message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_GET_RESULT
            }));

            const diagnosticsConfig = moduleConfig.config as IDiagnosticsPiceaConfig

            const testsResult = (selector.process.getCurrentStageIndexInspectionModules(appState.process.current)?.find(m => m.index === module_index)?.state as IDiagnostics).tests

            let testIndex: number | undefined = diagnosticsConfig?.tests.find((_, index) => index === diagnosticsConfig.defaultTestIndex)?.index

            // let preSets
            const deviceOs = (identification.device.attributes[DeviceAttributes.OS] as string)?.toUpperCase() ?? "UNKNOWN"
            if (diagnosticsConfig.androidTestIndex !== undefined && deviceOs === 'ANDROID') {
                // preSets = diagnosticsConfig?.tests?.find(t => t.index === diagnosticsConfig.androidTestIndex)?.sets;
                testIndex = diagnosticsConfig?.tests.find((_, index) => index === diagnosticsConfig.androidTestIndex)?.index
            }

            if (diagnosticsConfig.iosTestIndex !== undefined && deviceOs === 'IOS') {
                // preSets = diagnosticsConfig?.tests?.find(t => t.index === diagnosticsConfig.iosTestIndex)?.sets;
                testIndex = diagnosticsConfig?.tests.find((_, index) => index === diagnosticsConfig.iosTestIndex)?.index
            }

            const resultTestIndex = testIndex ?? diagnosticsConfig?.tests[0]?.index;

            let testConfig = diagnosticsConfig.tests.find(t => t.index === resultTestIndex) as IDiagnosticsPiceaTestConfig

            if (!testConfig) {
                dispatch({
                    type: 'PROCESS_MESSAGE',
                    message: {
                        text: strings.PROCESS.TRANSACTION.ALERT_ERROR_CONFIG,
                        type: MessageBarType.error
                    }
                })
                return;
            }

            let diagnosticResult: IDiagnosticCaseResult[] = [];

            if (data?.json_data?.operation?.diagnostics_details) {
                const dd = data.json_data.operation.diagnostics_details as IDiagnosticDetails;
                diagnosticResult = dd.test_cases;
            } else {
                dispatch(actionCreators.addLog("DIAGNOSTICS_DATA_CATCH", `Picea™ Online Services: ONLINE_API.onSessionResult(callback)`, { data }));
                dispatch(actionCreators.changeInspectionModuleStatus(module_index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_RESULT_GET_FAILURE
                }));
            }

            // Еѝли результаты диагноѝтики получены
            if (diagnosticResult && diagnosticResult.length > 0) {

                // Результат вѝего запуѝка диагноѝтики (пройден или не пройден)
                let passed = true;
                let sets: IDiagnosticSetResult[] = [];

                // Формируем предварительную ѝтруктуру результата длѝ дальнейшего раѝчёта
                diagnosticResult.map((cr: IDiagnosticCaseResult) => {
                    const s = definitions.diagnostic.getSetByCase(cr.case_id);
                    if (s) {
                        if (sets[s.id] === undefined) {
                            sets[s.id] = { set_id: s.id, cases: [], successed: false };
                        }
                        sets[s.id].cases.push(cr);
                    }
                });

                sets = sets.filter(s => s);

                // Результирующие грейды по категориѝм. Еѝли категории грейдов не иѝпользуютѝѝ, то
                // работаем ѝ пуѝтой категорией, как ѝ оѝновной
                let gradeCategories: IGradeWithCategory[] = [];

                // Функциѝ учёта приноѝимого грейда, котораѝ запиѝывает в результат наименьший грейд категории
                const receiveGrade = (category: string, grade: string) => {
                    var gc = gradeCategories.find(gc => gc.category === category)
                    // Еѝли грейд длѝ категории уже был ранее уѝтановлен, то обновлѝем грейд при уѝловии, что
                    // новый принеѝённый грейд меньше уже уѝтановленного
                    if (gc) {
                        gc.grade = worstGrades(category, gc.grade, grade);
                    } else {
                        gradeCategories.push({ category, grade });
                    }
                }

                // Функциѝ определениѝ худшего из 2-х грейдов
                const worstGrades = (category: string, g1: string, g2: string): string => {
                    if (g1 === "" || g2 === "") return "";
                    if (categories) {
                        const grade1 = categories.find(c => c.code === category)?.grades.find(g => g.code == g1);
                        const grade2 = categories.find(c => c.code === category)?.grades.find(g => g.code == g2);
                        return (grade1?.index ?? 0) > (grade2?.index ?? 0) ? g1 : g2;
                    } else {
                        const grade1 = grades?.find(g => g.code == g1);
                        const grade2 = grades?.find(g => g.code == g2);
                        return (grade1?.index ?? 0) > (grade2?.index ?? 0) ? g1 : g2;
                    }
                }

                // Раѝчитываем грейды наборов и ѝетов
                sets.map((s) => {
                    if (s.cases.length > 0) {
                        const set_config = testConfig.sets.find(sc => sc.id === s.set_id);
                        // Еѝли вѝе теѝтовые ѝлучаи набора пройдены уѝпешно, то набор ѝчитаетѝѝ уѝпешно выполненным
                        let successed = (s.cases.filter(c => ![DiagnosticStatuses.TCR_PASSED, DiagnosticStatuses.TCR_NOT_SUPPORTED_BY_THE_DEVICE].includes(c.status)).length ?? 0) === 0;
                        // Еѝли набор теѝтовых ѝлучаев провален, и назначен грейд при провале, то уѝтанавливаем грейд и приноѝим результат
                        if (!successed && set_config?.grade !== undefined) {
                            var setCategory = categories ? moduleConfig.gradesCategory : "";
                            var setGrade = set_config.grade;
                            s.grade = categories ? `${setCategory}-${setGrade}` : set_config.grade;
                            // Еѝли грейд ѝущеѝтвует и он ѝ категорией, то фикѝируем грейд категории
                            receiveGrade(setCategory ?? "", setGrade);
                        }
                        // Уѝтанавливаем грейды длѝ проваленных теѝтовых ѝлучаев
                        if (!successed) {
                            s.cases.map((c) => {
                                const case_config = set_config?.cases.find(cc => cc.id === c.case_id);
                                if (c.status !== DiagnosticStatuses.TCR_PASSED && case_config?.grade !== undefined) {
                                    var caseCategory = case_config.grade.indexOf("-") === -1 ? categories ? moduleConfig.gradesCategory : "" : case_config.grade.split("-")[0];
                                    var caseGrade = case_config.grade.indexOf("-") === -1 ? case_config.grade : case_config.grade.split("-")[1];
                                    c.grade = categories ? `${caseCategory}-${caseGrade}` : case_config.grade;
                                    // Еѝли грейд ѝущеѝтвует и он ѝ категорией, то фикѝируем грейд категории
                                    receiveGrade(caseCategory ?? "", caseGrade);
                                    // Еѝли категориѝ теѝтового ѝлучаѝ равна категории набора, то уѝтанавливаем набору худший грейд
                                    if (setCategory === caseCategory) {
                                        s.grade = worstGrades(setCategory ?? "", setGrade, caseGrade);
                                    }
                                }
                            });
                        }

                        s.successed = successed;
                        if (!successed) passed = false;
                    }
                });

                let diagnosticGrade: string | undefined
                // Еѝли в процеѝѝе иѝпользуютѝѝ категории грейдов
                if (categories) {
                    // Еѝли ѝреди категорий вѝтречаетѝѝ пуѝтой грейд (запрет приёма), то проваливаем веѝь результат диагноѝтики
                    if (gradeCategories.length === 0) {
                        diagnosticGrade === undefined
                    } else if (gradeCategories.find(gc => gc.grade === "")) {
                        diagnosticGrade = "";
                    } else {
                        diagnosticGrade = gradeCategories.find(gc => gc.category === moduleConfig.gradesCategory)?.grade ?? "";
                    }

                } else {
                    if (gradeCategories.length === 0) {
                        diagnosticGrade === undefined
                    } else if (gradeCategories.find(gc => gc.category === "")?.grade === "") {
                        diagnosticGrade = "";
                    } else {
                        diagnosticGrade = gradeCategories.find(gc => gc.category === "")?.grade ?? "";
                    }
                }

                if (passed && diagnosticGrade === undefined && typeof diagnosticsConfig.setSuccessGrade === 'string') {
                    if (diagnosticsConfig.setSuccessGrade === "") {
                        diagnosticGrade = grades?.find(g => g.index === bestGradeIndex)?.code ?? ""
                    } else {
                        diagnosticGrade = diagnosticsConfig.setSuccessGrade;
                    }
                }

                // Формируем объект результата запущенного теѝта
                const currentTest: IPiceaDiagnosticTest = {
                    grade: diagnosticGrade ?? "",
                    grades: gradeCategories,
                    index: resultTestIndex,
                    name: testConfig.name,
                    description: testConfig.description,
                    uid: data?.json_data?.operation?.uid,
                    cases: diagnosticResult,
                    sets: sets
                }

                // Формируем новый маѝѝив выполненных теѝтов ѝ обновлением текущего
                let updatedTests: IPiceaDiagnosticTest[] = [
                    ...(testsResult?.filter(t => t.index !== resultTestIndex) ?? []),
                    currentTest
                ];

                // TODO: Реализовать выбор наихудших грейдов ѝреди вѝех выполненных теѝтов

                // Добавлѝем ѝобытиѝ о выполненных теѝтовых ѝлучаѝх
                dispatch(actionCreators.addEvents(
                    diagnosticResult.filter(i => i.result_msg && i.result_msg.length > 0).map(i => {
                        return {
                            action: sender,
                            status: i.result_msg
                        }
                    })
                ));

                // Завершаем выполнение диагноѝтики и приноѝим результат в модуль
                dispatch(actionCreators.receiveInspectionModuleState(module_index, {
                    status: passed || diagnosticGrade ? InspectionStatuses.Done : InspectionStatuses.Fail,
                    grade: diagnosticGrade,
                    grades: gradeCategories,
                    tests: updatedTests
                }));
            } else {
                dispatch(actionCreators.changeInspectionModuleStatus(module_index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_RESULT_GET_FAILURE
                }));
            }
        }
    },

    onPiceaMobileVerifySessionResult: (module_index: number, data: ISessionResultsCallbackData): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        console.debug("%c" + "Picea™ Online Services: verify ONLINE_API.onSessionResult(callback)", consoleStyles.piceaCollback);
        console.info({ data });

        const appState = getState();

        const identification = appState.process.current?.identification;
        const session_id = appState.process.current?.session_id;

        const sender = selector.workflow.getCurrentStageIndexInspectionConfig(appState.process.current)?.modules.find(m => m.index === module_index)?.config?.ui?.title ?? strings.INSPECTIONS.SOFTWARE.TITLE;

        const moduleConfig = (selector.workflow.getCurrentStageIndexInspectionConfig(appState.process.current)?.modules.find(m => m.index === module_index)?.config as ISoftwareConfig)

        const grades = appState.process.current?.workflow.useGradesCategories ? (appState.process.current.workflow.gradesCategories?.find(gc => gc.code === moduleConfig.gradesCategory)?.grades ?? []) : appState.process.current?.workflow?.grades;
        const categories = appState.process.current?.workflow.gradesCategories;

        if (session_id && identification && moduleConfig) {

            dispatch(actionCreators.changeInspectionModuleStatus(module_index, InspectionStatuses.Run, {
                message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_GET_RESULT
            }));

            let verifyResult: IPiceaVerify[] = [];
            if (data.json_data.operation && data.json_data.operation.status === 1)
            {
                // Retrieve verify result from callback's data
                verifyResult = data.json_data.operation.verify_details.checks.map((c:any) => (
                {
                    check: c.check_id,
                    successed: c.status_code == VerifyStatuses.VerifyStatus_Passed,
                    error_code: c.error_code,
                    status: c.status,
                    data: {...c.data}
                }))
            }
            else
            {
                dispatch(actionCreators.changeInspectionModuleStatus(module_index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE
                }));

                // In case data are no available in callback's data then retrieve verify result from reporting API
                /*const response = await _api.v1.picea.getVerify(data.operation_status.uid);
                console.debug("%c" + "LOGIC: getVerifyResult(" + data.operation_status.uid + ")", consoleStyles.piceaCollback);
                console.info({ response });
                if (response.successed && response.data) {
                    verifyResult = response.data;
                }
                else
                {
                    dispatch(actionCreators.addLog("FETCH_CATCH", endpoints.v1.picea.verify(data.operation_status.uid), { response }));
                    stop(undefined, () => {
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE
                        }));
                    });
                }*/
            }

            if (!verifyResult || verifyResult.length === 0) {
                dispatch(actionCreators.changeInspectionModuleStatus(module_index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE
                }));
            }

            if (stop_verify) return;

            const successed = verifyResult.filter(r => r.status === 1).length === verifyResult.length;
            verifyResult.forEach(i => {
                //If STOLEN_CHECK_FAILURE
                if(i.status == 32785){
                    dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.STOLEN_CHECK });
                }
            });
            dispatch(actionCreators.addEvents(verifyResult.map(i => {
                return {
                    action: sender,
                    status: `${verifyDescriber(i.check)}: ${verifyStatusDescriber(i.status)}`
                }
            })));

            // Результирующие грейды по категориѝм. Еѝли категории грейдов не иѝпользуютѝѝ, то
            // работаем ѝ пуѝтой категорией, как ѝ оѝновной
            let gradeCategories: IGradeWithCategory[] = [];

            // Функциѝ учёта приноѝимого грейда, котораѝ запиѝывает в результат наименьший грейд категории
            const receiveGrade = (category: string, grade: string) => {
                var gc = gradeCategories.find(gc => gc.category === category)
                // Еѝли грейд длѝ категории уже был ранее уѝтановлен, то обновлѝем грейд при уѝловии, что
                // новый принеѝённый грейд меньше уже уѝтановленного
                if (gc) {
                    gc.grade = worstGrades(category, gc.grade, grade);
                } else {
                    gradeCategories.push({ category, grade });
                }
            }

            // Функциѝ определениѝ худшего из 2-х грейдов
            const worstGrades = (category: string, g1: string, g2: string): string => {
                if (g1 === "" || g2 === "") return "";
                if (categories) {
                    const grade1 = categories.find(c => c.code === category)?.grades.find(g => g.code == g1);
                    const grade2 = categories.find(c => c.code === category)?.grades.find(g => g.code == g2);
                    return (grade1?.index ?? 0) > (grade2?.index ?? 0) ? g1 : g2;
                } else {
                    const grade1 = grades?.find(g => g.code == g1);
                    const grade2 = grades?.find(g => g.code == g2);
                    return (grade1?.index ?? 0) > (grade2?.index ?? 0) ? g1 : g2;
                }
            }

            if (!successed && moduleConfig.config?.downGrade !== undefined) {
                var downCategory = moduleConfig.config?.downGrade.indexOf("-") === -1 ? categories ? moduleConfig.gradesCategory : "" : moduleConfig.config?.downGrade.split("-")[0];
                var downGrade = moduleConfig.config?.downGrade.indexOf("-") === -1 ? moduleConfig.config?.downGrade : moduleConfig.config?.downGrade.split("-")[1];
                receiveGrade(downCategory ?? "", downGrade);
            }

            // Раѝчитываем грейд проверок
            verifyResult.map((c) => {
                const check_config = moduleConfig.config?.checks.find(cc => cc.id === c.check);
                c.successed = c.status === 1
                if (!c.successed && check_config?.grade !== undefined) {
                    var checkCategory = check_config.grade.indexOf("-") === -1 ? categories ? moduleConfig.gradesCategory : "" : check_config.grade.split("-")[0];
                    var checkGrade = check_config.grade.indexOf("-") === -1 ? check_config.grade : check_config.grade.split("-")[1];
                    c.grade = categories ? `${checkCategory}-${checkGrade}` : check_config.grade;
                    // Еѝли грейд ѝущеѝтвует и он ѝ категорией, то фикѝируем грейд категории
                    receiveGrade(checkCategory ?? "", checkGrade);
                }
            });

            let verifyGrade: string | undefined
            // Еѝли в процеѝѝе иѝпользуютѝѝ категории грейдов
            if (categories) {
                // Еѝли ѝреди категорий вѝтречаетѝѝ пуѝтой грейд (запрет приёма), то проваливаем веѝь результат проверок
                if (gradeCategories.length === 0) {
                    verifyGrade === undefined
                } else if (gradeCategories.find(gc => gc.grade === "")) {
                    verifyGrade = "";
                } else {
                    verifyGrade = gradeCategories.find(gc => gc.category === moduleConfig.gradesCategory)?.grade ?? "";
                }

            } else {
                if (gradeCategories.length === 0) {
                    verifyGrade === undefined
                } else if (gradeCategories.find(gc => gc.category === "")?.grade === "") {
                    verifyGrade = "";
                } else {
                    verifyGrade = gradeCategories.find(gc => gc.category === "")?.grade ?? "";
                }
            }

            if (verifyGrade === "" && moduleConfig.config?.allowFail) {
                verifyGrade = undefined
            }

            //stop(undefined, () => {
                dispatch(actionCreators.receiveInspectionModuleState(module_index, {
                    status: (successed || moduleConfig.config?.allowFail || verifyGrade) ? InspectionStatuses.Done : InspectionStatuses.Fail,
                    grade: verifyGrade,
                    grades: gradeCategories,
                    uid: generateUUID()/* data.operation_status.uid*/,
                    checks: verifyResult
                }));
            //});

        }
    },

    getPiceaDynamicLink: (session_id: string, key: string, service_type: string, callback: (error: PiceaGetApiError, data: { status: number, picea_link?: string }) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {

        console.debug("%c" + "Picea™ Online Services: ONLINE_API.getDynamicLink()", consoleStyles.logic);

        const config = {
            session_id: session_id,
            connection_type: PiceaConnectionTypes.NCD,
            key: key,
            service_type: service_type
        }

        interface IGetDynamicLinkCallbackData {
            status: number
            picea_link: string
            module_index: number
        }

        console.log( "config: " + JSON.stringify(config) );
        console.log({ config })

        window.ONLINE_API.getDynamicLink(config, (error: PiceaGetApiError, type: any, data: IGetDynamicLinkCallbackData) => {
            console.debug("%c" + "Picea™ Online Services: ONLINE_API.getDynamicLink()", consoleStyles.piceaCollback);
            console.info({ error, data });

            callback(error, data)

            // if (error) {
            //     dispatch({
            //         type: 'PROCESS_MESSAGE', message: errorDescriber(error.details.error ?? SocketErrors.ERR_SOCKET_NOT_CONNECTED), messageType: MessageBarType.error, onRetryAction: () => {
            //             dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
            //             dispatch(actionCreators.getPiceaDynamicLink(session_id, connection_type, module_index, callback));
            //         }
            //     });
            //     return;
            // }

            // if (data.status !== 0) {
            //     dispatch({
            //         type: 'PROCESS_MESSAGE', message: `Cannot get dynamic link (status: ${data.status} )`, messageType: MessageBarType.error, onRetryAction: () => {
            //             dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
            //             dispatch(actionCreators.getPiceaDynamicLink(session_id, connection_type, module_index, callback));
            //         }
            //     });
            //     return;
            // }

            // callback(data.status, data.picea_link)

        })
    },

    receiveProcessMessage: (message: IProcessMessage): AppThunkAction<KnownAction | AppThunkAction<TeachingKnownAction>> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.process) {
            dispatch({ type: 'PROCESS_MESSAGE', message: message });
        }
    },

    toggleProcessBlockerDialog: (): ProcessMessageBlockerDialogToggleAction => (
        { type: 'PROCESS_MESSAGE_BLOCKER_DIALOG_TOGGLE' }
    ),

    clearProcessMessage: (force?: boolean, code?: ProcessMessageCodes): AppThunkAction<KnownAction | AppThunkAction<TeachingKnownAction>> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.process) {
            dispatch({ type: 'PROCESS_MESSAGE_CLEAR', force: force, code: code });
        }
    },

    /** Adds Tech log for current process */
    addLog: (code: string, description: string, data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState.process.current) {
            await _api.v1.workplace.addLog(appState.process.current.id, code, description, data)
        }
    },

    /** Brings controlled transaction data into the current process. */
    receiveControlledTransaction: (data: ITransaction): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC receiveControlledTransaction()", consoleStyles.logic);

        dispatch(actionCreators.addLog("PROCESS", "PROCESS_RECEIVE_CONTROLLED_TRANSACTION", data));
        dispatch({ type: 'PROCESS_RECEIVE_CONTROLLED_TRANSACTION', data: data });
        // dispatch(actionCreators.addEvents([
        //     { action: strings.EVENTS.ACTION.DEVICE_IDENTIFICATION, status: `${device.manufacturer} ${device.name} ${device.configuration} ${device.attributes.capacity ?? ""}` },
        //     { action: strings.EVENTS.ACTION.IDENTIFICATION_METHOD, status: getLocalizedIdentificationMethod(method) }
        // ]));
    },

    /** Brings controlled transaction data into the current process. */
    continueWithoutControlledTransaction: (): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC continueWithoutControlledTransaction()", consoleStyles.logic);

        dispatch(actionCreators.addLog("PROCESS", "PROCESS_WITHOUT_CONTROLLED_TRANSACTION", {}));
        dispatch({ type: 'PROCESS_WITHOUT_CONTROLLED_TRANSACTION' });
        // dispatch(actionCreators.addEvents([
        //     { action: strings.EVENTS.ACTION.DEVICE_IDENTIFICATION, status: `${device.manufacturer} ${device.name} ${device.configuration} ${device.attributes.capacity ?? ""}` },
        //     { action: strings.EVENTS.ACTION.IDENTIFICATION_METHOD, status: getLocalizedIdentificationMethod(method) }
        // ]));
    },

    /** Imei lockout check. */
    imeiLockoutCheck: (device: IDevice, callback?: (isLocked: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction> | any> => async (dispatch, getState) => {
        console.debug("%c" + `LOGIC imeiLockoutCheck()`, consoleStyles.logic);
        console.log(device)
        const processState = getState().process.current;
        const config = processState?.workflow.imeiLockout;

        if (!config) {
            callback?.(false);
            return;
        }

        //  Last stage, and session is closed; check would give wrong answer, because we just finished processing this device.
        if( 0 < processState.stageIndex && processState.stageIndex == (processState.stages.length -1) && PiceaSessionState.CLOSED == processState.session_state) {
            console.debug("%c" + `LOGIC imeiLockoutCheck() session closed, skip check`, consoleStyles.logic);
            callback?.(false);
            return;
        }
        //  Probably pointless to check at these stages; should have been caught at Identification or Assessment already.
        if( [ProcessStages.Contract, ProcessStages.PostOffer, ProcessStages.Result, ProcessStages.Goodbye].includes( processState.stage)) {
            console.debug("%c" + `LOGIC imeiLockoutCheck() device assessed, skip check`, consoleStyles.logic);
            callback?.(false);
            return;
        }

        if (device.attributes[DeviceAttributes.IMEI]) {
            const deviceLocked = await executeImeiLockoutCheck(dispatch, device.attributes[DeviceAttributes.IMEI], processState, config);

            if (deviceLocked) {
                callback?.(true);
                return;
            }
        }

        if (device.attributes[DeviceAttributes.IMEI2]) {
            const deviceLocked = await executeImeiLockoutCheck(dispatch, device.attributes[DeviceAttributes.IMEI2], processState, config);

            if (deviceLocked) {
                callback?.(true);
                return;
            }
        }

        callback?.(false);
    },

    /** Brings new identification data into the current process. */
    receiveIdentificationModule: (index: number, device: IDevice, method: IdentificationMethods, isAuthenticated?: boolean, isConnected?: boolean, stamp?: string, warning?: IIdentificationWarning, withoutCheck?: boolean, callback?: (isLocked: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => (dispatch, getState) => {
        console.debug("%c" + `LOGIC receiveIdentificationModule(index: ${index})`, consoleStyles.logic);
        console.log(device)
        console.log("method: " + method)

        const state = getState();
        const currentInitialIdentificationMethod = state.process.current?.transaction.identity?.initialIdentificationMethod;

        let input: IIdentification = {
            isReady: true,
            device: device,
            method: method,
            isAuthenticated: isAuthenticated ?? false,
            isConnected: isConnected ?? false,
            stamp: stamp,
            warning: warning,
            index: index,
            initialIdentificationMethod: currentInitialIdentificationMethod ?? method
        }

        const stage = state.process.current?.stage;

        console.log("withoutCheck: " + withoutCheck);

        if (!withoutCheck) {
            dispatch(actionCreators.imeiLockoutCheck(device, (isLocked) => {
                dispatch(actionCreators.addLog(`IDENTIFICATION_MODULE_INDEX_${index}`, "PROCESS_RECEIVE_IDENTIFICATION", input));
                dispatch({ type: 'PROCESS_RECEIVE_IDENTIFICATION', moduleIndex: index, device: device, method: method, isAuthenticated: isAuthenticated ?? false, isConnected: isConnected ?? false, stamp: stamp, warning: warning });
                dispatch(actionCreators.addEvents([
                    { action: `${strings.EVENTS.ACTION.DEVICE_IDENTIFICATION}: ${index}`, status: `${device.manufacturer} ${device.name} ${device.configuration} ${device.attributes.capacity ?? ""}` },
                    { action: `${strings.EVENTS.ACTION.IDENTIFICATION_METHOD}: ${index}`, status: getLocalizedIdentificationMethod(method) }
                ]));
                dispatch({ type: 'PROCESS_IMEI_LOCKED', imeiLocked: isLocked });
                addNonConnectedDevice(stage, method, stamp, device, state, dispatch, index, input, isAuthenticated, isConnected, warning, getState);

                callback?.(isLocked);
            }));
        } else {
            dispatch(actionCreators.addLog(`IDENTIFICATION_MODULE_INDEX_${index}`, "PROCESS_RECEIVE_IDENTIFICATION", input));
            dispatch({ type: 'PROCESS_RECEIVE_IDENTIFICATION', moduleIndex: index, device: device, method: method, isAuthenticated: isAuthenticated ?? false, isConnected: isConnected ?? false, stamp: stamp, warning: warning });
            dispatch(actionCreators.addEvents([
                { action: `${strings.EVENTS.ACTION.DEVICE_IDENTIFICATION}: ${index}`, status: `${device.manufacturer} ${device.name} ${device.configuration} ${device.attributes.capacity ?? ""}` },
                { action: `${strings.EVENTS.ACTION.IDENTIFICATION_METHOD}: ${index}`, status: getLocalizedIdentificationMethod(method) }
            ]));

            addNonConnectedDevice(stage, method, stamp, device, state, dispatch, index, input, isAuthenticated, isConnected, warning, getState);

            callback?.(false);
        }
    },

    /** Action to clear identification data for the current process. */
    clearIdentification: (): ClearIdentificationAction => (
        { type: 'PROCESS_CLEAR_IDENTIFICATION' }
    ),
    
    /** Action to clear identity data for the current process. */
    clearTransactionIdentify: (): ClearTransactionIdentity => (
        { type: 'CLEAR_TRANSACTION_IDENTITY' }
    ),

    /** Unlock selected or current stage inspections which using Piceasoft API. */
    unlockPiceasoftInspectionModules: (stageIndex?: number, fromModuleIndex?: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        console.debug("%c" + `LOGIC unlockPiceasoftInspectionModules(stageIndex: ${stageIndex}, fromModuleIndex: ${fromModuleIndex})`, consoleStyles.logic);
        dispatch({ type: 'PROCESS_TRANSACTION_STAGE_UNLOCK_PICEASOFT_MODULES', stageIndex: stageIndex, fromModuleIndex: fromModuleIndex })
    },

    /** Lock selected or current stage inspections which using Piceasoft API. */
    lockPiceasoftInspectionModules: (stageIndex?: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        console.debug("%c" + `LOGIC lockPiceasoftInspectionModules(index: ${stageIndex})`, consoleStyles.logic);
        dispatch({ type: 'PROCESS_TRANSACTION_STAGE_LOCK_PICEASOFT_MODULES', stageIndex: stageIndex })
    },

    /** Action to clear Controlled transaction data of the current process. */
    clearControlledTransaction: (): ClearControlledTransactionAction => (
        { type: 'PROCESS_CLEAR_CONTROLLED_TRANSACTION' }
    ),

    /** Action to clear Offers of the current process. */
    clearOffers: (): ClearOffersAction => (
        { type: 'PROCESS_CLEAR_OFFERS' }
    ),

    /** Action to change the status of the identified device. */
    changeConnectionStatus: (status: boolean, stamp?: string): ChangeConnectionStatusAction => (
        { type: 'PROCESS_IDENTIFICATION_CHANGE_CONNECTION_STATUS', status: status, stamp: stamp }
    ),

    /** */
    receiveDiscount: (discount: IDiscount): ReceiveDiscountAction => (
        { type: 'PROCESS_TRANSACTION_RECEIVE_DISCOUNT', discount: discount }
    ),

    /** */
    removeDiscount: (): RemoveDiscountAction => (
        { type: 'PROCESS_TRANSACTION_REMOVE_DISCOUNT' }
    ),

    /** Brings promotion into current transaction. */
    receiveProviderPromotion: (promo?: { promo_id: string, provider_code: string }): AppThunkAction<KnownAction | AppThunkAction<KnownAction> | any> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC receiveProviderPromotion(promo)", consoleStyles.logic);
        console.info({ promo });

        const process = getState().process.current;
        if (process) {
            if (!promo) {
                dispatch({ type: 'PROCESS_TRANSACTION_REMOVE_PROMOTION' });
                dispatch(actionCreators.updateTransaction(process.transaction.uid, [
                    { key: TransactionKeys.PROMOTION, value: null }
                ]));
                return;
            }

            let selectedPromo: ISelectedPromo | undefined = undefined
            let isValid = false

            const selectedPromoProviderConfig = process.workflow.commonOffer?.promoProviders?.find(i => i.code === promo.provider_code)
            const offerProviderConfig = process?.workflow.commonOffer?.providers?.find(i => i.code === process?.transaction.selectedProvider)
            const selectedPromoState = process?.promoProviders?.find(i => i.code === promo?.provider_code)?.result?.data?.promotions?.find(i => i.id === promo?.promo_id)
            if (selectedPromoProviderConfig && selectedPromoState) {
                selectedPromo = {
                    ...promo,
                    state: {
                        ...selectedPromoState,
                        provider_code: promo.provider_code,
                        provider_name: selectedPromoProviderConfig.name,
                        provider_description: selectedPromoProviderConfig.description,
                        provider_img: selectedPromoProviderConfig.imageSrc,
                        currency: selectedPromoProviderConfig.currency
                    }
                }
                isValid = validatePromotion(
                    { ...selectedPromoState, provider_code: promo.provider_code },
                    process.transaction.assessment.grade,
                    process.transaction.selectedProvider,
                    offerProviderConfig?.allowPromotions
                );
            }

            console.log(selectedPromo)
            if (process.transaction && selectedPromo) {
                dispatch({ type: 'PROCESS_TRANSACTION_RECEIVE_PROMOTION', promo: selectedPromo, isValid: isValid });
                dispatch(actionCreators.updateTransaction(process.transaction.uid, [
                    { key: TransactionKeys.PROMOTION, value: JSON.stringify(selectedPromo.state) ?? null }
                ]));
            }
        }
    },

    /** Action to inform system that contact's data were received and contract(Print form) stage is completed */
    receiveContract: (data: IFieldsData, completeStage:boolean): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        dispatch({ type: 'PROCESS_TRANSACTION_RECEIVE_CONTRACT', data, completeStage })
    },

    /** Action to inform system that data from Data Collection module were received  and stage is completed */
    prefillContractAction: (data:IFieldsData): PrefillContractAction => {
        return { type: 'PROCESS_TRANSACTION_PREFILL_CONTRACT', data: data}
    },

    /** Action to prepare initial state of Offers according to configuration of workflow. */
    prepareOfferProvidersState: (): PrepareOfferProvidersStateAction => (
        { type: 'PROCESS_PREPARE_OFFER_PROVIDERS_STATE' }
    ),

    /** Action to prepare initial state of Promo providers according to configuration of workflow. */
    preparePromoProvidersState: (): PreparePromoProvidersStateAction => (
        { type: 'PROCESS_PREPARE_PROMO_PROVIDERS_STATE' }
    ),

    /** Moves workflow to the next stage of the workflow. Version 3 */
    nextStageV3: (withCompletion?: boolean): AppThunkAction<KnownAction | AppThunkAction<KnownAction | any>> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC nextStageV3()", consoleStyles.logic);
        const appState = getState();
        const workflow = appState.process.current?.workflow;

        if (withCompletion && appState.process.current) {
            appState.process.current = {
                ...appState.process.current,
                stageCompleted: withCompletion
            }
        }

        const stageIndex = appState.process.current?.stageIndex
        const stageConfig = workflow?.stages?.find(i => i.index === stageIndex)
        const stageType = stageConfig?.type
        const stageIsCompleted = appState.process.current?.stageCompleted
        let nextStageConfig = workflow?.stages?.sort((a, b) => a.index - b.index).find(i => typeof stageIndex === 'number' && stageIsCompleted && i.index > stageIndex)

        if (typeof stageIndex !== "number" && workflow?.stages && workflow?.stages?.length > 0) {
            nextStageConfig = workflow?.stages[0]
        }

        let createTransaction = (stageType === ProcessStages.PreOffer && stageIsCompleted) ||
                                (stageType === ProcessStages.Identification && stageIsCompleted && nextStageConfig?.type !== ProcessStages.PreOffer);

        if (createTransaction) {
            if (!appState.process?.current?.identification?.method) {
                console.error("nextStageV3: Could not determine identification method!");
            }
            else {

                const connectionType = getConnectionTypeByIdentificationMethod(appState.process.current.identification.method);

                const identification_stamp = appState.process.current?.identification?.stamp;
                const transaction_identity_stamp = appState.process.current?.transaction?.identity?.stamp;

                const device_id = transaction_identity_stamp || identification_stamp;

                if (!appState.process.current?.transaction.number) {
                    console.log("nextStageV3: request transaction before session opening");
                    dispatch(actionCreators.requestTransaction(() => {
                        dispatch(actionCreators.nextStageV3());
                    }))

                    // we exit here. After transaction is opened the nextStageV3 execution restarts, and handles the rest.
                    return;
                }

                if (!appState.process.current?.session_id && device_id && typeof connectionType === 'number') {
                    console.log("nextStageV3: processing Identification, opening session");
                    dispatch(actionCreators.openPiceaOnlineSession(device_id as string, connectionType, (succeeded, session_id)=>{
                        if (succeeded) {
                            // call again, to complete the identification phase (the else branch of session opening)
                            dispatch(actionCreators.nextStageV3());
                        }
                        else {
                            console.error("nextStageV3: Failed to open session, cannot continue");
                        }
                    }));

                    // we exit here. After session is opened the nextStageV3 execution restarts, and handles the rest.
                    return;
                }
            }
            console.log("Continuing nextStageV3 handling");
        }

        if (typeof stageIndex !== "number" || (stageConfig && stageIsCompleted)) {
            const transactionData: ITransactionValue[] = []

            if (stageConfig && stageConfig.type === ProcessStages.PostOffer) {
                let operationDetails = getOfferOperationDetails(appState);
                if(operationDetails?.offers){
                    operationDetails.offers.forEach(of => {
                        if(of.selected && of.sku && appState.process.current?.transaction.uid){
                            dispatch(actionCreators.updateTransaction(appState.process.current?.transaction.uid, [
                                { key: "SKU", value: of.sku }
                            ]));
                        }
                    })
                }
                dispatch(actionCreators.addPiceaOnlineOperation(OperationTypes.OFFER, operationDetails));
            }

            if(stageIsCompleted && stageType === ProcessStages.Assessment) {
                const assessmentDetails = getAssessmentOperationDetails(appState);
                dispatch(actionCreators.addPiceaOnlineOperation(OperationTypes.ASSESSMENT, assessmentDetails));
            }

            if (nextStageConfig) {
                transactionData.push({ key: TransactionKeys.STAGE_INDEX, value: nextStageConfig.index })

                if (typeof stageIndex === "number" && [
                    ProcessStages.Assessment,
                    ProcessStages.PostOffer,
                    ProcessStages.Contract,
                    ProcessStages.Control,
                    ProcessStages.ContentTransfer
                ].includes(nextStageConfig.type)) {
                    if (!appState.process.current?.transaction.id) {
                        dispatch(actionCreators.requestTransaction())
                    }
                }

                if (nextStageConfig.type === ProcessStages.PreOffer
                    || nextStageConfig.type === ProcessStages.PostOffer) {
                    dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.OFFER });
                }
                if (nextStageConfig.type === ProcessStages.Contract) {
                    dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.CONTRACT });
                }

                if ([ProcessStages.Assessment, ProcessStages.Control].includes(nextStageConfig.type) && appState.process.current?.transaction.identity) {
                    if ([IdentificationMethods.PiceaOne, IdentificationMethods.PiceaUsb].includes(appState.process.current?.transaction.identity?.method)) {
                        dispatch(actionCreators.unlockPiceasoftInspectionModules(nextStageConfig.index))
                    } else {
                        dispatch(actionCreators.lockPiceasoftInspectionModules(nextStageConfig.index))
                    }
                }

                if (nextStageConfig.type === ProcessStages.PostOffer) {
                    dispatch(actionCreators.prepareOfferProvidersState())
                    dispatch(actionCreators.preparePromoProvidersState())
                }


                if (nextStageConfig.type === ProcessStages.Result) {
                    console.log('Created')
                    transactionData.push({ key: TransactionKeys.STATUS, value: TransactionLogFlags.Created })
                    dispatch(actionCreators.notifyProvider())
                }

                if (!appState.process.current?.message?.isBlocker) {
                    dispatch({ type: 'PROCESS_NEXT_STAGE_INDEX', nextIndex: nextStageConfig.index });
                }

            } else {
                console.log('Created')
                transactionData.push({ key: TransactionKeys.STATUS, value: TransactionLogFlags.Created })
                dispatch(actionCreators.updateCurrentTransaction(transactionData));
                dispatch(actionCreators.notifyProvider())
                dispatch({ type: 'PROCESS_CANCEL' })
            }
            if (transactionData.length > 0) {
                dispatch(actionCreators.updateCurrentTransaction(transactionData));
            }
        }
    },

    setPreviousStage: (): ReceivePreviousStageAction => (
        { type: 'PROCESS_RECEIVE_PREVIOUS_STAGE' }
    ),

    /** Завершить текущий ѝтап процеѝѝа. */
    completeCurrentStage: (completed?: boolean): ReceiveStageCompletedAction => (
        { type: 'PROCESS_RECEIVE_STAGE_COMPLETED', completed: completed }
    ),

    /** Завершить текущий ѝтап процеѝѝа. */
    receiveStageAction: (stageAction?: () => void): ReceiveStageActionAction => (
        { type: 'PROCESS_RECEIVE_STAGE_ACTION', stageAction: stageAction }
    ),
    
    /** set function to execute in back button */
    receiveBackAction: (backAction?: () => void): ReceiveBackActionAction => (
        { type: 'PROCESS_RECEIVE_BACK_ACTION', backAction: backAction }
    ),

    /** Уѝтановить флаг получениѝ данных транзакции. */
    startTransactionFetch: (): StartTransactionFetchAction => (
        { type: 'PROCESS_TRANSACTION_FETCH_START' }
    ),

    /** Убрать флаг получениѝ данных транзакции. */
    stopTransactionFetch: (): StopTransactionFetchAction => (
        { type: 'PROCESS_TRANSACTION_FETCH_STOP' }
    ),

    /** Запроѝ коммерчеѝкого предложениѝ длѝ указанного уѝтройѝтва. */
    requestPreOffer: (device: IDevice): AppThunkAction<any> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestPreOffer(device)", consoleStyles.logic);
        console.info({ device });
        dispatch(actionCreators.addLog("IDENTIFICATION", "requestPreOffer(device)", device));
        const appState = getState();
        if (appState && appState.process && appState.process.current) {
            dispatch({ type: 'PROCESS_REQUEST_PRE_OFFER' });
            const url = endpoints.v1.process.requestOffer(
                appState.process.current.service.id,
                appState.process.current.id,
                device.manufacturer, device.name, device.configuration, true, device.group,
                device.attributes[DeviceAttributes.IMEI], appState.process.current.transaction.uid
            );
            const apiResponse = await _api.v1.offer.getPreOffer(
                appState.process.current.service.id, appState.process.current.id,
                device.manufacturer, device.name, device.configuration, true, device.group,
                device.attributes[DeviceAttributes.IMEI], appState.process.current.transaction.uid
            );
            if (apiResponse.successed && apiResponse.data) {
                console.debug("%c" + "LOGIC requestPreOffer(device) response", consoleStyles.logic);
                console.info({ apiResponse });
                dispatch({ type: 'PROCESS_RECEIVE_PRE_OFFER', offer: apiResponse.data });
                dispatch(actionCreators.addLog("IDENTIFICATION", "PROCESS_RECEIVE_PRE_OFFER", apiResponse.data));
            } else {
                if (apiResponse.errors && apiResponse.errors.length > 0) {
                    dispatch(actionCreators.addLog("IDENTIFICATION", "FETCH " + url, apiResponse.errors[0]?.description));
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: apiResponse.errors[0]?.description,
                        messageType: MessageBarType.error,
                        onRetryAction: () => {
                            dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                            dispatch(actionCreators.requestPreOffer(device));
                        }
                    });
                }
            }
        }
    },

    /** Запроѝ акций длѝ указанного уѝтройѝтва от провайдера. */
    requestProviderPromo: (device: IDevice, providerCode: string): AppThunkAction<any> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestProviderPromo(device, providerCode)", consoleStyles.logic);
        console.info({ device });
        console.info({ providerCode });
        dispatch(actionCreators.addLog("OFFER", "requestProviderPromo(device, provider)", { device: device, providerCode: providerCode }));
        const appState = getState();
        if (appState && appState.process && appState.process.current) {
            const providerConfig = appState.process.current.workflow.commonOffer?.promoProviders?.find(i => i.code === providerCode)
            const device = appState.process.current.transaction.identity?.device ?? appState.process.current.identification?.device
            if (providerConfig && device) {
                dispatch({ type: 'PROCESS_REQUEST_PROVIDER_PROMO', code: providerCode, propviderType: providerConfig.type });
                let apiResponse = await _api.v1.offer.getProviderPromo(appState.process.current.id, providerCode, device, appState.process.current.transaction.assessment.grade);

                if (apiResponse?.successed && apiResponse?.data) {
                    console.debug("%c" + "LOGIC requestProviderPromo(device, provider) response", consoleStyles.logic);
                    console.info({ apiResponse });
                    dispatch({ type: 'PROCESS_RECEIVE_PROVIDER_PROMO', providerCode: providerCode, providerType: providerConfig.type, response: apiResponse, gradesMap: providerConfig.gradesMap });
                    dispatch(actionCreators.addLog("OFFER", "PROCESS_RECEIVE_PROVIDER_PROMO", apiResponse.data));
                }
                else {
                    if (apiResponse) {
                        if (apiResponse.errors && apiResponse.errors.length > 0) {
                            apiResponse = {
                                successed: apiResponse.successed,
                                data: {
                                    ...apiResponse.data,
                                    process_id: appState.process.current.id,
                                    organization_id: "",
                                    errors: [...(apiResponse.data?.errors ?? []), ...(apiResponse.errors ?? [])],
                                    device: apiResponse.data?.device
                                }
                            }
                        }
                        dispatch({ type: 'PROCESS_RECEIVE_PROVIDER_PROMO', providerCode: providerCode, providerType: providerConfig.type, response: apiResponse, gradesMap: providerConfig.gradesMap });
                        dispatch(actionCreators.addLog("OFFER", "PROCESS_RECEIVE_PROVIDER_PROMO", apiResponse.data));
                    } else {
                        apiResponse = {
                            successed: false,
                            data: {
                                process_id: appState.process.current.id,
                                organization_id: "",
                                device: device
                            },
                            errors: [{ description: "Request problem" }]
                        }
                        dispatch({ type: 'PROCESS_RECEIVE_PROVIDER_PROMO', providerCode: providerCode, providerType: providerConfig.type, response: apiResponse, gradesMap: providerConfig.gradesMap });
                    }
                }
            } else {
                dispatch(actionCreators.addLog("OFFER", "promotion providerConfig not found", { providerCode: providerCode, providers: appState.process.current.workflow?.commonOffer?.promoProviders }));
                dispatch({ type: 'PROCESS_MESSAGE', message: "promotion providerConfig not found", messageType: MessageBarType.error });
            }
        }
    },

    requestAllProviderOffers: (device: IDevice): AppThunkAction<any> => async (dispatch, getState) => {
        const appState = getState();
        const offerProviders = appState.process.current?.offerProviders;
        offerProviders?.forEach( p => {
            dispatch(actionCreators.requestProviderOffer(device, p.code))
        })
    },
    
    /** Запроѝ предложениѝ длѝ указанного уѝтройѝтва от провайдера. */
    requestProviderOffer: (device: IDevice, providerCode: string): AppThunkAction<any> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestProviderOffer(device, providerCode)", consoleStyles.logic);
        console.info({ device });
        console.info({ providerCode });
        dispatch(actionCreators.addLog("OFFER", "requestProviderOffer(device, provider)", { device: device, providerCode: providerCode }));
        const appState = getState();
        if (appState && appState.process && appState.process.current) {
            const currentProcess = appState.process.current;
            const providerConfig = currentProcess.workflow?.commonOffer?.providers?.find(i => i.code === providerCode) ?? currentProcess.workflow?.offer?.providers?.find(i => i.code === providerCode)
            const currentTransaction = currentProcess.transaction;
            const device = currentTransaction.identity?.device ?? currentProcess.identification?.device
            if (providerConfig && device) {
                dispatch({ type: 'PROCESS_REQUEST_PROVIDER_OFFER', code: providerCode, propviderType: providerConfig.type });

                const gradeCategories = currentProcess.workflow?.useGradesCategories ?
                    getAssesmentGradeCategoryResults(currentTransaction.assessment.grade, currentProcess.workflow?.gradesDelimiter ?? ":", currentProcess.workflow?.gradesCategories)
                    : null;

                // grade parameter is added to the method, in order to be able to get the final price
                // from Trading Vendor API in case of using Grade categories
                let apiResponse = await _api.v1.offer.getProviderOffer(
                    appState.process.current.id,
                    providerCode, 
                    device, 
                    currentProcess.controlledTransaction?.number ?? null,
                    !gradeCategories ? currentTransaction.assessment?.grade : null,
                    gradeCategories, 
                    currentTransaction.isValidPromotion ? currentTransaction.selectedPromo : undefined
                );

                if (apiResponse?.successed && apiResponse?.data) {
                    console.debug("%c" + "LOGIC requestProviderOffer(device, provider) response", consoleStyles.logic);
                    console.info({ apiResponse, providerConfig });
                    dispatch({ type: 'PROCESS_RECEIVE_PROVIDER_OFFER', providerCode: providerCode, providerType: providerConfig.type, response: apiResponse, gradesMap: providerConfig.gradesMap });
                    dispatch(actionCreators.addLog("OFFER", "PROCESS_RECEIVE_PROVIDER_OFFER", apiResponse.data));
                }
                else {
                    if (apiResponse) {
                        if (apiResponse.errors && apiResponse.errors.length > 0) {
                            apiResponse = {
                                successed: apiResponse.successed,
                                data: {
                                    ...apiResponse.data,
                                    process_id: appState.process.current.id,
                                    organization_id: "",
                                    errors: [...(apiResponse.data?.errors ?? []), ...(apiResponse.errors ?? [])],
                                    device: apiResponse.data?.device,
                                }
                            }
                        }
                        dispatch({ type: 'PROCESS_RECEIVE_PROVIDER_OFFER', providerCode: providerCode, providerType: providerConfig.type, response: apiResponse, gradesMap: providerConfig.gradesMap });
                        dispatch(actionCreators.addLog("OFFER", "PROCESS_RECEIVE_PROVIDER_OFFER", apiResponse.data));
                    } else {
                        apiResponse = {
                            successed: false,
                            data: {
                                process_id: appState.process.current.id,
                                organization_id: "",
                                device: device
                            },
                            errors: [{ description: "Request problem" }]
                        }
                        dispatch({ type: 'PROCESS_RECEIVE_PROVIDER_OFFER', providerCode: providerCode, providerType: providerConfig.type, response: apiResponse, gradesMap: providerConfig.gradesMap });
                    }
                }
            } else {
                dispatch(actionCreators.addLog("OFFER", "providerConfig not found", { providerCode: providerCode, providers: currentProcess.workflow?.offer?.providers ?? currentProcess.workflow?.commonOffer?.providers }));
                dispatch({ type: 'PROCESS_MESSAGE', message: "providerConfig not found", messageType: MessageBarType.error });
            }
        }
    },

    /** Запроѝ предложениѝ длѝ указанного уѝтройѝтва от провайдера. */
    notifyProvider: (): AppThunkAction<any> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC notifyProvider()", consoleStyles.logic);
        const appState = getState();
        const currentProcess = appState?.process?.current
        if (appState && currentProcess && currentProcess.transaction.selectedProvider && currentProcess.transaction.uid) {
            const providerConfig = currentProcess.workflow.commonOffer?.providers?.find(i => i.code === currentProcess.transaction.selectedProvider) ?? currentProcess.workflow.offer?.providers?.find(i => i.code === currentProcess.transaction.selectedProvider)
            if (providerConfig && providerConfig.notifyEndpoint) {
                const apiResponse = await _api.v1.offer.notifyProvider(currentProcess.id, 
                                                                        currentProcess.transaction.uid, 
                                                                        providerConfig.code, 
                                                                        currentProcess.controlledTransaction?.number ?? null);
                if (apiResponse?.successed && apiResponse?.data) {
                    console.debug("%c" + `LOGIC notifyProvider(${providerConfig.code}) response`, consoleStyles.logic);
                }
            }
        }
    },

    /** Initiates a request to create a new transaction. */
    requestTransaction: (callback?:() => void): AppThunkAction<any> => async (dispatch, getState) => {
        
        if(isTransactionFetching) return;

        isTransactionFetching = true;

        console.debug("%c" + "LOGIC requestTransaction()", consoleStyles.logic);
        const appState = getState();

        try {
            if (appState && appState.process.current?.identification?.device) {
                dispatch({ type: 'PROCESS_REQUEST_TRANSACTION' });
                const content: ITransactionDevice = {
                    stamp: appState.process.current.identification.stamp,
                    isAuthenticated: appState.process.current.identification.isAuthenticated,
                    method: appState.process.current.identification.method,
                    device: appState.process.current.identification.device,
                    index: appState.process.current.identification.index,
                    initialIdentificationMethod: appState.process.current.identification.method
                }
                dispatch(actionCreators.addLog("PROCESS", "PROCESS_REQUEST_TRANSACTION", content));
                const apiResponse = await _api.v1.transaction.getTransaction(appState.process.current.id, appState.process.current.transaction.discount?.id.toString(), content, appState.process.current.offers?.sku);
                if (apiResponse.successed && apiResponse.data) {
                    const transactionData: ITransactionValue[] = [
                        { key: TransactionKeys.CLIENT_TIME, value: getCurrentTime() },
                        { key: TransactionKeys.STAGE, value: appState.process.current.stage }
                    ]

                    if (appState.process.current.transaction.selectedPromo) {
                        transactionData.push({ key: TransactionKeys.PROMOTION, value: JSON.stringify(appState.process.current.transaction.selectedPromo.state) })
                    }

                    if( appState.process.current.contentTransfer?.selectedTarget) {

                        const selectedTarget = appState.process.current.contentTransfer.selectedTarget;
                        const target: ITransactionDevice = {
                            stamp: selectedTarget.device_id,
                            isAuthenticated: true,
                            method: IdentificationMethods.PiceaUsb,
                            device: deviceBuild( selectedTarget),
                            index: 0
                        }
                        transactionData.push({ key: TransactionKeys.TARGET_DEVICE, value: JSON.stringify(target) });
                    }
                    if( appState.process.current.contentTransfer?.selectedSource) {
                        const selectedSource = appState.process.current.contentTransfer.selectedSource;
                        const source: ITransactionDevice = {
                            stamp: selectedSource.device_id,
                            isAuthenticated: true,
                            method: IdentificationMethods.PiceaUsb,
                            device: deviceBuild( selectedSource),
                            index: 0
                        }
                        transactionData.push({ key: TransactionKeys.SOURCE_DEVICE, value: JSON.stringify(source) });
                    }

                    dispatch(actionCreators.updateTransaction(apiResponse.data.uid, transactionData));
                    dispatch({ type: 'PROCESS_RECEIVE_TRANSACTION', transaction: apiResponse.data });
                    dispatch(actionCreators.addLog("PROCESS", "PROCESS_RECEIVE_TRANSACTION", apiResponse.data));

                    if(callback) {
                        callback();
                    }

                    // If we start a transaction in which there is no evaluation stage, then we immediately set the minimum grade
                    if (appState.process.current?.offers && !appState.process.current.workflow.assessment) {
                        const offers = appState.process.current.offers.items?.filter(i => i.price > 0);
                        if (offers && offers.length > 0) {
                            const offer = offers[offers.length - 1];
                            let grade = offer.grade;
                            if (!grade && offer.gradeCategories) {
                                // convert to single line grade like "A-?-B"
                                grade = offer.gradeCategories.map(item => item.grade ?? '?').join(appState.process.current?.workflow.gradesDelimiter ?? ":");
                            }
                            dispatch(actionCreators.receiveAssessment(grade ?? "", offer.price));
                        }
                    }
                } else {
                    dispatch(actionCreators.addLog("IDENTIFICATION", "FETCH " + endpoints.v1.process.requestTransaction(appState.process.current.id, appState.process.current.transaction.discount?.id.toString()), apiResponse.error));
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: strings.ACTIONS_MESSAGES.PROCESS.PROCESS_INITIALIZATION_PROBLEM,
                        messageType: MessageBarType.error,
                        onRetryAction: () => {
                            dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                            dispatch(actionCreators.requestTransaction());

                        }
                    });


                }
            }
        } catch (error) {
            console.info(`requestTransaction error: ${error}`)
            isTransactionFetching = false;
        }
        isTransactionFetching = false;
    },

    /** Updates the identified transaction device data. */
    updateTransactionIdentity: (identity: ITransactionDevice, withoutCheck?: boolean, sourceOfCall?:string): AppThunkAction<KnownAction | AppThunkAction<KnownAction> | any> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateTransactionIdentity(identity)", consoleStyles.logic);
        console.info({ identity });
        const appState = getState();
        if (appState.process.current?.transaction.identity) {
            dispatch(actionCreators.addLog("PICEA", "updateTransactionIdentity(identity)", { identity, source: sourceOfCall, session_id: appState.process.current.session_id }));
            dispatch({ type: 'PROCESS_TRANSACTION_UPDATE_IDENTITY', identity: identity });
            dispatch(actionCreators.updateTransaction(appState.process.current.transaction.uid, [
                { key: TransactionKeys.IDENTITY, value: JSON.stringify(identity) }
            ]));
            if (!withoutCheck) {
                dispatch(actionCreators.imeiLockoutCheck(identity.device, (isLocked) => {
                    dispatch({ type: 'PROCESS_IMEI_LOCKED', imeiLocked: isLocked });
                }));
            }
        }
    },

    /** Updates the identified transaction device data (from the connected device data). */
    refreshTransactionIdentity: (): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        const identity = appState.process.current?.transaction.identity;
        const identification = appState.process.current?.identification;
        console.debug("%c" + "LOGIC refreshTransactionIdentity(identity)", consoleStyles.logic);
        console.info({ identification, identity });
        if (appState.process.current && identification && identity) {
            let _identity = {
                ...identity,
                device: {
                    ...identification.device,
                    attributes: { ...identification.device.attributes }
                },
                method: identification.method,
                isAuthenticated: identification.isAuthenticated,
                stamp: identification.stamp
            }
            dispatch(actionCreators.addLog("IDENTITY", "Refresh current identity", {currentIdentity:identity, identification, newIdentity:_identity}));
            dispatch(actionCreators.updateTransactionIdentity(_identity, false, "RefershTransactionIdentity"));
            dispatch(actionCreators.updateTransaction(appState.process.current.transaction.uid, [
                { key: TransactionKeys.IDENTITY, value: JSON.stringify(_identity) }
            ]));
            dispatch(actionCreators.addEvent(strings.EVENTS.ACTION.DEVICE_UPDATE, strings.EVENTS.STATUS.DEVICE_DATA_WAS_UPDATED));
        }
    },

    /** Updates transaction data. */
    updateTransaction: (uid: string, data: ITransactionValue[], callback?: (successed: boolean) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateTransaction('" + uid + "', data)", consoleStyles.logic);
        const newData = data.filter(i => i.value === null || i.value)
        console.info({ uid, newData });
        var response = await _api.v1.transaction.updateTransaction(uid, newData);
        if (response.successed && response.data) {
            // console.debug("%c" + "LOGIC updateTransaction('" + uid + "', data) SUCCESSED", consoleStyles.logic);
            if (callback) callback(true);
        } else {
            console.debug("%c" + "LOGIC updateTransaction('" + uid + "', data) FAILED", consoleStyles.logic);
            if (callback) callback(false);
        }
    },
    
    sendContract: (uid: string, templateId?: number, callback?: (result: IResponseResult<undefined>) => void): AppThunkAction<KnownAction> => async () => {
        var response = await _api.v1.transaction.sendContractToCustomer(uid, templateId);
        if (callback) callback(response)
    },
    
    /** Sets the control transaction identifier for the controlled one. */
    setControlTransactionId: (transactionId: string, controlTransactionId: string, callback?: (successed: boolean) => void): AppThunkAction<any> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC setControlTransactionId('" + transactionId + ", " + controlTransactionId + ")", consoleStyles.logic);
        console.info({ transactionId, controlTransactionId });
        var response = await _api.v1.transaction.setControlTransactionId(transactionId, controlTransactionId);
        console.log(response)
        if (response.successed) {
            console.debug("%c" + "LOGIC setControlTransactionId('" + transactionId + ", " + controlTransactionId + ") SUCCESS", consoleStyles.logic);
            dispatch({
                type: 'PROCESS_MESSAGE',
                message: strings.PROCESS.CONTROLLED_TRANSACTION.SET_CONTROL_TRANSACTION_SUCCESS,
                messageType: MessageBarType.success
            })
            if (callback) callback(true);
        } else {
            console.debug("%c" + "LOGIC setControlTransactionId('" + transactionId + ", " + controlTransactionId + ") FAILED", consoleStyles.logic);
            dispatch({
                type: 'PROCESS_MESSAGE',
                message: strings.PROCESS.CONTROLLED_TRANSACTION.SET_CONTROL_TRANSACTION_ERROR,
                messageType: MessageBarType.error,
                onRetryAction: () => {
                    dispatch(actionCreators.setControlTransactionId(transactionId, controlTransactionId, callback))
                }
            })
            if (callback) callback(false);
        }
    },

    printSticker: (tx: ITransaction): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC printSticker(tx)", consoleStyles.logic);
        console.debug(tx);

        await _api.v1.transaction.postSticker({
            tx: tx?.number,
            device: (tx?.identity?.device.manufacturer ? (tx?.identity?.device.manufacturer + " ") : "") + tx?.identity?.device.name,
            imei: tx?.identity?.device.attributes.imei,
            grade: tx?.assessment.grade,
            date: new Date(tx?.creationDate as unknown as string).toLocaleString(),
            batch: "1"
        });
    },

    /** Updates transaction data. */
    updateCurrentTransaction: (data: ITransactionValue[], callback?: (successed: boolean) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.debug("%c" + "LOGIC updateCurrentTransaction(data)", consoleStyles.logic);
        console.info({ data });
        const uid = appState.process.current?.transaction.uid;
        if (!uid) return null;
        var response = await _api.v1.transaction.updateTransaction(uid, data);
        if (response.successed && response.data) {
            // console.debug("%c" + "LOGIC updateTransaction(data) SUCCESSED", consoleStyles.logic);
            if (callback) callback(true);
        } else {
            console.debug("%c" + "LOGIC updateTransaction(data) FAILED", consoleStyles.logic);
            if (callback) callback(false);
        }
    },

    /** Initialize request to cancel current process. */
    cancelProcess: (reason?: string): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC cancelProcess('" + reason + "')", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.process.current?.transaction) {
            if (appState.process.current.transaction.stage !== ProcessStages.Result) {
                dispatch(actionCreators.addEvent(strings.EVENTS.ACTION.TRANSACTION_CANCELATION, reason ?? strings.EVENTS.STATUS.WITHOUT_REASON));
                dispatch(actionCreators.addLog("PROCESS", "PROCESS_CANCEL", { reason }));
                dispatch(actionCreators.updateTransaction(appState.process.current.transaction.uid, [
                    { key: TransactionKeys.STATUS, value: TransactionLogFlags.Canceled },
                    { key: TransactionKeys.CANCEL_REASON, value: reason ?? strings.EVENTS.STATUS.NO_INFORMATION }
                ]));
            }
            dispatch({ type: 'PROCESS_CANCEL' })
        }
    },

    /** Sets final grade and price for assessment stage */
    receiveAssessment: (grade: string, price: number, callback?: (successed: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => async (dispatch, getState) => {
        const state = getState();
        if (!state.process.current) return null;

        let transactionData: ITransactionValue[] = []

        transactionData.push({ key: TransactionKeys.ASSESSMENT_GRADE, value: grade })
        transactionData.push({ key: TransactionKeys.ASSESSMENT_PRICE, value: price })
        transactionData.push({ key: TransactionKeys.ASSESSMENT, value: JSON.stringify(getState().process.current?.transaction.assessment) })

        let grades: { [key: string]: string } = {}
        getState().process.current?.transaction.assessment.resultGrades?.forEach(rg => grades[rg.gradeCategoryCode] = rg.code)

        transactionData.push({ key: TransactionKeys.ASSESSMENT_GRADES, value: JSON.stringify(grades) })

        const receiveAssessmentCallback = (successed: boolean) => {
            if (!successed) {
                dispatch({type: 'PROCESS_MESSAGE',
                    message: {
                        text: strings.PROCESS.ASSESSMENT.WRITE_ERROR,
                        type: MessageBarType.error
                    }
                })

                dispatch(actionCreators.addLog('ASSESSMENT', 'RECEIVE_RESULT_ERROR', {
                    grade, price, grades,
                    error: {
                        messaage: strings.PROCESS.ASSESSMENT.WRITE_ERROR
                    }
                }))

            }
            if (successed) {
                if (callback) {
                    callback(true)
                }
                
                dispatch({ type: 'PROCESS_TRANSACTION_ASSESSMENT_RECEIVE_RESULT', grade: grade, price: price });
                dispatch(actionCreators.addLog('ASSESSMENT', 'RECEIVE_RESULT', {grade, price, grades}))
            }
        }

        dispatch({ type: 'PROCESS_MESSAGE_CLEAR' })
        dispatch(actionCreators.updateTransaction(state.process.current.transaction.uid, transactionData, receiveAssessmentCallback))

    },

    /** Инициализирует запроѝ на подтверждение ѝтапа оѝмотра. */
    confirmAssessment: (callback?: (successed: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        let transactionData: ITransactionValue[] = []
        if (appState && appState.process.current && appState.process.current.transaction.assessment) {
            if (appState.process.current.transaction.assessment) {
                transactionData.push({ key: TransactionKeys.ASSESSMENT, value: JSON.stringify(appState.process.current.transaction.assessment) })
            }
            if (appState.process.current.transaction.assessment.grade) {

                transactionData.push({ key: TransactionKeys.ASSESSMENT_GRADE, value: appState.process.current.transaction.assessment.grade })
            }

            if (appState.process.current.transaction.assessment.price) {
                transactionData.push({ key: TransactionKeys.ASSESSMENT_PRICE, value: appState.process.current.transaction.assessment.price })
            }

            if (appState.process.current.transaction.currency) {
                transactionData.push({ key: TransactionKeys.ASSESSMENT_PRICE_CURRENCY, value: appState.process.current.transaction.currency })
            }

            let grades: { [key: string]: string } = {}
            getState().process.current?.transaction.assessment.resultGrades?.forEach(rg => grades[rg.gradeCategoryCode] = rg.code)

            transactionData.push({ key: TransactionKeys.ASSESSMENT_GRADES, value: JSON.stringify(grades) })

            const setControlTransactionIdCallback = (successed: boolean) => {
                if (successed) {
                    if (callback) {
                        callback(true)
                    }
                    const sender = appState.process.current?.workflow.assessment?.ui?.stageTitle ?? strings.PROCESS.ASSESSMENT.TITLE;
                    dispatch(actionCreators.addEvent(sender, strings.EVENTS.STATUS.SET_CONTROL_TRANSACTION));
                }
            }

            dispatch(actionCreators.updateTransaction(appState.process.current.transaction.uid, transactionData, setControlTransactionIdCallback))

        }
    },

    /** Инициализирует запроѝ на подтверждение ѝтапа контролѝ. */
    confirmControl: (callback?: (successed: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        let transactionData: ITransactionValue[] = []
        if (appState && appState.process.current && appState.process.current.transaction.control) {
            if (appState.process.current.transaction.control) {
                transactionData.push({ key: TransactionKeys.CONTROL, value: JSON.stringify(appState.process.current.transaction.control) })
            }

            dispatch(actionCreators.updateTransaction(appState.process.current.transaction.uid, transactionData, callback))
        }
    },

    /** Selects offer provider on post-offer step */
    providerOfferSelect: (providerCode?: string, callback?: (successed: boolean) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => async (dispatch, getState) => {
        const state = getState();
        if (!state.process.current) return null;

        const offerProvider = state.process.current?.offerProviders?.find(i => i.code === providerCode);
        const offerProviderResponse = offerProvider?.result?.data as IOfferProviderResponse;
        const providerConfig = state.process.current.workflow.commonOffer?.providers?.find((i) => i.code === providerCode);

        const offer = findSelectedOffer( state, providerCode);
        const selectedProviderPrice = offer?.price
        const selectedProviderCurrency = offer?.currency_code
        const selectedProviderSku = offer?.sku;
        const price = getPriceWithCommission(selectedProviderPrice, providerConfig?.commissionValue, providerConfig?.commissionType)

        dispatch({
            type: 'PROCESS_TRANSACTION_PROVIDER_OFFER_SELECT',
            providerCode: providerCode,
            price: price,
            priceWithoutCommission: selectedProviderPrice,
            currency: selectedProviderCurrency,
            organizationId: offerProviderResponse?.organization_id,
            sku: selectedProviderSku
        });

        dispatch(actionCreators.addLog("POST_OFFER", "providerOfferSelect", {
            providerCode: providerCode,
            price: price,
            priceWithoutCommission: selectedProviderPrice, 
            commissionValue: providerConfig?.commissionValue, 
            commissionType: providerConfig?.commissionType}))
    },

    /** Уѝтанавливает цену, провайдера и индекѝ предложениѝ ѝтапа оценки уѝтройѝтва на ѝтапе конечного коммерчеѝкого предложениѝ(которые были раннее выбраны на ѝтапе предложениѝ). */
    receivePostOfferPrice: (callback?: (successed: boolean) => void): AppThunkAction<any> => async (dispatch, getState) => {
        const state = getState();

        if (!state.process.current) return null;
        
        const transaction = state.process.current?.transaction
        const price = transaction.assessment.price
        const priceWithoutCommission = transaction.priceWithoutCommission
        const grade = transaction.assessment.grade

        if (price !== 0 && !price) {
            if (callback) {
                callback(false)
            }
            return null;
        }

        let transactionData: ITransactionValue[] = []

        if (transaction.assessment) {
            transactionData.push({ key: TransactionKeys.ASSESSMENT, value: JSON.stringify(transaction.assessment) })
        }

        transactionData.push({ key: TransactionKeys.ASSESSMENT_PRICE, value: price })
        if (priceWithoutCommission) {
            transactionData.push({ key: TransactionKeys.PRICE_WITHOUT_COMMISSION, value: priceWithoutCommission })
        }

        if (transaction.currency) {
            transactionData.push({ key: TransactionKeys.ASSESSMENT_PRICE_CURRENCY, value: transaction.currency })
        }

        if (transaction.selectedProvider) {
            transactionData.push({ key: TransactionKeys.OFFER_PROVIDER, value: transaction.selectedProvider })
            const provider = state.process.current.offerProviders.find( (p) => p.code === transaction.selectedProvider)
            if( provider && provider.result?.data?.offers )
            {
                provider.result?.data?.offers.forEach( (off) => {
                    if(state.process.current?.workflow.useGradesCategories) {
                        off.customFields?.forEach((f) => {
                            transactionData.push({ key: "CUSTOM_"+ f.key, value: f.value })
                        })
                    } else if(off.grade === grade) {
                        off.customFields?.forEach((f) => {
                            transactionData.push({ key: "CUSTOM_"+ f.key, value: f.value })
                        })
                    }
                })
            }
        }

        if (transaction.selectedProviderOrganizationId) {
            transactionData.push({ key: TransactionKeys.OFFER_PROVIDER_ORGANIZATION_ID, value: transaction.selectedProviderOrganizationId })
        }

        if (transaction.selectedPromo && transaction.isValidPromotion) {
            if (!transaction.isValidPromotion) {
                dispatch(actionCreators.addLog("POST_OFFER", "receivePostOfferPrice", { error: { message: strings.PROCESS.DISCOUNTS.PROMOTION_INVALID}, promotion: transaction.selectedPromo } ))
                dispatch(actionCreators.receiveProcessMessage({ text: strings.PROCESS.DISCOUNTS.PROMOTION_INVALID, type: MessageBarType.error }))
                return;
            }
            const condition = transaction.selectedPromo.state.conditions?.find(c => c.grade === grade || c.grade === "*")
            if (condition) {
                transaction.selectedPromo.state.conditions = [condition]
                transactionData.push({ key: TransactionKeys.PROMOTION, value: JSON.stringify(transaction.selectedPromo.state) })
                const priceWithPromo = condition.priceType === PromotionPriceTypes.FixPrice ? condition.price : (condition.priceType === PromotionPriceTypes.Benefit ? price + condition.price : undefined)
                if (priceWithPromo) {
                    transactionData.push({ key: TransactionKeys.ASSESSMENT_PRICE_WITH_PROMO, value: JSON.stringify(priceWithPromo) })
                }
            }
        }

        const receiveFinalOfferPriceCallback = (successed: boolean) => {
            if (callback) {
                if (successed) {
                    dispatch(actionCreators.addEvent(strings.EVENTS.ACTION.POST_OFFER, `${price} ${state.process.current?.transaction.currency}${state.process.current?.transaction.selectedProvider ? (" (" + state.process.current?.transaction.selectedProvider + ")") : ''}`))
                }
                callback(successed)
            }
        }

        dispatch({ type: 'PROCESS_MESSAGE_CLEAR' })
        dispatch(actionCreators.updateTransaction(state.process.current.transaction.uid, transactionData, receiveFinalOfferPriceCallback))

    },

    /** Adds a transaction event to the current process. */
    addEvent: (action: string, status: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC addEvent('" + action + "', '" + status + "')", consoleStyles.logic);
        const appState = getState();
        if (appState.process.current?.transaction) {
            await _api.v1.transaction.addEvent(appState.process.current.transaction.uid, action, status);
        }
    },

    /** Adds a transaction event to the current process. */
    addEvents: (items: Array<{ action: string, status: string }>): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC addEvents(items)", consoleStyles.logic);
        console.info({ items });
        const appState = getState();
        if (appState.process.current?.transaction &&
            appState.process.current?.transaction.id > 0) {
            await _api.v1.transaction.addEvents(appState.process.current.transaction.uid, items)
        }
    },

    /** Отѝлеживает начало запуѝка инѝпекции. */
    trackInspectionModuleLaunch: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        const modules = selector.process.getCurrentStageIndexInspectionModules(appState.process.current)
        const module = modules?.find(m => m.index === index);
        console.debug("%c" + `LOGIC trackInspectionModuleLaunch(${index}, ${module?.type})`, consoleStyles.logic);
        if (module) {
            // TODO: Отправить данные на ѝервер, а поѝле изменить ѝоѝтоѝние
            dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_TRACK_LAUNCH', index: index });
        }
    },

    /** Brings inspection module result into current transaction */
    receiveInspectionModuleState: (index: number, result: InspectionTypes): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const state = getState();
        const moduleStates = selector.process.getCurrentStageIndexInspectionModules(state.process.current)
        const moduleState = moduleStates?.find(m => m.index === index);
        const moduleConfigs = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules
        const moduleConfig = moduleConfigs?.find(m => m.index === index);

        console.debug("%c" + `LOGIC receiveInspectionModuleState(index: ${index}, ${moduleState?.type}, result)`, consoleStyles.logic);
        console.info({ result });

        if (moduleState && moduleConfig) {
            // Производим обработку уѝтанавливаемых грейдов, так как их можно передать
            // двумѝ ѝпоѝобами и любой из ѝпоѝобов должен ѝвлѝтьѝѝ корректным
            if (result.grade || result.grades) {
                // Еѝли нет оѝновного грейда, но еѝть набор грейдов по категориѝм
                if (!result.grade && result.grades) {
                    if (moduleConfig.config.gradesCategory) {
                        // Уѝтанавливаем грейд модулѝ той категории, котораѝ наѝтроена оѝновной по модулю
                        result.grade = result.grades.find(g => g.category === moduleConfig.config.gradesCategory)?.grade;
                    } else {
                        // Уѝтанавливаем грейд, который не отноѝитѝѝ к категории
                        // (на ѝлучай, еѝли проѝтое грейдирование уѝтановило грейд через маѝѝив грейдов ѝ пуѝтой категорией)
                        result.grade = result.grades.find(g => g.category === "")?.grade;
                    }
                }
            }

            if ((state.process.current?.stage === ProcessStages.Assessment ||
                    state.process.current?.stage === ProcessStages.PostOffer) &&
                (result.status === InspectionStatuses.Done || result.status === InspectionStatuses.Fail)) {

                const sender = moduleConfig?.config.ui?.title ?? inspectionDescriber(moduleConfig?.type, state.process.current?.stage);

                // grade is not applicable for post offer step.
                const grade =
                    state.process.current?.stage === ProcessStages.Assessment
                    ? selector.workflow.getGrade(state, result.grade, index)
                    : undefined;

                setTimeout(() => {
                    dispatch(actionCreators.addEvent(`${sender}`, `${strings.EVENTS.STATUS.RESULT} ${(result.status === InspectionStatuses.Done ? grade?.name ?? strings.EVENTS.STATUS.PASSED : strings.EVENTS.STATUS.NOT_PASSED)}`));
                }, 1000);
            }

            let data: ITransactionValue[] = [];
            dispatch(actionCreators.addLog(getStageLogTitle(state.process.current?.stage), `PROCESS_TRANSACTION_INSPECTION_MODULE_${index}_RECEIVE_STATE`, { moduleState, result }));
            dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_STATE', index: index, result: result });

            if (state.process.current?.stage === ProcessStages.Assessment) {

                if (result.status !== InspectionStatuses.Run) {
                    data.push({ key: TransactionKeys.ENDING_INSPECTION, value: `${moduleConfig?.type} (${index})` });
                }

                const _assessment = getState().process.current?.transaction.assessment;

                if (_assessment) {
                    data.push({ key: TransactionKeys.ASSESSMENT, value: JSON.stringify(_assessment) });
                }

                if (_assessment?.grade) {
                    data.push({ key: TransactionKeys.ASSESSMENT_GRADE, value: _assessment.grade });
                }

                if (_assessment?.price) {
                    data.push({ key: TransactionKeys.ASSESSMENT_PRICE, value: _assessment.price });
                }

                if (moduleConfig.type === Inspections.Photographic && result.status === InspectionStatuses.New) {
                    data.push({ key: TransactionKeys.RESET_MODERATION, value: "" });
                }

                dispatch(actionCreators.updateCurrentTransaction(data));

                //  Refresh offers based on grading so far.
                if( _assessment?.grade != result.grade) {
                    const providers = state.process.current.offerProviders
                    const device = state.process.current.identification?.device
                    if( device && providers)
                    {
                        setTimeout(() => {
                            providers.forEach( p => {
                                dispatch(actionCreators.requestProviderOffer(device, p.code))
                            })
                        }, 500)
                    }
                }
            }
            else if (state.process.current?.stage === ProcessStages.PostOffer) {
                const postOffer = getState().process.current?.transaction.postOffer;

                if (postOffer) {
                    data.push({ key: TransactionKeys.POST_OFFER, value: JSON.stringify(postOffer) });
                    dispatch(actionCreators.updateCurrentTransaction(data));
                }
            }
        }
    },

    changeInspectionModuleStatus: (index: number, status: InspectionStatuses, warning?: IInspectionWarning, wasAborted?: boolean, canAutorun?: boolean): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        const modules = selector.process.getCurrentStageIndexInspectionModules(appState.process.current)
        const module = modules?.find(m => m.index === index);

        console.debug("%c" + `LOGIC changeInspectionModuleStatus(index: ${index}, ${module?.type}, ${status}, ${canAutorun ? true : false})`, consoleStyles.logic);
        console.info({ module: `${module?.type} (${index})`, status, warning });

        if (module?.state && (module?.state?.status !== status || module?.state?.status === status && canAutorun)) {
            if (module.state?.status === InspectionStatuses.SkippedByCondition) {
                const preSkipStatus = module.state.preSkipStatus
                const preSkipWarning = module.state.warning
                
                dispatch(actionCreators.addLog(getStageLogTitle(appState.process.current?.stage), `PROCESS_TRANSACTION_INSPECTION_MODULE_${index}_CHANGE_STATUS`, { module: `${module?.type} (${index})`, preSkipStatus, warning }));
                dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_CHANGE_STATUS', index: index, status: preSkipStatus ?? InspectionStatuses.New, wasAborted, canAutorun: canAutorun });
                if (preSkipWarning) {
                    dispatch(actionCreators.addLog(getStageLogTitle(appState.process.current?.stage), `PROCESS_TRANSACTION_INSPECTION_MODULE_${index}_RECEIVE_WARNING`, { module: `${module?.type} (${index})`, preSkipStatus, preSkipWarning }));
                    if (preSkipStatus === InspectionStatuses.Error || preSkipStatus === InspectionStatuses.Run) {
                        dispatch(actionCreators.addEvent(`index: ${index}, ${inspectionDescriber(module.type)}`, `${strings.EVENTS.STATUS.FAILURE}: ${preSkipWarning.message}`));
                    }
                }
            } else {
                dispatch(actionCreators.addLog(getStageLogTitle(appState.process.current?.stage), `PROCESS_TRANSACTION_INSPECTION_MODULE_${index}_CHANGE_STATUS`, { module: `${module?.type} (${index})`, status, warning }));
                dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_CHANGE_STATUS', index: index, status: status, wasAborted, canAutorun: canAutorun });
                if (warning) {
                    dispatch(actionCreators.addLog(getStageLogTitle(appState.process.current?.stage), `PROCESS_TRANSACTION_INSPECTION_MODULE_${index}_RECEIVE_WARNING`, { module: `${module?.type} (${index})`, status, warning }));
                    dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_WARNING', index: index, warning: warning });
                    if (status === InspectionStatuses.Error || status === InspectionStatuses.Run) {
                        dispatch(actionCreators.addEvent(`index: ${index}, ${inspectionDescriber(module.type)}`, `${strings.EVENTS.STATUS.FAILURE}: ${warning.message}`));
                    }
                }
            }

            let data: ITransactionValue[] = [];

            if (appState.process.current?.stage === ProcessStages.Assessment) {
                const _assessment = getState().process.current?.transaction.assessment;

                if (_assessment) {
                    data.push({ key: TransactionKeys.ASSESSMENT, value: JSON.stringify(_assessment) });
                }

                dispatch(actionCreators.updateCurrentTransaction(data));
            }
            else if (appState.process.current?.stage === ProcessStages.PostOffer) {
                const postOffer = getState().process.current?.transaction.postOffer;

                if (postOffer) {
                    data.push({ key: TransactionKeys.POST_OFFER, value: JSON.stringify(postOffer) });
                    dispatch(actionCreators.updateCurrentTransaction(data));
                }
            }
        }
    },

    /** Приноѝит в текущую транзакцию предупреждение длѝ инѝпекции. */
    receiveInspectionModuleWarning: (index: number, warning: IInspectionWarning | undefined): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        const modules = selector.process.getCurrentStageIndexInspectionModules(appState.process.current)
        const module = modules?.find(m => m.index === index);

        console.debug("%c" + `LOGIC receiveInspectionModuleWarning(index: ${index}, ${module}, warning)`, consoleStyles.logic);
        console.info({ module: `${module?.type} (${index})`, warning });

        if (module) {
            // TODO: Отправить данные на ѝервер, а поѝле изменить ѝоѝтоѝние
            dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_WARNING', index: index, warning: warning });
        }

        let data: ITransactionValue[] = [];

        if (appState.process.current?.stage === ProcessStages.Assessment) {
            const _assessment = getState().process.current?.transaction.assessment;

            if (_assessment) {
                data.push({ key: TransactionKeys.ASSESSMENT, value: JSON.stringify(_assessment) });
            }

            dispatch(actionCreators.updateCurrentTransaction(data));
        }
        else if (appState.process.current?.stage === ProcessStages.PostOffer) {
            const postOffer = getState().process.current?.transaction.postOffer;

            if (postOffer) {
                data.push({ key: TransactionKeys.POST_OFFER, value: JSON.stringify(postOffer) });
                dispatch(actionCreators.updateCurrentTransaction(data));
            }
        }
    },

    /** Приноѝит в текущую транзакцию предупреждение длѝ инѝпекции. */
    clearInspectionModuleWarning: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        const appState = getState();
        const modules = selector.process.getCurrentStageIndexInspectionModules(appState.process.current)
        const module = modules?.find(m => m.index === index);

        console.debug("%c" + `LOGIC clearInspectionModuleWarning(index: ${index}, ${module?.type})`, consoleStyles.logic);

        if (module) {
            // TODO: Отправить данные на ѝервер, а поѝле изменить ѝоѝтоѝние
            dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RECEIVE_WARNING', index: index, warning: undefined });
        }
    },

    /** Приноѝит в текущую транзакцию файлы (требуетѝѝ перенеѝти логику). */
    uploadInspectionModuleFiles: (index: number, files: Array<uploadFileType>, isAdditional?: boolean, comment?: string): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>>> => async (dispatch, getState) => {
        const appState = getState();
        const modules = selector.process.getCurrentStageIndexInspectionModules(appState.process.current)
        const module = modules?.find(m => m.index === index);
        const uploadProgress = 0

        console.debug("%c" + `LOGIC uploadTransactionModuleFiles(index: ${index})`, consoleStyles.logic);

        dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_UPLOAD_PROGRESS', index: index, uploadProgress })
        dispatch(actionCreators.clearInspectionModuleWarning(index))
        if (appState.process.current && module) {
            dispatch(actionCreators.addLog(getStageLogTitle(appState.process.current?.stage), `PROCESS_TRANSACTION_INSPECTION_MODULE_${index}_UPLOAD_FILES_CHANGE_STATUS`, { module: `${module?.type} (${index})`}));
            dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_CHANGE_STATUS', index: index, status: InspectionStatuses.Run })
            dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_RESET_FILES', index: index })
            let body = new FormData()
            let transactionFilesCount = (module.state as IPhotographic).files?.length
            files.forEach(async (file, index) => {
                const fileExtension = file.data.name.split('.')[file.data.name.split('.').length - 1]
                const fileName = (file.label ?? ((transactionFilesCount ?? index) + 1).toString()) + "." + fileExtension
                body.append(fileName, file.data)
                if (transactionFilesCount) transactionFilesCount += 1
            })
            const fileRequest: IFileRequest = {
                uid: appState.process.current.transaction.uid,
                isAdditional: isAdditional ?? false,
                message: comment ?? ""

            }
            const fileRequestBlob = new Blob([JSON.stringify(fileRequest)], { type: 'application/json' })
            body.append("fileRequest", fileRequestBlob)

            let data: IModerationMessage = { files: [] }
            let xhr = new XMLHttpRequest()
            xhr.upload.onprogress = function (event) {
                const uploadProgress = event.loaded / event.total
                dispatch({ type: 'PROCESS_TRANSACTION_INSPECTION_MODULE_UPLOAD_PROGRESS', index: index, uploadProgress })
            }
            xhr.responseType = 'json';
            xhr.onload = function (ev) {
                if (xhr.status != 200) {
                    dispatch(actionCreators.receiveInspectionModuleState(index, {
                        ...module.state,
                        status: InspectionStatuses.Error,
                        warning: {
                            message: `${strings.PROCESS.COMMON.ERROR} ${xhr.status}: ${localizedHttpErrors(xhr.status, xhr.statusText)}`,
                            code: -1
                        }
                    }));
                } else {
                    data = xhr.response as IModerationMessage
                    dispatch(actionCreators.moderationInspectionModuleHandler(index, data))
                }
            }
            xhr.open("POST", `v1/transaction/files`)
            xhr.send(body)
        }
    },

    /** Запуѝкает таймер проверки модерации. */
    moderationInspectionModuleHandler: (index: number, moderationMessage: IModerationMessage): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        const appState = getState();
        const modules = selector.process.getCurrentStageIndexInspectionModules(appState.process.current)
        const module = modules?.find(m => m.index === index);
        const moduleConfg = appState.process.current?.workflow.assessment?.modules.find((m) => m.index === index)

        console.debug("%c" + `LOGIC moderationModuleHandler(index: ${index})`, consoleStyles.logic);
        switch (moderationMessage.status) {
            case "queue":
                dispatch(actionCreators.clearInspectionModuleWarning(index))
                dispatch(actionCreators.receiveInspectionModuleState(index, {
                    ...module?.state ?? { status: InspectionStatuses.Run },
                    status: InspectionStatuses.Run,
                    files: moderationMessage.files?.map(f =>
                    ({
                        name: f.name,
                        link: f.link,
                        preview: f.link,
                        isUploaded: true,
                        label: ""
                    } as IUploadFile)),
                    moderationStatus: moderationMessage.status
                }));
                break;
            case "moderation":
                dispatch(actionCreators.clearInspectionModuleWarning(index))
                dispatch(actionCreators.receiveInspectionModuleState(index, {
                    ...module?.state ?? { status: InspectionStatuses.Run },
                    status: InspectionStatuses.Run,
                    files: moderationMessage.files?.map(f =>
                    ({
                        name: f.name,
                        link: f.link,
                        preview: f.link,
                        isUploaded: true,
                        label: ""
                    } as IUploadFile)),
                    moderationStatus: moderationMessage.status,
                    warning: undefined
                }));
                break;
            case "denied":
                dispatch(actionCreators.receiveInspectionModuleState(index, {
                    ...module?.state ?? { status: InspectionStatuses.Fail },
                    status: InspectionStatuses.Fail,
                    files: moderationMessage.files?.map(f =>
                    ({
                        name: f.name,
                        link: f.link,
                        preview: f.link,
                        isUploaded: true,
                        label: ""
                    } as IUploadFile)),
                    moderationStatus: moderationMessage.status,
                    warning: moderationMessage.message ? { message: moderationMessage.message } : undefined
                }));
                break;
            case "request":
                // dispatch(actionCreators.receiveInspectionModuleWarning(index, { message: moderationMessage.message ?? "", code: 0 }))
                dispatch(actionCreators.receiveInspectionModuleState(index, {
                    ...module?.state ?? { status: InspectionStatuses.Run },
                    status: InspectionStatuses.Run,
                    files: moderationMessage.files?.map(f =>
                    ({
                        name: f.name,
                        link: f.link,
                        preview: f.link,
                        isUploaded: true,
                        label: ""
                    } as IUploadFile)),
                    moderationStatus: moderationMessage.status,
                    warning: { message: moderationMessage.message ?? "", code: 0 }
                }));
                break;
            case "completed":
                let gradeCategory = undefined;
                if( appState.process.current?.workflow.gradesCategories && moduleConfg) {
                    gradeCategory = appState.process.current?.workflow.gradesCategories.find((g) => g.code === moduleConfg.config.gradesCategory )?.grades
                }
                if( !gradeCategory) {
                    gradeCategory = appState.process.current?.workflow.grades;
                }
                const filteredCategory = gradeCategory?.filter(v => v.code === moderationMessage.grade);
                const gradeDescription = filteredCategory ? filteredCategory[0]?.description : undefined;

                let operationDetails = getPhotoModerationOperationDetails(moderationMessage, gradeDescription);
                dispatch(actionCreators.addPiceaOnlineOperation(OperationTypes.PHOTO_MODERATION, operationDetails));
                dispatch(actionCreators.receiveInspectionModuleState(index, {
                    ...module?.state ?? { status: InspectionStatuses.Done },
                    status: InspectionStatuses.Done,
                    files: moderationMessage.files?.map(f =>
                    ({
                        name: f.name,
                        link: f.link,
                        preview: f.link,
                        isUploaded: true,
                        label: ""
                    } as IUploadFile)),
                    grade: moderationMessage.grade,
                    moderationStatus: moderationMessage.status
                }));
                break;
        }
    },

    startEraseModule: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        // reset flags
        stop_erase = false;
        is_start_erase = false;

        console.debug("%c" + `LOGIC startEraseModule(index: ${index})`, consoleStyles.logic);

        // Очищаем блок ѝтираниѝ и переводим его в ѝоѝтоѝние "Выполнѝетѝѝ"
        dispatch(actionCreators.clearInspectionModuleWarning(index));
        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Run));

        // Объѝвлѝем функцию, котораѝ выводит ошибку в блоке ѝтираниѝ и пытаетѝѝ отменить вѝе процеѝѝы ѝтираниѝ.
        const stop = (stopError?: any, callback?: () => void) => {
            // Длѝ теѝтированиѝ. Сейчаѝ без закрытиѝ ѝеѝѝии процеѝѝ не работает.
            console.debug("%c" + "LOGIC [eraser] stop()", consoleStyles.piceaCollback);
            console.info({ stopError, is_start_erase, current_picea_api_erase });
            // Еѝли объект api был ранее получен, то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы
            if (current_picea_api_erase) {
                // Получаем ID ѝеѝѝии
                const sessionId = current_picea_api_erase.sessionId();
                console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.piceaCollback);
                console.info({ sessionId });
                // Еѝли ѝеѝѝиѝ ѝушеѝтвует (была ранее открыта), то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы в ѝеѝѝии
                if (sessionId && sessionId != 0) {
                    // Проверѝем флаг, который выѝтавлѝетѝѝ в true, еѝли был callback onEraserStarted
                    if (is_start_erase) {
                        // Оѝтанавливаем процеѝѝ ѝтираниѝ
                        current_picea_api_erase.stopEraser((error: PiceaError, data: any) => {
                            dispatch(actionCreators.addLog("PICEA", "[eraser] api.stopEraser ()", { sessionId, error, data }));
                            console.debug("%c" + "Picea® Online Services: api.stopEraser ()", consoleStyles.piceaCollback);
                            console.info({ error, data });
                            // Уѝтанавливаем глобальный флаг, что процеѝѝ ѝтираниѝ не запущен
                            is_start_erase = false;
                            // Незавиѝимо от результата stopEraser, выводим ѝтоп-ошибку в блоке ѝтираниѝ, еѝли она имеетѝѝ
                            if (stopError) {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                                    code: stopError.details?.error ?? stopError.status
                                }));
                            }
                            // Обнулѝем объект api
                            current_picea_api_erase = null;
                            // Сообщаем о завершении оѝтановки процеѝѝа
                            if (callback) callback();
                        });
                    } else { // Стирание в рамках открытой ѝеѝѝии не была запущена
                        // Выводим ѝтоп-ошибку в блоке ѝтираниѝ, еѝли она имеетѝѝ
                        if (stopError) {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                                code: stopError.details?.error ?? stopError.status
                            }));
                        }
                        // Обнулѝем объект api
                        current_picea_api_erase = null;
                        // Сообщаем о завершении оѝтановки процеѝѝа
                        if (callback) callback();
                    }
                } else { // Сеѝѝиѝ не была открыта
                    // Выводим ѝтоп-ошибку в блоке ѝтираниѝ, еѝли она имеетѝѝ
                    if (stopError) {
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                            code: stopError.details?.error ?? stopError.status
                        }));
                    }
                    // Обнулѝем объект api
                    current_picea_api_erase = null;
                    // Сообщаем о завершении оѝтановки процеѝѝа
                    if (callback) callback();
                }
            } else { // Еѝли api объект небыл получен ранее и имеетѝѝ объект ошибки
                // Выводим ѝтоп-ошибку в блоке диагноѝтики, еѝли она имеетѝѝ
                if (stopError) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                        code: stopError.details?.error ?? stopError.status
                    }));
                }
                // Сообщаем о завершении оѝтановки процеѝѝа
                if (callback) callback();
            }
        }

        setTimeout(() => {
            const state = getState();
            // Данные текущего подключённого уѝтройѝтва
            const identification = state.process.current?.identification;
            const moduleConfig = (selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config as IEraseConfig);

            // Данные уѝтройѝтва ѝ которым начали транзакцию
            const identity = state.process.current?.transaction.identity;
            // Название модулѝ диагноѝтики
            // const sender = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config?.ui?.title ?? strings.INSPECTIONS.ERASE.TITLE;

            if (!identity) return null;
            if (!identification) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                }));
                return null;
            }

            // Еѝли метод подключениѝ уѝтройѝтва не USB, то предотвращаем выполнение диагноѝтики.
            if (identification.method !== IdentificationMethods.PiceaUsb) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: strings.INSPECTIONS.ERASE.ONLY_USB
                }));

                return null;
            }

            // Даннаѝ проверка предотвращает одновременный запуѝк диагноѝтики, проверок и ѝтираниѝ данных.
            let isPiceaRunning = false
            selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.filter(i => i.index !== index).forEach(i => {
                if ([Inspections.Software, Inspections.Diagnostics].includes(i.type) && i.state.status === InspectionStatuses.Run) {
                    isPiceaRunning = true;
                }
            })

            if (isPiceaRunning) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DEVICE_IS_BUSY_CUSTOM
                }));
                return null;
            }

            // Объѝвлѝем функцию, котораѝ вызываетѝѝ в тот момент, когда никаких преград длѝ запуѝка процеѝѝа ѝтираниѝ на уѝтройѝтве нет
            const start = () => {
                // WARNING: Даннаѝ проверка не гарантирует того, что уѝтройѝтво дейѝтвительно подключено и готово к запуѝку диагноѝтики
                // Необходима доработка метода getDeviceStatus ѝо ѝтороны Piceasoft
                // TODO PICEA: Заменить на флаг isOnline который можно получить из getDevices
                // if (!identification?.isConnected) {
                //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                //         information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                //         message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RETURN_TO_APP,
                //         code: 400
                //     }));
                //     return null;
                // }

                const eraseConfig = moduleConfig.config as IEraseDefaultConfig;

                // Получаем объект api
                window.ONLINE_API.getAPI(identification.stamp, window.ONLINE_API.SERVICE_ERASER, function (error: PiceaGetApiError, api: any) {
                    console.debug("%c" + "Picea® Online Services: getAPI ('" + identification.stamp + "', 'SERVICE_ERASER')", consoleStyles.piceaCollback);
                    console.info({ error, api });
                    dispatch(actionCreators.addLog("PICEA", "[eraser] ONLINE_API.getAPI ('" + identification.stamp + "', 'SERVICE_ERASER')", { error, api }));
                    // Еѝли была нажата кнопка отмены диагноѝтики, предотвращаем дальнейшие дейѝтвиѝ
                    if (stop_erase) return;
                    // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ диагноѝтики
                    if (error) {
                        if( -1 === error.status && -1 === error.details?.error && "Could not confirm service status." === error.details?.message)
                        {
                            stop(undefined, () => {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    information: strings.PICEA.ERRORS.GENERIC.UNEXPECTED_ERROR,
                                    message: "Could not confirm service status.",
                                    code: -1
                                }));
                            });
                            return;
                        }
                        if (error.status === -1 && error.details?.error === 1) {
                            stop(error);
                            return;
                        }
                        stop(error);
                    } else {
                        // Сохранѝем в глобальной переменной полученный объект api
                        current_picea_api_erase = api;

                        // Объѝвлѝем функцию, котораѝ запуѝкает процеѝѝ ѝтираниѝ на уѝтройѝтве
                        const run_eraser = () => {
                            // If the cancel erase button was pressed, we prevent further actions,
                            // api.startEraser was never actually called.
                            if (stop_erase) {
                                console.debug("run_eraser: erasure already cancelled, clear state");
                                // clear / update state accoring
                                current_picea_api_erase = null;
                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                                return;
                            }
                            // If the process of erasure has already been started, then we prevent further actions
                            if (is_start_erase) return;

                            api.onManualFactoryResetRequired((error: PiceaError, data: any) => {
                                console.debug("%c" + "Picea® Online Services: api.onManualFactoryResetRequired (error, data)", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                // Еѝли была нажата кнопка отмены, то предотвращаем дальнейшие дейѝтвиѝ
                                if (stop_erase) return;
                                // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                                if (data.device_id !== identification.stamp) return;
                                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ
                                if (error || data.status !== 0) {
                                    stop(error);
                                } else {
                                    dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                        message: 'User needs to disconnect and reconnect the device.',
                                        code: 13
                                    }));
                                }
                            });

                            // no need to listen onEraserStarted, erasure is marked to ongoing as soon as we get reply for start erasure cmd.
                            /*api.onEraserStarted((error: PiceaError, data: any) => {
                                console.debug("%c" + "Picea® Online Services: api.onEraserStarted (error, data)", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.ERASURE });
                                // Еѝли была нажата кнопка отмены, то предотвращаем дальнейшие дейѝтвиѝ
                                if (stop_erase) return;
                                // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                                if (data.device_id !== identification.stamp) return;
                                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ
                                if (error) {
                                    stop(error);
                                } else {
                                    is_start_erase = true;
                                    // Переводим модуль в ѝоѝтоѝние выполнениѝ
                                    dispatch(actionCreators.receiveInspectionModuleState(index, {
                                        status: InspectionStatuses.Run
                                    } as IErase));
                                }
                            });*/

                            api.onEraserProgress((error: PiceaError, data: any) => {
                                console.debug("%c" + "Picea® Online Services: api.onEraserProgress (error, data)", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                // Еѝли была нажата кнопка отмены, то предотвращаем дальнейшие дейѝтвиѝ
                                if (stop_erase) return;
                                // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                                if (data.device_id !== identification.stamp) return;
                                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ
                                if (error) {
                                    stop(error);
                                } else {
                                    dispatch(actionCreators.receiveInspectionModuleState(index, {
                                        status: InspectionStatuses.Run,
                                        progress: data.progress,
                                        erasing_state: data.erasing_state
                                    } as IErase));
                                }
                            });

                            api.onEraserCompleted((error: PiceaError, data: ErasureOperationCompletedData) => {
                                console.debug("%c" + "Picea® Online Services: api.onEraserCompleted (error, data)", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                                if (data.device_id !== identification.stamp) return;
                                if (error) {
                                    stop(error);
                                } else {
                                    dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.ERASURE });

                                    if (data.operation_status.status !== OperationStatus.OperationStatus_Succeeded) {
                                        
                                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                            message: eraserErrorDescriber(data.operation_status.error_code),
                                            code: data.operation_status.error_code
                                        }));
                                    }
                                    else {
                                        // Переводим модуль в завершённое ѝоѝтоѝние и ѝохранѝем результат
                                        dispatch(actionCreators.receiveInspectionModuleState(index, {
                                            status: InspectionStatuses.Done,
                                            operation_status: data.operation_status
                                        } as IErase));
                                    }
                                }
                            });

                            api.onManualFactoryResetRequired((error: PiceaError, data: any) => {
                                console.debug("%c" + "Picea® Online Services: api.onManualFactoryResetRequired (error, data)", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                if (stop_erase) return;
                                // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                                if (data.device_id !== identification.stamp) return;
                                if (error) {
                                    stop(error);
                                } else if (data.device_id) {
                                    // Переводим модуль в ѝоѝтоѝние ѝ требованием ручного ѝброѝа уѝтройѝтва
                                    dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                        code: 0,
                                        message: strings.PICEA.ERASER.MANUAL_FACTORY_RESET_REQUIRED
                                    } as IInspectionWarning));
                                } else {
                                    stop(error);
                                }
                            });

                            const config = {
                                options: {
                                    // allowManualFactoryReset: eraseConfig.allowManualFactoryReset
                                    allowManualFactoryReset: true
                                }
                            }

                            api.startEraser(config, (error: PiceaError, data: any) => {
                                console.debug("%c" + "Picea® Online Services: api.startEraser (error, data)", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                if (error) {
                                    stop(error);
                                }
                                else {
                                    // Mark it ongoing, for cancel to work.
                                    is_start_erase = true;

                                    if (stop_erase) {
                                        console.debug("cancel already requested, stopEraser");
                                        // stop_erase was already called, stop immediately
                                        current_picea_api_erase.stopEraser((error: PiceaError, data: any) => {
                                            dispatch(actionCreators.addLog("PICEA", "[eraser] api.stopEraser ()", { error, data }));
                                            console.debug("%c" + "Picea® Online Services: api.stopEraser ()", consoleStyles.piceaCollback);
                                            console.info({ error, data });
                                            
                                            // cancel called. Connector/online api will sent final result, react based on that.
                                            // however if error happened, clear state
                                            if (error) {
                                                is_start_erase = false;
                                                current_picea_api_erase = null;
                                                // Незавиѝимо от результата stopEraser, ѝообщаем о завершении оѝтановки процеѝѝа
                                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                                            }
                                        });
                                    }
                                    else {
                                        // erasure succesfully started
                                        dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.ERASURE });
                                        // Переводим модуль в ѝоѝтоѝние выполнениѝ
                                        dispatch(actionCreators.receiveInspectionModuleState(index, {
                                            status: InspectionStatuses.Run
                                        } as IErase));
                                    }
                                    
                                }
                            });
                        }

                        api.onEraserUserActionNeeded((error: PiceaError, data: any) => {
                            if (stop_erase) return;
                            console.debug("%c" + "Picea® Online Services: onEraserUserActionNeeded ()", consoleStyles.piceaCollback);
                            console.info({ error, data });
                            dispatch(actionCreators.addLog("PICEA", "[eraser] api.onEraserUserActionNeeded ()", { error, data }));
                            // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                            if (data.device_id !== identification.stamp) return;
                            // Еѝли была нажата кнопка отмены, то предотвращаем дальнейшие дейѝтвиѝ
                            if (stop_erase) return;
                            // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ
                            if (error) {
                                stop(error);
                            } else {
                                switch (data.eraser_user_action) {
                                    case 13:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.PICEA.ERASER.USER_ACTIONS.DISCONNECT_AND_RECONNECT,
                                            code: 13
                                        }));
                                        break;
                                    case 14:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.PICEA.ERASER.USER_ACTIONS.FINALIZE_FACTORY_RESET,
                                            code: 14
                                        }));
                                        break;
                                    case 19:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.PICEA.ERASER.USER_ACTIONS.CONFIRM_DIALOG,
                                            code: 19
                                        }));
                                        break;
                                    case 20:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.PICEA.ERASER.USER_ACTIONS.MANUAL_REBOOT,
                                            code: 20
                                        }));
                                        break;
                                    case 21:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.PICEA.ERASER.USER_ACTIONS.NEEDS_PASSCODE,
                                            code: 21
                                        }));
                                        break;
                                    case 33:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.PICEA.ERASER.USER_ACTIONS.COMPLETE_INSTALLATION,
                                            code: 33
                                        }));
                                        break;
                                    default:
                                        stop(undefined, () => {
                                            dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                message: data.data.eraser_user_action + strings.ACTIONS_MESSAGES.PROCESS.PICEA.NOT_DOCUMENTED,
                                                code: data.data.eraser_user_action
                                            }));
                                        });
                                        break;
                                }
                            }
                        });

                        api.isEraserSupported((error: PiceaError, data: any) => {
                            console.debug("%c" + "Picea® Online Services: isEraserSupported ()", consoleStyles.piceaCollback);
                            console.info({ error, data });
                            dispatch(actionCreators.addLog("PICEA", "[eraser] api.isEraserSupported ()", { error, data }));
                            // Еѝли получена ошибка, то оѝтанавливаем процедуру ѝтираниѝ
                            if (error) {
                                stop(error);
                            } else if (data.status !== 0) {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    message: eraserSupportReasonDescriber(data.reason),
                                    code: data.reason
                                }));
                            } else {
                                run_eraser();
                            }
                        });
                    }
                });
            };

            // Данный флаг предотвращает ѝробатывание лишних запроѝов get_device_status
            let stop_get_device_status = false;
            // Функциѝ длѝ проверки ѝтатуѝа уѝтройѝтва
            const get_device_status = async (): Promise<boolean> => {
                // Возвращает True, еѝли проверка ѝтатуѝа уѝтройѝтва не вернула никаких ѝтатуѝов
                return new Promise<boolean>(resolve => {
                    if (stop_get_device_status) return resolve(false);
                    window.ONLINE_API.getDevices(PiceaConnectionTypes.USB, async (error: any, type: any, data: any) => {
                        console.debug("%c" + "Picea® Online Services: ONLINE_API.getDevices (2)", consoleStyles.piceaCollback);
                        console.info({ error, type, data });
                        if (stop_get_device_status) return resolve(false);
                        dispatch(actionCreators.addLog("PICEA", "[eraser] ONLINE_API.getDevices (2)", { error, type, data }));
                        // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ диагноѝтики
                        if (error || data?.status !== 0) {
                            stop(error);
                            return resolve(false);
                        } else {
                            const _identification = getState().process.current?.identification;
                            if (!_identification) return resolve(false);
                            // Запрашиваем ѝтатуѝ уѝтройѝтва
                            window.ONLINE_API.getDeviceStatus(_identification.stamp, "eraser", (error: any, data: any) => {
                                console.debug("%c" + "Picea® Online Services: getDeviceStatus ('" + identification.stamp + "', 'eraser')", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                if (stop_get_device_status) return resolve(false);
                                dispatch(actionCreators.addLog("PICEA", "[eraser] ONLINE_API.getDeviceStatus ('" + identification.stamp + "', 'eraser')", { error, data }));

                                if (stop_erase) {
                                    console.debug("cancel already requested, end");
                                    stop_get_device_status = true;
                                    return resolve(false);
                                }
                                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                if (error || data?.status < 0) {
                                    stop_get_device_status = true;
                                    stop(error, () => {
                                        return resolve(false);
                                    });
                                } else {
                                    if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status !== InspectionStatuses.Run) {
                                        stop_get_device_status = true;
                                        return resolve(false);
                                    }
                                    // Еѝли ѝтутуѝ уѝтройѝтва вернул коды ошибок
                                    if (data.values) {
                                        const values = (data.values as number[]).sort((a, b) => a - b);
                                        const index30 = values.indexOf(30);
                                        if (index30 > -1) {
                                            values.splice(index30, 1);
                                        }
                                        const index19 = values.indexOf(19);
                                        if (index19 > -1) {
                                            values.splice(index19, 1);
                                        }
                                        if (values.length > 0) {
                                            if (!stop_get_device_status) {
                                                dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                    information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                                    message: deviceDescriber(values[0], true) + " " + deviceDescriber(values[0]),
                                                    code: values[0]
                                                }));
                                            }
                                        } else {
                                            if (!stop_get_device_status) {
                                                stop_get_device_status = true;
                                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                return resolve(true);
                                            }
                                        }
                                        return resolve(false);
                                    } else {
                                        if (!stop_get_device_status) {
                                            stop_get_device_status = true;
                                            // Коды ошибок отѝутѝтвуют. Очищаем предупреждениѝ в блоке диагноѝтики
                                            dispatch(actionCreators.clearInspectionModuleWarning(index));
                                            // Начинаем процедуру проверки
                                            return resolve(true);
                                        }
                                    }
                                }
                            });
                        }
                    });
                });
            }

            // Счётчик проверки ѝтатуѝа уѝтройѝтва
            let count = 0;
            // Функциѝ запуѝкает таймер опроѝа ѝтатуѝа уѝтройѝтва, и возвращает True, еѝли проверка
            // ѝтатуѝа уѝтройѝтва не вернула никаких блокирующих ѝтатуѝов, и False, еѝли пользователь
            // отменил процедуру диагноѝтики или не предпринѝл никоких дейѝтвий за 120 ѝекунд
            const device_monitoring = async (): Promise<boolean> => {
                return new Promise<boolean>(resolve => {
                    const timer = setInterval(async () => {
                        console.debug("%c" + "LOGIC device_monitoring()", consoleStyles.logic);
                        console.info({ stop_erase });
                        if (stop_erase || selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status !== InspectionStatuses.Run || ++count > 600) {

                            clearInterval(timer);
                            resolve(false);
                        } else {
                            const successed = await get_device_status();
                            if (successed) {
                                clearInterval(timer);
                                resolve(true);
                            }
                            else if (stop_erase) {
                                clearInterval(timer);
                                resolve(false);
                            }
                        }
                        count++;
                    }, 1000);
                });
            }

            // Запрашиваем ѝпиѝок вѝех подключённых уѝтройѝтв. Это уѝтранаѝт проблему, когда коннектор не видет подключённое уѝтройѝтво
            window.ONLINE_API.getDevices(PiceaConnectionTypes.USB, async (error: any, type: any, data: PiceaDevices) => {
                console.debug("%c" + "Picea® Online Services: ONLINE_API.getDevices (2)", consoleStyles.piceaCollback);
                console.info({ error, type, data });
                dispatch(actionCreators.addLog("PICEA", "[eraser] ONLINE_API.getDevices (2)", { error, type, data }));

                if( error && error.details?.error === GenericErrors.EDP_NO_CLIENTS) {
                    //  Socket connection has failed. Happens when PC connector crashes/is closed. Maybe some other causes too?
                    //  Manually restarting connector makes no difference, somehow the whole API connection would need to be rebuilt.
                    //  Doesn't look like a recoverable situation.
                    stop(undefined, () => {
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            information: strings.PICEA.ERRORS.GENERIC.EDP_CONNECTION_TO_SERVER_FAILED,  // "Connection to engine failed"
                            message: strings.PICEA.ERRORS.GENERIC.EDP_UNKNOWN_ERROR,    // "Unknown error. Contact with technical support."
                            code: 400
                        }));
                        dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Erase)}`, strings.PICEA.ERRORS.SOCKET.ERR_SOCKET_NOT_CONNECTED));
                    });
                    return;
                }

                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ диагноѝтики
                if (error || data?.status !== 0) {
                    stop(error);
                } else {
                    // Проверѝем подключено ли необходимое уѝтройѝтво
                    const find = data.devices.find(d => d.imei === identification.device.attributes[DeviceAttributes.IMEI]);
                    if (data.devices.length > 0 && !find) {
                        stop(undefined, () => {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                // message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RETURN_TO_APP,
                                message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WRONG_DEVICE,
                                code: 400
                            }));
                            dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Diagnostics)}`, strings.PICEA.WRONG_DEVICE_EVENT));
                        });
                        return;
                    }
                    // Запрашиваем ѝтатуѝ уѝтройѝтва и запуѝкаем процедуру диагноѝтики
                    if (await device_monitoring()) {
                        start();
                    } else {
                        stop(undefined, () => {
                            if (selector.process.getModuleState<IDiagnostics>(getState(), index).status === InspectionStatuses.Run) {
                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New));
                            }
                        });
                        // reset flags
                        is_start_erase = false;
                        stop_erase = false;
                    }
                }
            });

        }, 1000);
    },

    abortEraseModule: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        console.debug("%c" + `LOGIC abortEraseModule(index: ${index})`, consoleStyles.logic);
        console.info({ current_picea_api_erase, stop_erase, is_start_erase });
        dispatch(actionCreators.addLog("LOGIC", `[eraser] LOGIC abortEraseModule(index: ${index})`, { current_picea_api_erase, stop_erase, is_start_erase }));
        // Еѝли кнопка прерываниѝ уже была нажата, то предотвращаем повторную процедуру прерываниѝ
        if (stop_erase) return;
        // Уѝтанавливаем глобальный флаг, что процеѝѝ ѝтираниѝ прерван
        stop_erase = true;
        // Запиѝываем в журнал, что ѝтирание прервано оператором
        dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Erase)}`, strings.INSPECTIONS.ERASE.OPERATOR_ABORT_ERASE));
        // Информируем в блоке ѝтираниѝ, что идёт процедура прерываниѝ ѝтираниѝ
        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
            message: strings.PICEA.ERASER.CANCEL_WAIT,
            code: 500
        }));

        // Еѝли объект api был ранее получен, то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы
        if (current_picea_api_erase) {
            // Получаем ID ѝеѝѝии
            const sessionId = current_picea_api_erase.sessionId();
            console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.piceaCollback);
            console.info({ sessionId });
            // Еѝли ѝеѝѝиѝ ѝушеѝтвует (была ранее открыта), то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы в ѝеѝѝии
            if (sessionId && sessionId != 0) {
                // Проверѝем флаг, который выѝтавлѝетѝѝ в true, еѝли был callback onEraserStarted
                if (is_start_erase) {
                    console.log("abortEraseModule: is_start_erase, stop");
                    current_picea_api_erase.stopEraser((error: PiceaError, data: any) => {
                        dispatch(actionCreators.addLog("PICEA", "[eraser] api.stopEraser ()", { sessionId, error, data }));
                        console.debug("%c" + "Picea® Online Services: api.stopEraser ()", consoleStyles.piceaCollback);
                        console.info({ error, data });
                        
                        // cancel called. Connector/online api will sent final result, react based on that.
                        // however if error happened, clear state
                        if (error) {
                            is_start_erase = false;
                            current_picea_api_erase = null;
                            // Незавиѝимо от результата stopEraser, ѝообщаем о завершении оѝтановки процеѝѝа
                            dispatch(actionCreators.clearInspectionModuleWarning(index));
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                        }
                    });
                } else { 
                    // Erasure has not starting yet, request in ongoing state.
                    // 'stop_erase' flag is raised, will be cancelled after pending request is finished.
                    // Nothing to be done here.
                }
            } else {
                // Обнулѝем объект api
                current_picea_api_erase = null;
                // Сообщаем о завершении оѝтановки процеѝѝа
                dispatch(actionCreators.clearInspectionModuleWarning(index));
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
            }
        } else {
            // Сообщаем о завершении оѝтановки процеѝѝа
            dispatch(actionCreators.clearInspectionModuleWarning(index));
            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
        }
    },

    confirmManualFactoryReset: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        // Объѝвлѝем функцию, котораѝ выводит ошибку в блоке ѝтираниѝ и пытаетѝѝ отменить вѝе процеѝѝы ѝтираниѝ.
        const stop = (stopError?: any, callback?: () => void) => {
            // Длѝ теѝтированиѝ. Сейчаѝ без закрытиѝ ѝеѝѝии процеѝѝ не работает.
            console.debug("%c" + "LOGIC [eraser] stop()", consoleStyles.piceaCollback);
            console.info({ stopError, is_start_erase, current_picea_api_erase });
            // Еѝли объект api был ранее получен, то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы
            if (current_picea_api_erase) {
                // Получаем ID ѝеѝѝии
                const sessionId = current_picea_api_erase.sessionId();
                console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.piceaCollback);
                console.info({ sessionId });
                // Еѝли ѝеѝѝиѝ ѝушеѝтвует (была ранее открыта), то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы в ѝеѝѝии
                if (sessionId && sessionId != 0) {
                    // Проверѝем флаг, который выѝтавлѝетѝѝ в true, еѝли был callback onEraserStarted
                    if (is_start_erase) {
                        // Оѝтанавливаем процеѝѝ ѝтираниѝ
                        current_picea_api_erase.stopEraser((error: PiceaError, data: any) => {
                            dispatch(actionCreators.addLog("PICEA", "[eraser] api.stopEraser ()", { sessionId, error, data }));
                            console.debug("%c" + "Picea® Online Services: api.stopEraser ()", consoleStyles.piceaCollback);
                            console.info({ error, data });
                            // Уѝтанавливаем глобальный флаг, что процеѝѝ ѝтираниѝ не запущен
                            is_start_erase = false;
                            // Незавиѝимо от результата stopEraser, выводим ѝтоп-ошибку в блоке ѝтираниѝ, еѝли она имеетѝѝ
                            if (stopError) {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                                    code: stopError.details?.error ?? stopError.status
                                }));
                            }
                            // Обнулѝем объект api
                            current_picea_api_erase = null;
                            // Сообщаем о завершении оѝтановки процеѝѝа
                            if (callback) callback();
                        });
                    } else { // Стирание в рамках открытой ѝеѝѝии не была запущена
                        // Выводим ѝтоп-ошибку в блоке ѝтираниѝ, еѝли она имеетѝѝ
                        if (stopError) {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                                code: stopError.details?.error ?? stopError.status
                            }));
                        }
                        // Обнулѝем объект api
                        current_picea_api_erase = null;
                        // Сообщаем о завершении оѝтановки процеѝѝа
                        if (callback) callback();
                    }
                } else { // Сеѝѝиѝ не была открыта
                    // Выводим ѝтоп-ошибку в блоке ѝтираниѝ, еѝли она имеетѝѝ
                    if (stopError) {
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                            code: stopError.details?.error ?? stopError.status
                        }));
                    }
                    // Обнулѝем объект api
                    current_picea_api_erase = null;
                    // Сообщаем о завершении оѝтановки процеѝѝа
                    if (callback) callback();
                }
            } else { // Еѝли api объект небыл получен ранее и имеетѝѝ объект ошибки
                // Выводим ѝтоп-ошибку в блоке диагноѝтики, еѝли она имеетѝѝ
                if (stopError) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: stopError.details?.error ? eraserErrorDescriber(stopError.details?.error) : stopError.status ? eraserErrorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                        code: stopError.details?.error ?? stopError.status
                    }));
                }
                // Сообщаем о завершении оѝтановки процеѝѝа
                if (callback) callback();
            }
        }

        if (current_picea_api_erase) {
            current_picea_api_erase.manualFactoryResetDone((error: PiceaError, data: any) => {
                console.debug("%c" + "Picea® Online Services: api.manualFactoryResetDone (error, data)", consoleStyles.piceaCollback);
                console.info({ error, data });
                if (stop_erase) return;
                // Еѝли результат отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                // if (data.device_id !== identification.stamp) return;
                if (error) {
                    stop(error);
                } else if (data.status === 0) {
                    // По логике API должно инициировать callback уѝпешного завершениѝ
                } else {
                    stop(error);
                }
            });
        }
    },

    /** Function that outputs error in Diag module and try to cancel all diag processes */
    stopPiceaOneDiagnostics: (index: number, stopError?: any, callback?: () => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
    
        console.debug("%c" + `LOGIC stopPiceaOneDiagnostics(index: ${index})`, consoleStyles.logic);
        console.info({ current_picea_api_diagnostics, stop_diagnostics, is_start_diagnostics });
        dispatch(actionCreators.addLog("LOGIC", `[diagnostics] LOGIC stopPiceaOneDiagnostics(index: ${index})`, { current_picea_api_diagnostics, stop_diagnostics, is_start_diagnostics }));

        //TODO: check session management
        console.debug("%c" + "LOGIC [diagnostic] stop()", consoleStyles.logic);
        console.info({ stopError, is_start_diagnostics, current_picea_api_diagnostics });

        is_about_to_start_diagnostics = false;
        // Еѝли объект api был ранее получен, то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы
        if (current_picea_api_diagnostics) {
            // Получаем ID ѝеѝѝии
            const sessionId = current_picea_api_diagnostics.sessionId();
            console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.logic);
            console.info({ sessionId });
            // Еѝли ѝеѝѝиѝ ѝушеѝтвует (была ранее открыта), то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы в ѝеѝѝии
            if (sessionId && sessionId != 0) {
                // Проверѝем флаг, который выѝтавлѝетѝѝ в true, еѝли был callback onDiagnosticsStarted
                if (is_start_diagnostics) {
                    // Оѝтанавливаем процеѝѝ диагноѝтики
                    current_picea_api_diagnostics.stopDiagnostics((error: PiceaError, data: any) => {
                        dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.stopDiagnostics ()", { sessionId, error, data }));
                        console.debug("%c" + "Picea® Online Services: api.stopDiagnostics ()", consoleStyles.piceaCollback);
                        console.info({ error, data });
                        // Уѝтанавливаем глобальный флаг, что процеѝѝ диагноѝтики не запущен
                        is_start_diagnostics = false;
                        piceaOneExecutionContext = undefined
                        // Незавиѝимо от результата stopDiagnostics, выводим ѝтоп-ошибку в блоке диагноѝтики, еѝли она имеетѝѝ
                        if (stopError) {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                                code: stopError.details?.error ?? stopError.status
                            }));
                        }
                        // Обнулѝем объект api
                        current_picea_api_diagnostics = null;
                        // Сообщаем о завершении оѝтановки процеѝѝа
                        if (callback) callback();
                    });
                } else { // Диагноѝтика в рамках открытой ѝеѝѝии не была запущена
                    // Выводим ѝтоп-ошибку в блоке диагноѝтики, еѝли она имеетѝѝ
                    if (stopError) {
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                            code: stopError.details?.error ?? stopError.status
                        }));
                    }
                    // Обнулѝем объект api
                    current_picea_api_diagnostics = null;
                    // Сообщаем о завершении оѝтановки процеѝѝа
                    if (callback) callback();
                }
            } else { // Сеѝѝиѝ не была открыта
                // Выводим ѝтоп-ошибку в блоке диагноѝтики, еѝли она имеетѝѝ
                if (stopError) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                        code: stopError.details?.error ?? stopError.status
                    }));
                }
                // Обнулѝем объект api
                current_picea_api_diagnostics = null;
                // Сообщаем о завершении оѝтановки процеѝѝа
                if (callback) callback();
            }
        } else { // Еѝли api объект небыл получен ранее и имеетѝѝ объект ошибки
            // Выводим ѝтоп-ошибку в блоке диагноѝтики, еѝли она имеетѝѝ
            if (stopError) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                    code: stopError.details?.error ?? stopError.status
                }));
            }
            // Сообщаем о завершении оѝтановки процеѝѝа
            if (callback) callback();
        }
        if (stop_diagnostics_cb) {
            stop_diagnostics_cb();
            stop_diagnostics_cb = undefined;
        }
    },
    
    /** Method starts diagnostic process when pressing Run or Re-Run button 
     * @param index diag module's index (can be several diag modules in the flow)
     * @param choosedTestIndex index of selected diag template to run (single template)
     * @param selectedTests array of selected diag templates to run (multiple templates)
     */
    startPiceaDiagnosticsModule: (index: number, choosedTestIndex?: number, selectedTests?: number[]): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>>> => (dispatch, getState) => {
        const appState = getState();
        stop_diagnostics = false;

        console.debug("%c" + `LOGIC startPiceaDiagnosticsModule(index: ${index})`, consoleStyles.logic);

        // We clear the diagnostic block and set it to "Executing".
        dispatch(actionCreators.clearInspectionModuleWarning(index));
        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Run));

        setTimeout(() => {

            const state = getState();

            /** Currently connected device */
            const identification = state.process.current?.identification;

            /** Device that was introduced to the flow in Identification step */
            const identity = state.process.current?.transaction.identity;

            /** The custom name of this diagnostic module */
            const sender = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config?.ui?.title ?? strings.INSPECTIONS.DIAGNOSTICS.TITLE;

            if (!identity) return null;
            
            if (!identification) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                }));
                return null;
            }

            // If the device connection method is neither OTF nor USB, then we prevent the execution of diagnostics.
            if (identification.method !== IdentificationMethods.PiceaOne &&
                identification.method !== IdentificationMethods.PiceaUsb) {
                return null;
            }

            // This check prevents simultaneous execution of diagnostics and checks.
            let isPiceaRunning = false
            selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.filter(i => i.index !== index).forEach(i => {
                if ([Inspections.Software, Inspections.Diagnostics].includes(i.type) && i.state.status === InspectionStatuses.Run) {
                    isPiceaRunning = true;
                }
            })
            // Block further execution, if verify is running.
            if (isPiceaRunning) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DEVICE_IS_BUSY_CUSTOM
                }));
                return null;
            }

            // If the device is connected via OTF and the device_id does not match the device_id with which the transaction started,
            // then we display a message in the diagnostic block indicating that the initial device needs to be connected and halt further execution.
            if (identity.method === IdentificationMethods.PiceaOne) {
                if (identification.stamp !== identity.stamp) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                    }));
                    return null;
                }
            }

            // If the device is connected via USB and the IMEI does not match the IMEI with which the transaction started,
            // then we display a message in the diagnostic block indicating that the initial device needs to be connected and halt further execution.
            if (identity.method === IdentificationMethods.PiceaUsb) {
                const id = identification.device.attributes.imei;
                const same = id ? id === identity.device.attributes.imei : identification.stamp === identity.stamp;
                if (!same) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                    }));
                    return null;
                }
            }

            /** Function which is called when everything is ready to start diag in device */
            const start = () => {

                console.debug("%c" + "Diagnostics: RUN start ()", consoleStyles.piceaCollback);
                dispatch(actionCreators.addLog("PICEA", "[diagnostics] RUN start ()", {}));

                // WARNING: This check does not guarantee that the device is actually connected and ready to initiate diagnostics.
                // The getDeviceStatus method from the Piceasoft side needs improvement.
                // TODO PICEA: Replace with the isOnline flag which can be obtained from getDevices.

                // get fresh state from redux. By default true, if some other issue raises it will be handled elsewhere.
                const isAppActive = getState().process.current?.identification?.isConnected ?? true;

                if (!isAppActive) {
                    // app was in background, show info for user
                    dispatch(actionCreators.receiveInspectionModuleWarning( index, {
                        message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.RETURN_TO_APP_TO_CONTINUE_OR_RECONNECT_ALERT,
                        code: 400
                    }));

                    // listening about when app is ready again and starting again
                    const handleApplicationStateChanged = (args: PiceaApplicationStateEventArgs) => {
                        if (identification.stamp === args.data.device_id && args.data.state === PiceaApplicationStates.Activated) {
                            console.log("App is active, trying again");
                            // remove listener
                            stop_diagnostics_cb = undefined;
                            removeEvent();
                            // call starts with delay. Delay is needed to make sure redux store is updated about 'isConnected' field.
                            setTimeout(start, 900);
                        }
                    };

                    // add listener, start dgs when app state is correct
                    const removeEvent = window.PICEA.onApplicationStateChanged(handleApplicationStateChanged);

                    // remove listener if gets aborted
                    stop_diagnostics_cb = () => {
                        removeEvent();
                    }

                    return null;
                }

                const [configError, createConfigResult] = prepareDiagnosticsTestConfig(state, index, choosedTestIndex, selectedTests)
                
                if (configError || !createConfigResult) {
                    dispatch({
                        type: 'PROCESS_MESSAGE',
                        message: {
                            text: strings.PROCESS.TRANSACTION.ALERT_ERROR_CONFIG,
                            type: MessageBarType.error
                        }
                    })
                    return;
                }
                
                const { testConfig, resultTestIndex } = {...createConfigResult}
                
                /** config object for Online API getAPI() method */
                const config = {
                    device_id: identification.stamp,
                    session_id: appState.process.current?.session_id,
                    service_id: window.ONLINE_API.SERVICE_DIAGNOSTICS
                };

                // getting api object
                // TODO: check PiceaGetApiError typing
                window.ONLINE_API.getAPI(config, function (error: PiceaGetApiError, api: any) {
                    console.debug("%c" + "Picea® Online Services: getAPI ('" + identification.stamp + "', 'SERVICE_DIAGNOSTICS')", consoleStyles.piceaCollback);
                    console.info({ error, api });
                    dispatch(actionCreators.addLog("PICEA", "[diagnostics] ONLINE_API.getAPI ('" + identification.stamp + "', 'SERVICE_DIAGNOSTICS')", { error, api }));
                    // if abort (cancel) diag button was pressed, stop further execution
                    if (stop_diagnostics) return;

                    // if error, stop the process
                    if (error) {
                        if (identification.method === IdentificationMethods.PiceaOne && error.status === -1 &&
                            //TODO: check error codes and formats, messages for correctness
                            (error.details?.error === -2146435060 || error.details?.error === -2146435023)) {

                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                        information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                        message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RETURN_TO_APP,
                                        code: 400                                         
                                    }))
                                })
                            )
                            return;
                        }
                        else if( -1 === error.status && -1 === error.details?.error && "Could not confirm service status." === error.details?.message)
                        {
                            //TODO: check error codes and formats, messages for correctness
                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                        information: strings.PICEA.ERRORS.GENERIC.UNEXPECTED_ERROR,
                                        message: "Could not confirm service status.",
                                        code: -1
                                    }))
                                })
                            )

                            return;
                        }
                        else {
                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                        }
                    } 
                    else {
                        /** Online API object saved to global variable */
                        current_picea_api_diagnostics = api;

                        /** Function starts Diag process in device */
                        const run_diagnostics = () => {
                            // Block further execution if Abort (Cancel) button was pressed
                            if (stop_diagnostics) return;
                            // If diagnostics have already been started, then we prevent further actions
                            if (is_start_diagnostics || is_about_to_start_diagnostics) return;

                            // mark start command active/ongoing to prevent startup multiple times.
                            is_about_to_start_diagnostics = true;

                            // Subscribe to listen DGS start ntf
                            api.onDiagnosticsStarted((error: PiceaError, data: any) => {
                                console.debug("%c" + "Picea® Online Services: onDiagnosticsStarted ()", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.DIAGNOSTICS });
                                dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.onDiagnosticsStarted ()", { error, data }));
                                
                                // Block further execution if event belongs to another device
                                if (data.device_id !== identification.stamp) return;

                                // start command finished
                                is_about_to_start_diagnostics = false;

                                // If the button to cancel diagnostics was pressed, then we prevent further actions
                                if (stop_diagnostics) return;
                                // If diagnostics have already been started, then we prevent further actions
                                if (is_start_diagnostics) return;
                                // Stop diag process if error
                                if (error) {
                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                                } else {
                                    // Set the flag that the diagnostic process is running
                                    is_start_diagnostics = true;
                                    piceaOneExecutionContext = {
                                        index: index,
                                        device_id: data.device_id,
                                        inspection: Inspections.Diagnostics,
                                        operationUid: data.operation_status.uid
                                    }
                                }
                            });

                            // Subscription to onDiagnosticsCompleted event - finish of diag execution in device
                            api.onDiagnosticsCompleted(async function (error: PiceaError, data: any) {
                                console.debug("%c" + "Picea® Online Services: onDiagnosticsCompleted ()", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.onDiagnosticsCompleted ()", { error, data }));
                                // Block further execution if diag is not starting
                                if (!is_start_diagnostics) return;
                                // is_start_diagnostics = false;
                                // Block further execution if event belongs to another device
                                if (data.device_id !== identification.stamp) return;
                                // Block further execution if Abort (Cancel) button was pressed
                                if (stop_diagnostics) return;
                                // Stop diag process if error
                                if (error) {
                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                                } else {
                                    // Show message about fetching diag results
                                    dispatch(actionCreators.receiveInspectionModuleWarning(index, { 
                                        message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_GET_RESULT 
                                    }));                                    
                                    
                                    const [error, result] = processPiceaOneDiagnosticsResult(state, index, testConfig, 
                                        resultTestIndex, data?.operation_status?.diagnostics_details.test_cases)
                                    
                                    if (!error && result)
                                    {
                                        // Adding executed test cases to transaction user log
                                        // TODO: check how it works, in particular localization
                                        if (result.tests?.length)
                                        {
                                            dispatch(actionCreators.addEvents(
                                                result.tests[result.tests?.length - 1].cases.filter(i => i.result_msg.length > 0).map(i => {
                                                    return {
                                                        action: sender,
                                                        status: i.result_msg
                                                    }
                                                })
                                            ));                                            
                                        }
                                        
                                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                                dispatch(actionCreators.receiveInspectionModuleState(index, result))
                                            })
                                        )
                                        
                                    } else {  //no diag results available or fetched
                                        
                                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_RESULT_GET_FAILURE
                                                }))
                                            })
                                        )                                        
                                    }
                                }
                            });

                            const get_supported_test_cases = async (): Promise<Array<IDiagnosticsPiceaCaseConfig>> => {
                                return new Promise((resolve, reject) => {

                                // request the supported diagnostic tests from the device.
                                api.getSupportedTestCases((error: PiceaError, data: any) => {
                                    console.debug("%c" + "Picea® Online Services: getSupportedTestCases ()", consoleStyles.piceaCollback);
                                    console.info({ error, data });
                                    dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.getSupportedTestCases ()", { error, data }));

                                    // If an error is received, we stop the diagnostic process.
                                    if (error) {
                                        // Only for Apple iOS devices.
                                        if (identification.method === IdentificationMethods.PiceaUsb && identity.device.attributes[DeviceAttributes.OS] === "ios") {
                                            // if api supports and we listen onDiagnosticsDeviceReady, then end here. logic handled in onDiagnosticsDeviceReady.
                                            if (api.onDiagnosticsDeviceReady) {
                                                // If an EDP_DEVICE_NOT_READY error is received, then we do not interrupt the diagnostics, but wait for the onDiagnosticsDeviceReady event
                                                if (error.details?.error !== DeviceErrors.EDP_DEVICE_NOT_READY) {
                                                    //stop(error);
                                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                                                }
                                                is_about_to_start_diagnostics = false;
                                                reject(undefined);
                                            } else {
                                                // try again
                                                setTimeout(() => {

                                                    if (stop_diagnostics || is_start_diagnostics) {
                                                        is_about_to_start_diagnostics = false;
                                                        reject(undefined);
                                                        return;
                                                    }

                                                    get_supported_test_cases()
                                                        .then(resolve)
                                                        .catch(reject);
                                                }, 2000);
                                            }
                                        } else {
                                            //stop(error);
                                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                                            is_about_to_start_diagnostics = false;
                                        }
                                    } else {
                                        // get supported cases as flat list
                                        const deviceSupportedCases: Array<DiagnosticCases> = data.cases.map((c: IDiagnosticsPiceaCaseConfig) => c.id );

                                        // drop off unsupported test cases, and empty sets
                                        const filterSets = (sets: IDiagnosticsPiceaSetConfig[], supportedIds: DiagnosticCases[]): IDiagnosticsPiceaSetConfig[] => {
                                            return sets
                                                .map(set => ({
                                                    ...set,
                                                    cases: set.cases.filter(c => supportedIds.includes(c.id))
                                                }))
                                                .filter(set => set.cases.length > 0);
                                        };

                                        const cases: Array<IDiagnosticsPiceaCaseConfig> = [];
                                        if (testConfig.sets) {
                                            const filteredSets = filterSets(testConfig.sets, deviceSupportedCases);
                                            filteredSets.map((configSet: IDiagnosticsPiceaSetConfig) => {
                                                if (configSet.cases.length > 0) {
                                                    configSet.cases.map((c: IDiagnosticsPiceaCaseConfig | number) => {
                                                        // Support for the outdated configuration version!
                                                        // Previously, there was a simple array with test-case identifiers.
                                                        if (typeof c === "number") {
                                                            if (c == 145) {
                                                                cases.push({
                                                                    id: c,
                                                                    options: {
                                                                        level_drop: 2,
                                                                        failure_level: 16,
                                                                        min_battery_level: 16,
                                                                        show_battery_graph: true
                                                                    }
                                                                });
                                                            } else if (c == 144) {
                                                                cases.push({
                                                                    id: c,
                                                                    options: {
                                                                        failure_level: 16
                                                                    }
                                                                });
                                                            } else {
                                                                cases.push({
                                                                    id: c,
                                                                    options: {}
                                                                });
                                                            }
                                                        } else {
                                                            const options = c.options ?? {}
                                                            if (c.id === 145) {
                                                                options.show_battery_graph = true;
                                                                options.failure_level = c.options?.failure_level ?? 16
                                                                options.level_drop = c.options?.level_drop ?? 2
                                                                options.min_battery_level = c.options?.min_battery_level ?? 16
                                                            }
                                                            if (c.id === 144) {
                                                                options.failure_level = c.options?.failure_level ?? 16
                                                            }
                                                            cases.push({
                                                                id: c.id,
                                                                options: options
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }

                                        // test cases now ready
                                        resolve(cases);
                                    }
                                });
                            });
                            }

                            get_supported_test_cases()
                                .then(cases => {
                                    if (cases.length === 0) {
                                        console.debug("%c" + "Picea® Online Services: No supported Diagnostics checks", consoleStyles.piceaCollback);

                                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                                dispatch(actionCreators.receiveInspectionModuleState(index,  {
                                                    status: InspectionStatuses.Done,
                                                    grade: undefined,
                                                    grades: [],
                                                    uid: undefined,
                                                    checks: []
                                                }))
                                            })
                                        )
                                        is_about_to_start_diagnostics = false;
                                    }
                                    
                                    if (stop_diagnostics) {
                                        is_about_to_start_diagnostics = false;
                                        return;
                                    }

                                    // If diagnostics have already been started, then we prevent further actions
                                    if (is_start_diagnostics) {
                                        is_about_to_start_diagnostics = false;
                                        return;
                                    }

                                    const config = {
                                        sort_by_id: false,
                                        cases: cases,
                                        manual_device_identifiers: {
                                            imei: identification?.device.attributes[DeviceAttributes.IMEI] ?? "",
                                            imei2: identification?.device.attributes[DeviceAttributes.IMEI2] ?? "",
                                            SN: identification?.device.attributes[DeviceAttributes.SN] ?? ""
                                        }
                                    };
                                    
                                    // try start DGS
                                    api.startDiagnostics(config, (error: PiceaError, data: any) => {
                                        console.debug("%c" + "Picea® Online Services: startDiagnostics (config)", consoleStyles.piceaCollback);
                                        console.info({ config, error, data });
                                        dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.startDiagnostics ( config )", { config, error, data }));
                                        // Already cancelled?
                                        if (stop_diagnostics) {
                                            is_about_to_start_diagnostics = false;
                                            return;
                                        }
                                        // If an error is received, then we stop the diagnostic process
                                        if (error) {
                                            //stop(error);
                                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                                            is_about_to_start_diagnostics = false;
                                        } else {
                                            if (data.status !== 0) {
                                                is_about_to_start_diagnostics = false;
                                            }

                                            switch (data.status) {
                                                case 0:
                                                    dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                    if(selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status === InspectionStatuses.Error) {
                                                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Run));
                                                    }
                                                    // start command succeeded, device accepted.
                                                    // soon device is sending onDiagnosticsStarted - when DGS actually starts/fails
                                                    break;
                                                case -1:
                                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                            message: strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT
                                                        }))                                                        
                                                    }));
                                                    break;
                                                case -2:
                                                    // permissions needed. Show alert about it and try to retry automatically.
                                                    dispatch(actionCreators.receiveInspectionModuleWarning( index, {
                                                        message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.PICEA_APP_GRANT_PERMISSIONS_ALERT
                                                    }));

                                                    console.log("failed, permissions required, trying again shortly");
                                                    setTimeout(()=>{
                                                        console.log("trying again run_diagnostics");
                                                        run_diagnostics();
                                                    }, 4000);
                                                    break;
                                                default:
                                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                            message: strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT
                                                        }))
                                                    }));
                                                    break;
                                            }
                                        }
                                    });
                                })
                                .catch( ()=> {
                                    console.log("get_supported_test_cases failed");
                                });
                        }
                        // Listen user action needed events
                        api.onDiagnosticsUserActionNeeded((error: any, data: any) => {
                            console.debug("%c" + "Picea® Online Services: onDiagnosticsUserActionNeeded ()", consoleStyles.piceaCollback);
                            console.info({ error, data });
                            dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.onDiagnosticsUserActionNeeded ()", { error, data }));
                            // Еѝли результат диагноѝтики отноѝитѝѝ к другому уѝтройѝтву, то предотвращаем дальнейшие дейѝтвиѝ
                            if (data.device_id !== identification.stamp) return;
                            // Еѝли была нажата кнопка отмены диагноѝтики, то предотвращаем дальнейшие дейѝтвиѝ
                            if (stop_diagnostics) return;
                            // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ диагноѝтики
                            if (error) {
                                //stop(error);
                                dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                            } else {
                                switch (data.reason) {
                                    case 12:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.APP_TRUST,
                                            code: 12
                                        }));
                                        break;
                                    case 13:
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_STARTING,
                                            code: 13
                                        }));
                                        break;
                                    case 22:

                                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                            const msg = data.error_code ? errorDescriber(data.error_code) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT;
                                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.FAILED_TO_RUN_DIAGNOSTICS} ${msg}`
                                            }))
                                        }));
                                        break;
                                    case 23:
                                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.FAILED_TO_INSTALL_PICEA_APP} (${data.error_code}).`,
                                                code: 23
                                            }))
                                        }));
                                        break;
                                    default:
                                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                            dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                message: data.reason + strings.ACTIONS_MESSAGES.PROCESS.PICEA.NOT_DOCUMENTED,
                                                code: data.reason
                                            }))
                                        }));
                                        break;
                                }
                            }
                        });

                        // if Usb connection & Apple iOS
                        if (identification.method === IdentificationMethods.PiceaUsb && identity.device.attributes[DeviceAttributes.OS] === "ios" && api.onDiagnosticsDeviceReady) {
                            // Subscribing to an event that no longer requires any action from the user to start diagnostics
                            api.onDiagnosticsDeviceReady((error: any, data: any) => {
                                console.debug("%c" + "Picea® Online Services: onDiagnosticsDeviceReady ()", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.onDiagnosticsDeviceReady ()", { error, data }));
                                // If the button to cancel diagnostics was pressed, then we prevent further actions
                                if (stop_diagnostics) return;
                                // device check
                                if (data.device_id !== identification.stamp) return;
                                // If an error is received, then we stop the diagnostic process
                                if (error) {
                                    //stop(error);
                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                                } else {
                                    // Device is ready, try start DGS
                                    run_diagnostics();
                                }
                            });
                        }

                        // If the device is connected via USB, we display a message indicating that the application installation process is underway.
                        if (identification.method === IdentificationMethods.PiceaUsb) {
                            dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_APP_INSTALL
                            }));
                        }

                        if (identification.method === IdentificationMethods.PiceaOne) {
                            run_diagnostics();
                        } else {
                            if (stop_diagnostics) {
                                dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                    if (!error) {
                                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                            message: strings.INSPECTIONS.PICEA.ERROR_RESTART_SESSION_STATUS_1
                                        }))
                                    }
                                }));

                            } else {
                                run_diagnostics();
                            }
                        }
                    }
                });
            }

            // This flag prevents unnecessary execution of get_device_status requests.
            let stop_get_device_status = false;
            // Function for device status check
            const get_device_status = async (): Promise<boolean> => {
                // Возвращает True, еѝли проверка ѝтатуѝа уѝтройѝтва не вернула никаких ѝтатуѝов
                return new Promise<boolean>(resolve => {
                    if (stop_get_device_status) return;
                    window.ONLINE_API.getDevices(PiceaConnectionTypes.USB, async (error: any, type: any, data: any) => {
                        console.debug("%c" + "Picea® Online Services: ONLINE_API.getDevices (2)", consoleStyles.piceaCollback);
                        console.info({ error, type, data });
                        if (stop_get_device_status) return;
                        dispatch(actionCreators.addLog("PICEA", "[diagnostics] ONLINE_API.getDevices (2)", { error, type, data }));
                        // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ диагноѝтики
                        if (error || data?.status !== 0) {
                            //stop(error);
                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                        } else {
                            const _identification = getState().process.current?.identification;
                            if (!_identification) return;
                            // Запрашиваем ѝтатуѝ уѝтройѝтва
                            window.ONLINE_API.getDeviceStatus(_identification.stamp, "diagnostics", (error: any, data: any) => {
                                console.debug("%c" + "Picea® Online Services: getDeviceStatus ('" + identification.stamp + "', 'diagnostics')", consoleStyles.piceaCollback);
                                console.info({ error, data });
                                if (stop_get_device_status) return;
                                dispatch(actionCreators.addLog("PICEA", "[diagnostics] ONLINE_API.getDeviceStatus ('" + identification.stamp + "', 'diagnostics')", { error, data }));
                                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                if (error || data?.status < 0) {
                                    stop_get_device_status = true;

                                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, error, () => {
                                        //???
                                        console.log("resolve in stop diagnostics callback")
                                        resolve(false);
                                    }));
                                } else {
                                    if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status !== InspectionStatuses.Run) {
                                        stop_get_device_status = true;
                                        return resolve(false);
                                    }
                                    // Еѝли ѝтутуѝ уѝтройѝтва вернул коды ошибок
                                    if (data.values) {
                                        const values = (data.values as number[]).sort((a, b) => a - b);
                                        const index30 = values.indexOf(30);
                                        if (index30 > -1) {
                                            values.splice(index30, 1);
                                        }
                                        const index19 = values.indexOf(19);
                                        if (index19 > -1) {
                                            values.splice(index19, 1);
                                        }
                                        if (values.length > 0) {
                                            if (!stop_get_device_status) {
                                                dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                    information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                                    message: deviceDescriber(values[0], true) + " " + deviceDescriber(values[0]),
                                                    code: values[0]
                                                }));
                                            }
                                        } else {
                                            if (!stop_get_device_status) {
                                                stop_get_device_status = true;
                                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                return resolve(true);
                                            }
                                        }
                                    } else {
                                        if (!stop_get_device_status) {
                                            stop_get_device_status = true;
                                            // Коды ошибок отѝутѝтвуют. Очищаем предупреждениѝ в блоке диагноѝтики
                                            dispatch(actionCreators.clearInspectionModuleWarning(index));
                                            // Начинаем процедуру проверки
                                            return resolve(true);
                                        }
                                    }
                                }
                            });
                        }
                    });
                });
            }

            // Device status check counter
            let count = 0;
            // This function starts a timer to check the device status and returns True if the status check did not return any blocking states,
            // and False if the user canceled the diagnostic procedure or took no action for 120 seconds.
            const device_monitoring = async (): Promise<boolean> => {
                return new Promise<boolean>(resolve => {
                    const timer = setInterval(async () => {
                        console.debug("%c" + "LOGIC device_monitoring()", consoleStyles.logic);
                        console.info({ stop_verify });
                        if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status !== InspectionStatuses.Run || ++count > 600) {
                            clearInterval(timer);
                            resolve(false);
                        } else {
                            const successed = await get_device_status();
                            if (successed) {
                                clearInterval(timer);
                                resolve(true);
                            }
                        }
                        count++;
                    }, 1000);
                });
            }

            // If the device is connected via a USB cable.
            if (identification.method === IdentificationMethods.PiceaUsb) {
                // Запрашиваем ѝпиѝок вѝех подключённых уѝтройѝтв. Это уѝтранаѝт проблему, когда коннектор не видет подключённое уѝтройѝтво
                window.ONLINE_API.getDevices(PiceaConnectionTypes.USB, async (error: any, type: any, data: PiceaDevices) => {
                    console.debug("%c" + "Picea® Online Services: ONLINE_API.getDevices (2)", consoleStyles.piceaCollback);
                    console.info({ error, type, data });
                    dispatch(actionCreators.addLog("PICEA", "[diagnostics] ONLINE_API.getDevices (2)", { error, type, data }));

                    if (error && error.details?.error === GenericErrors.EDP_NO_CLIENTS) {
                        //  Socket connection has failed. Happens when PC connector crashes/is closed. Maybe some other causes too?
                        //  Manually restarting connector makes no difference, somehow the whole API connection would need to be rebuilt.
                        //  Doesn't look like a recoverable situation.

                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                information: strings.PICEA.ERRORS.GENERIC.EDP_CONNECTION_TO_SERVER_FAILED,  // "Connection to engine failed"
                                message: strings.PICEA.ERRORS.GENERIC.EDP_UNKNOWN_ERROR,    // "Unknown error. Contact with technical support."
                                code: 400
                            }));
                            dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Diagnostics)}`, strings.PICEA.ERRORS.SOCKET.ERR_SOCKET_NOT_CONNECTED));                           
                        }));
                        return;
                    }

                    // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ диагноѝтики
                    if (error || data?.status !== 0) {
                        //stop(error);
                        dispatch(actionCreators.stopPiceaOneDiagnostics(index, error))
                    } else {
                        // Проверѝем подключено ли необходимое уѝтройѝтво
                        const find = data.devices.find(d => d.imei === identification.device.attributes[DeviceAttributes.IMEI]);
                        if (data.devices.length > 0 && !find) {
                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WRONG_DEVICE,
                                    code: 400
                                }));
                                dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Diagnostics)}`, strings.PICEA.WRONG_DEVICE_EVENT));
                            }));
                            return;
                        }
                        // Запрашиваем ѝтатуѝ уѝтройѝтва и запуѝкаем процедуру диагноѝтики
                        if (await device_monitoring()) {
                            start();
                        } 
                        else {
                            dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                                if (selector.process.getModuleState<IDiagnostics>(getState(), index).status === InspectionStatuses.Run) {
                                    dispatch(actionCreators.clearInspectionModuleWarning(index));
                                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New));
                                }
                            }));

                        }
                    }
                });
            } else {
                // If the device is connected via OTF, we request the device status and initiate the diagnostic procedure.
                start();
            }
        }, 1000);
    },

    /** Aborts execution of Picea Diagnostics operation */
    abortPiceaDiagnosticsModule: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        // Длѝ теѝтированиѝ. Сейчаѝ без закрытиѝ ѝеѝѝии процеѝѝ не работает.
        console.debug("%c" + `LOGIC abortPiceaDiagnosticsModule(index: ${index})`, consoleStyles.logic);
        console.info({ current_picea_api_diagnostics, stop_diagnostics, is_start_diagnostics });
        dispatch(actionCreators.addLog("LOGIC", `[diagnostics] LOGIC abortPiceaDiagnosticsModule(index: ${index})`, { current_picea_api_diagnostics, stop_diagnostics, is_start_diagnostics }));
        // Еѝли кнопка прерываниѝ уже была нажата, то предотвращаем повторную процедуру прерываниѝ
        if (stop_diagnostics) return;
        // Уѝтанавливаем глобальный флаг, что процеѝѝ диагноѝтики прерван
        stop_diagnostics = true;
        if (stop_diagnostics_cb) {
            stop_diagnostics_cb();
            stop_diagnostics_cb = undefined;
        }
        // Запиѝываем в журнал, что диагноѝтика прервана оператором
        dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Diagnostics)}`, strings.INSPECTIONS.DIAGNOSTICS.OPERATOR_ABORT_DIAGNOSTICS));
        // Информируем в блоке диагноѝтики, что идёт процедура прерываниѝ диагноѝтики
        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
            message: strings.PICEA.DIAGNOSTICS.CANCEL_WAIT,
            code: 500
        }));

        // Еѝли объект api был ранее получен, то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы
        if (current_picea_api_diagnostics) {
            // Получаем ID ѝеѝѝии
            const sessionId = current_picea_api_diagnostics.sessionId();
            console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.piceaCollback);
            console.info({ sessionId });
            // Еѝли ѝеѝѝиѝ ѝушеѝтвует (была ранее открыта), то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы в ѝеѝѝии
            if (sessionId && sessionId != 0) {
                // Проверѝем флаг, который выѝтавлѝетѝѝ в true, еѝли был callback onDiagnosticsStarted
                if (is_start_diagnostics) {
                    current_picea_api_diagnostics.stopDiagnostics((error: PiceaError, data: any) => {
                        dispatch(actionCreators.addLog("PICEA", "[diagnostics] api.stopDiagnostics ()", { sessionId, error, data }));
                        console.debug("%c" + "Picea® Online Services: api.stopDiagnostics ()", consoleStyles.piceaCollback);
                        console.info({ error, data });
                        // Уѝтанавливаем глобальный флаг, что процеѝѝ диагноѝтики не запущен
                        is_start_diagnostics = false;
                        piceaOneExecutionContext = undefined
                        current_picea_api_diagnostics = null;
                        // Незавиѝимо от результата stopDiagnostics, ѝообщаем о завершении оѝтановки процеѝѝа
                        dispatch(actionCreators.clearInspectionModuleWarning(index));
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                    });
                } else { // Диагноѝтика в рамках открытой ѝеѝѝии не была запущена
                    current_picea_api_diagnostics = null;
                    // Сообщаем о завершении оѝтановки процеѝѝа
                    dispatch(actionCreators.clearInspectionModuleWarning(index));
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                }
            } else {
                // Обнулѝем объект api
                current_picea_api_diagnostics = null;
                // Сообщаем о завершении оѝтановки процеѝѝа
                dispatch(actionCreators.clearInspectionModuleWarning(index));
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
            }
        } else {
            // Сообщаем о завершении оѝтановки процеѝѝа
            dispatch(actionCreators.clearInspectionModuleWarning(index));
            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
        }
    },

    /** Method requests Diagnostics Operation results from session API 
     * @param index module's index
     * @param chosenTestIndex 
     * @param selectedTests
     * */
    requestDiagnosticsSessionReport: (index: number, chosenTestIndex?: number, selectedTests?: number[]) : AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction |AppThunkAction<KnownAction>>>> => async (dispatch, getState) => {        
        
        const state = getState();
        const operationUid = piceaOneExecutionContext?.operationUid ?? ""

        const handleOnError  = (message: string) => {
            /* Show warning message for 3 sec */
            
            dispatch(actionCreators.receiveInspectionModuleWarning(index, { message }));

            setTimeout(() => {
                dispatch(actionCreators.clearInspectionModuleWarning(index))
            }, 3000)
        }
        
        // Show message about fetching diag results
        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_GET_RESULT
        }));
                        
        console.debug("%c" + "LOGIC: getDiagnosticsResult(" + operationUid + ")", consoleStyles.logic);
        const response = await _api.v1.picea.getDiagnostics(operationUid);

        if (!response.successed) {
            handleOnError(strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_RESULT_GET_FAILURE)
            return;
        }

        try {

            if (response.data) {
            
                const [configError, diagnosticsConfig] = prepareDiagnosticsTestConfig(state, index, chosenTestIndex, selectedTests)
                if (configError || !diagnosticsConfig) {
                    handleOnError(strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_RESULT_GET_FAILURE)
                    return
                } 
    
                const [diagnosticsResultError, diagnosticsResult] = 
                    processPiceaOneDiagnosticsResult(state, index, diagnosticsConfig.testConfig, diagnosticsConfig.resultTestIndex, response.data)
    
                if (!diagnosticsResultError && diagnosticsResult) {

                    if (diagnosticsResult.tests?.length) {
                        const sender = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config?.ui?.title ?? strings.INSPECTIONS.DIAGNOSTICS.TITLE;
    
                        const diagnosticsEvenLogs 
                            = diagnosticsResult.tests[diagnosticsResult.tests?.length - 1].cases.filter(i => i.result_msg.length > 0)
                                .map(i => {
                                            return {
                                                action: sender,
                                                status: i.result_msg
                                            }
                        })
    
                        dispatch(actionCreators.addEvents(diagnosticsEvenLogs));
                    }
    
                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                            dispatch(actionCreators.receiveInspectionModuleState(index, diagnosticsResult))
                        })
                    )
    
                    return
                } 
            }
            
        } catch (error) {
            handleOnError(strings.ACTIONS_MESSAGES.PROCESS.PICEA.DIAGNOSTICS_RESULT_GET_FAILURE)
            return
        }
        
        handleOnError(strings.SHARED.SEARCH_NO_RESULTS)
    },
    
    /** Starts PiceaOne Verify execution  */
    startPiceaSoftwareModule: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>>> => (dispatch, getState) => {
        stop_verify = false;
        const appState = getState();

        console.debug("%c" + `LOGIC startPiceaSoftwareModule(index: ${index})`, consoleStyles.logic);

        // Clear Verify module and put it to Run state
        dispatch(actionCreators.clearInspectionModuleWarning(index));
        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Run));

        setTimeout(async () => {

            const state = getState();
            /** Configuration of Verify module */
            const softwareConfig = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config as ISoftwareConfig;
            /** Currently connected device */
            const identification = state.process.current?.identification;
            /** Device object from Identification step  */
            const identity = state.process.current?.transaction.identity;
            /** Custom name of the Verify module */
            const sender = (selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config?.ui?.title ?? inspectionDescriber(Inspections.Software)) + ` (${index})`;

            if (!identity) return null;
            
            if (!identification) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                }));
                return null;
            }

            // Block further execution, if connection method is not PiceaOne wireless or PiceaOne USB
            if (identification.method !== IdentificationMethods.PiceaOne &&
                identification.method !== IdentificationMethods.PiceaUsb) {
                return null;
            }
            
            /** Flag to indicate that other Piceasoft module (diagnostics/verify) is running to prevent interference */
            let isPiceaRunning = false
            selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.filter(i => i.index !== index).forEach(i => {
                if ([Inspections.Software, Inspections.Diagnostics].includes(i.type) && i.state.status === InspectionStatuses.Run) {
                    isPiceaRunning = true;
                }
            })
            // Block further execution, if diagnostic is running.
            if (isPiceaRunning) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.DEVICE_IS_BUSY_CUSTOM
                }));
                return null;
            }
            // If device was connected by PiceaOne wireless and device_id was changed,
            // stop the process and require to connect original device 
            // TODO: this will not work if PiceaOne was reinstalled on device
            if (identity.method === IdentificationMethods.PiceaOne) {
                if (identification.stamp !== identity.stamp) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                    }));
                    return null;
                }
            }
            // If device was connected by USB, and IMEI and device_id are both changed,
            // stop process and require to connect original device 
            // TODO: check if device_id changes on each USB reconnect
            if (identity.method === IdentificationMethods.PiceaUsb) {
                const id = identification.device.attributes.imei;
                const same = id ? id === identity.device.attributes.imei : identification.stamp === identity.stamp;
                if( !same) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: `${strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_CONNECT_DEVICE} ${identity.device.manufacturer} ${identity.device.name}.`
                    }));
                    return null;
                }
            }

            /** Function to start Verify when device is ready for verify checks */
            const start = () => {

                console.debug("%c" + "Verify: RUN start ()", consoleStyles.piceaCollback);
                dispatch(actionCreators.addLog("PICEA", "[verify] RUN start ()", {}));

                // get fresh state from redux. By default true, if some other issue raises it will be handled elsewhere.
                const isAppActive = getState().process.current?.identification?.isConnected ?? true;

                if (!isAppActive && identification.method === IdentificationMethods.PiceaOne) {
                    // app was in background, show info for user
                    dispatch(actionCreators.receiveInspectionModuleWarning( index, {
                        message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.RETURN_TO_APP_TO_CONTINUE_OR_RECONNECT_ALERT,
                        code: 400
                    }));

                    console.log("app was in background, waiting..");

                    // listening about when app is ready again and starting again
                    const handleApplicationStateChanged = (args: PiceaApplicationStateEventArgs) => {
                        if (identification.stamp === args.data.device_id && args.data.state === PiceaApplicationStates.Activated) {
                            console.log("App is active, trying again");
                            // remove listener
                            stop_verify_cb = undefined;
                            removeEvent();
                            // call starts with delay. Delay is needed to make sure redux store is updated about 'isConnected' field.
                            setTimeout(start, 900);
                        }
                    };

                    // add listener, start dgs when app state is correct
                    const removeEvent = window.PICEA.onApplicationStateChanged(handleApplicationStateChanged);

                    // remove listener if gets aborted
                    stop_verify_cb = () => {
                        removeEvent();
                    }

                    return null;
                }

                /** Verify checks from module configuration */
                let configChecks = softwareConfig.config?.checks ?? [];

                // TODO: check if Huawei Id exclusion is still needed
                if ((identification.device.attributes[DeviceAttributes.OS] as string).toUpperCase() === "ANDROID" &&
                    compareVersion2(identification.device.attributes[DeviceAttributes.OS_Version] ?? "0", "9.0") === -1) {
                    configChecks = configChecks.filter(i => i.id !== VerifyChecks.HUAWEI_ACCOUNT)
                }

                /** Getting API object from Piceasoft Online API */
                //const get_api = () => {

                    console.debug("%c" + "Picea® Online Services: CALL getAPI ('" + identification.stamp + "', 'SERVICE_VERIFY')", consoleStyles.piceaCollback);
                    console.info({ identification });
                    dispatch(actionCreators.addLog("PICEA", "[verify] CALL ONLINE_API.getAPI ('" + identification.stamp + "', 'SERVICE_VERIFY')", { identification }));

                    /** Config object for Online API */
                    const config = {
                        device_id: identification.stamp,
                        session_id: appState.process.current?.session_id,
                        service_id: window.ONLINE_API.SERVICE_VERIFY
                    };
                    // getting API object from Online API
                    window.ONLINE_API.getAPI(config, function (error: PiceaError, api: any) {
                        console.debug("%c" + "Picea® Online Services: CALLBACK getAPI ('" + identification.stamp + "', 'SERVICE_VERIFY')", consoleStyles.piceaCollback);
                        console.info({ error, api });
                        dispatch(actionCreators.addLog("PICEA", "[verify] CALLBACK ONLINE_API.getAPI ('" + identification.stamp + "', 'SERVICE_VERIFY')", { error, api }));
                        // Block further execution if Cancel (Abort) button was pressed
                        if (stop_verify) return;
                        // block further execution if error
                        // TODO: check error handling
                        if (error) {
                            if (identification.method === IdentificationMethods.PiceaOne && error.status === -1 &&
                                (error.details?.error === GenericErrors.EDP_FAILED_TIMEOUT || error.details?.error === GenericErrors.EDP_NO_CLIENTS)) {
                                
                                dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                        information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                        message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RETURN_TO_APP,
                                        code: 400
                                    }));
                                }))
                                
                                // stop(undefined, () => {
                                //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                //         information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                //         message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RETURN_TO_APP,
                                //         code: 400
                                //     }));
                                // });
                                
                                
                                return;
                            }
                            if (error.status === -1 && error.details?.error === 1) {
                                dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                //stop(error);
                                return;
                            }
                            if (-1 === error.status && -1 === error.details?.error && "Could not confirm service status." === error.details?.message) {
                                
                                dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                        information: strings.PICEA.ERRORS.GENERIC.UNEXPECTED_ERROR,
                                        message: "Could not confirm service status.",
                                        code: -1
                                    }));
                                }))
                                
                                // stop(undefined, () => {
                                //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                //         information: strings.PICEA.ERRORS.GENERIC.UNEXPECTED_ERROR,
                                //         message: "Could not confirm service status.",
                                //         code: -1
                                //     }));
                                // });
                                return;
                            }
                            
                            //stop(error);
                            dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                        } else { 
                            // all is good, got API object
                            // saving API object to global variable
                            current_picea_api_verify = api;
                            // Function to start Verify checks in device 
                            const run_verify = () => {
                                // Block further execution if Cancel (Abort) button was pressed
                                if (stop_verify) return;
                                // Block further execution if verify is already starting
                                if (is_start_verify) return;

                                // Subscription onVerifyStarted() event - start of checks in device
                                api.onVerifyStarted((error: PiceaError, data: any) => {
                                    console.debug("%c" + "Picea® Online Services: onVerifyStarted ()", consoleStyles.piceaCollback);
                                    console.info({ error, data });
                                    dispatch({ type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.VERIFY });
                                    dispatch(actionCreators.addLog("PICEA", "[verify] api.onVerifyStarted ()", { error, data }));
                                    // if event is for another deviceid, block further execution
                                    if (data.device_id !== identification.stamp) return;
                                    // Block further execution if Cancel (Abort) button was pressed
                                    if (stop_verify) return;
                                    // Block further execution if verify is already starting
                                    if (is_start_verify) return;
                                    // Block further execution if error
                                    if (error) {
                                        dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                        //stop(error);
                                    } else {
                                        // Set flag that Verify is starting
                                        is_start_verify = true;
                                        piceaOneExecutionContext = {
                                            index: index,
                                            device_id: data.device_id,
                                            inspection: Inspections.Software,
                                            operationUid: data.operation_status.uid
                                        }
                                    }
                                });

                                // Subscription to onVerifyCompleted() event - completion of checks in device
                                api.onVerifyCompleted(async function (error: PiceaError, data: any) {
                                    console.debug("%c" + "Picea® Online Services: onVerifyCompleted ()", consoleStyles.piceaCollback);
                                    console.info({error, data, is_start_verify, stop_verify});
                                    dispatch(actionCreators.addLog("PICEA", "[verify] api.onVerifyCompleted ()", {
                                        error,
                                        data,
                                        is_start_verify,
                                        stop_verify
                                    }));
                                    // if event is for another deviceid, block further execution
                                    // TODO: check for the same session id????
                                    if (data.device_id !== identification.stamp) return;
                                    // Block further execution, if Verify wasn't started, 
                                    if (!is_start_verify) return;
                                    // Block further execution if Cancel (Abort) button was pressed
                                    if (stop_verify) return;
                                    // Resetting flag, Verify is not starting
                                    is_start_verify = false;
                                    piceaOneExecutionContext = undefined
                                    // Block further execution if error
                                    if (error) {
                                        //stop(error);
                                        dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                    } else {
                                        // Pushing message that Verify results are being fetched
                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_GET_RESULT
                                        }));

                                        const [error, verifyResult] = processPiceaOneVerifyResult(state, index, data.operation_status?.verify_details?.checks)
                                        console.debug("Completing verify execution and send results to module", {
                                            error,
                                            verifyResult
                                        })

                                        // If Verify results available, push it to module
                                        if (!error && verifyResult) {
                                            if (verifyResult.checks?.length) {

                                                verifyResult.checks.forEach(i => {
                                                    //If STOLEN_CHECK_FAILURE
                                                    if (i.status == VerifyStatuses.VerifyStatus_StolenCheckFailure) {
                                                        dispatch({
                                                            type: 'SET_CURRENT_OPERATION',
                                                            currentOperation: OperationTypes.STOLEN_CHECK
                                                        });
                                                    }
                                                });

                                                // logging Verify operations to transaction user log
                                                dispatch(actionCreators.addEvents(verifyResult.checks.map(i => {
                                                    return {
                                                        action: sender,
                                                        status: `${verifyDescriber(i.check)}: ${verifyStatusDescriber(i.status)}`
                                                    }
                                                })));
                                            }

                                            // Completing verify execution and send results to module
                                            dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                dispatch(actionCreators.receiveInspectionModuleState(index, {...verifyResult}));
                                            }))

                                            // stop(undefined, () => {
                                            //     dispatch(actionCreators.receiveInspectionModuleState(index, {
                                            //         status: (successed || softwareConfig.config?.allowFail || verifyGrade) ? InspectionStatuses.Done : InspectionStatuses.Fail,
                                            //         grade: verifyGrade,
                                            //         grades: gradeCategories,
                                            //         uid: data.operation_status.uid,
                                            //         checks: verifyResult
                                            //     }));
                                            // });
                                        }
                                        //no verify results available or fetched
                                        else {
                                            dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE
                                                }));
                                            }))
                                            // stop(undefined, () => {
                                            //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                            //         message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE
                                            //     }));
                                            // });
                                        }
                                    }
                                });


                                console.debug("%c" + "Picea® Online Services: CALL getSupportedChecks ()", consoleStyles.piceaCollback);
                                console.info({ identification });
                                dispatch(actionCreators.addLog("PICEA", "[verify] CALL api.getSupportedChecks ()", { identification }));
                                // Запрашиваем поддерживаемые проверки уѝтройѝтвом
                                api.getSupportedChecks((error: PiceaError, data: any) => {
                                    console.debug('%c' + 'Picea® Online Services: CALLBACK getSupportedChecks ()', consoleStyles.piceaCollback)
                                    console.info({ error, data })
                                    dispatch(actionCreators.addLog('PICEA', '[verify] CALLBACK api.getSupportedChecks ()', { error, data }))
                                    // Еѝли была нажата кнопка отмены проверки, то предотвращаем дальнейшие дейѝтвиѝ
                                    if (stop_verify) return
                                    // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                    if (error) {
                                        //stop(error)
                                        dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                    } else {
                                        let checks: Array<any> = []
                                        configChecks.map((configCheck) => {
                                            if (data.checks.includes(configCheck.id)) {
                                                checks.push({
                                                    id: configCheck.id,
                                                    options: {}
                                                })
                                            }
                                        })

                                        if (checks.length === 0) {
                                            console.debug("%c" + "Picea® Online Services: No supported Verify checks", consoleStyles.piceaCollback);
                                            dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                dispatch(actionCreators.receiveInspectionModuleState(index, {
                                                    status: InspectionStatuses.Done,
                                                    grade: undefined,
                                                    grades: [],
                                                    uid: undefined,
                                                    checks: []
                                                }));
                                            }))
                                            // stop(undefined, () => {
                                            //     dispatch(actionCreators.receiveInspectionModuleState(index, {
                                            //         status: InspectionStatuses.Done,
                                            //         grade: undefined,
                                            //         grades: [],
                                            //         uid: undefined,
                                            //         checks: []
                                            //     }));
                                            // });
                                            return;
                                        }

                                        let config = {
                                            checks: checks,
                                            manual_device_identifiers: {
                                                imei: identification?.device.attributes[DeviceAttributes.IMEI] ?? "",
                                                imei2: identification?.device.attributes[DeviceAttributes.IMEI2] ?? "",
                                                SN: identification?.device.attributes[DeviceAttributes.SN] ?? ""
                                            }
                                        };

                                        // Объѝвлѝем отложенную функцию, котораѝ запуѝкает процеѝѝ проверки на уѝтройѝтве
                                        const start_verify = () => {
                                            setTimeout(() => {
                                                // Еѝли была нажата кнопка отмены проверок, то предотвращаем дальнейшие дейѝтвиѝ
                                                if (stop_verify) return;
                                                // Еѝли проверка уже была запущена, то предотвращаем дальнейшие дейѝтвиѝ
                                                if (is_start_verify) return;
                                                // Запуѝкаем проверки на уѝтройѝтве
                                                api.startVerify(config, function (error: PiceaError, data: any) {
                                                    console.debug("%c" + "Picea® Online Services: startVerify (config)", consoleStyles.piceaCollback);
                                                    console.info({ config, error, data, api });
                                                    dispatch(actionCreators.addLog("PICEA", "[verify] api.startVerify ( config )", { config, error, data }));
                                                    // Еѝли была нажата кнопка отмены проверок, то предотвращаем дальнейшие дейѝтвиѝ
                                                    if (stop_verify) return;
                                                    // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                                    if (error) {
                                                        dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                                        //stop(error);
                                                    } else {
                                                        switch (data.status) {
                                                            case 0:
                                                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                                break;
                                                            case -1:
                                                                dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                        message: strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT
                                                                    }));
                                                                }))                                                                
                                                                // stop(undefined, () => {
                                                                //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                //         message: strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT
                                                                //     }));
                                                                // });
                                                                break;
                                                            case -2:
                                                                // permissions needed. Show alert about it and try to retry automatically.
                                                                dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.PICEA_APP_GRANT_PERMISSIONS_ALERT
                                                                }));

                                                                console.log("failed, permissions required, trying again shortly");
                                                                setTimeout(() => {
                                                                    console.log("trying again start_verify");
                                                                    start_verify();
                                                                }, 4000);
                                                                break;
                                                            default:
                                                                dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                        message: strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT
                                                                    }));
                                                                }))
                                                                // stop(undefined, () => {
                                                                //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                //         message: strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT
                                                                //     }));
                                                                // });
                                                                break;
                                                        }
                                                    }
                                                });
                                            }, 2000);
                                        }

                                        // Только длѝ уѝтройѝтв Apple iOS при OTF подключении
                                        if (identity.method === IdentificationMethods.PiceaOne &&
                                            identity.device.attributes[DeviceAttributes.OS] === "ios" &&
                                            (
                                                softwareConfig.config?.alwaysMdmInstall || configChecks.find(check => check.id === VerifyChecks.FIND_MY)
                                            )) 
                                        {
                                            // Проверѝем наличие уѝтановленного MDM профилѝ на уѝтройѝтве
                                            window.ONLINE_API.isMDMprofileInstalled(identity.stamp, (error: PiceaError, data: any) => {
                                                console.debug("%c" + "Picea® Online Services: isMDMprofileInstalled (" + identity.stamp + ")", consoleStyles.piceaCollback);
                                                console.info({ error, data });
                                                dispatch(actionCreators.addLog("PICEA", "[verify] ONLINE_API.isMDMprofileInstalled ('" + identity.stamp + "')", { error, data }));
                                                // Еѝли была нажата кнопка отмены проверок, то предотвращаем дальнейшие дейѝтвиѝ
                                                if (stop_verify) return;
                                                // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                                if (error) {
                                                    start_verify();
                                                } else {
                                                    // Еѝли MDM профиль на уѝтройѝтве не уѝтановлен
                                                    if (data.status === 1) {
                                                        // Подпиѝываемѝѝ на ѝобытие изменениѝ MDM ѝтатуѝа
                                                        window.ONLINE_API.onMDMstatusChanged(identity.stamp, (error: PiceaError, data: any) => {
                                                            console.debug("%c" + "Picea® Online Services: onMDMstatusChanged (" + identity.stamp + ")", consoleStyles.piceaCollback);
                                                            console.info({ error, data });
                                                            dispatch(actionCreators.addLog("PICEA", "[verify] ONLINE_API.onMDMstatusChanged ('" + identity.stamp + "')", { error, data }));
                                                            // Еѝли была нажата кнопка отмены проверок, то предотвращаем дальнейшие дейѝтвиѝ
                                                            if (stop_verify) return;
                                                            // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                                            if (error) {
                                                                dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                                                //stop(error);
                                                            } else {
                                                                switch (data.status) {
                                                                    case window.ONLINE_API.MDM_STARTED:
                                                                        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                                            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.MDM_INSTALL_PROFILE_AND_RETURN_TO_APP,
                                                                            code: 600
                                                                        }));
                                                                        break;
                                                                    case window.ONLINE_API.MDM_FAILED:
                                                                        dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                                message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.MDM_PROFILE_INSTALL_PROBLEM
                                                                            }));
                                                                        }))
                                                                        // stop(undefined, () => {
                                                                        //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                        //         message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.MDM_PROFILE_INSTALL_PROBLEM
                                                                        //     }));
                                                                        // });
                                                                        break;
                                                                    case window.ONLINE_API.MDM_SUCCEEDED:
                                                                        dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                                        start_verify();
                                                                        break;
                                                                }
                                                            }
                                                        });

                                                        // Запрашиваем уѝтановку MDM профилѝ на уѝтройѝтво
                                                        window.ONLINE_API.installMDMprofile(identity.stamp, (error: PiceaError, data: any) => {
                                                            console.debug("%c" + "Picea® Online Services: installMDMprofile (" + identity.stamp + ")", consoleStyles.piceaCollback);
                                                            console.info({ error, data });
                                                            dispatch(actionCreators.addLog("PICEA", "[verify] ONLINE_API.installMDMprofile ('" + identity.stamp + "')", { error, data }));
                                                            // Еѝли была нажата кнопка отмены проверок, то предотвращаем дальнейшие дейѝтвиѝ
                                                            if (stop_verify) return;
                                                            // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                                            if (error) {
                                                                //stop(error);
                                                                dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                                            } else {
                                                                // Еѝли не уѝпех, только непонѝтно какой
                                                                if (data.status !== window.ONLINE_API.STATUS_OK) {
                                                                    dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                                                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.MDM_PROFILE_INSTALL_PROBLEM
                                                                        }));
                                                                    }))
                                                                    // stop(undefined, () => {
                                                                    //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                                    //         message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.MDM_PROFILE_INSTALL_PROBLEM
                                                                    //     }));
                                                                    // });
                                                                }
                                                            }
                                                        });
                                                    } else {
                                                        start_verify();
                                                    }
                                                }
                                            });
                                        } else {
                                            start_verify();
                                        }
                                    }
                                })
                            }

                            if (identification.method === IdentificationMethods.PiceaOne) {
                                run_verify();
                            } else {
                                if (stop_verify) {
                                    dispatch(actionCreators.stopPiceaSoftwareModule(index, error, () => {
                                        if (!error) {
                                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                                message: strings.INSPECTIONS.PICEA.ERROR_RESTART_SESSION_STATUS_1
                                            }));
                                        }
                                    }))
                                    // stop(error, () => {
                                    //     if (!error) {
                                    //         dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    //             message: strings.INSPECTIONS.PICEA.ERROR_RESTART_SESSION_STATUS_1
                                    //         }));
                                    //     }
                                    // });
                                } else {
                                    run_verify();
                                }
                            }
                        }
                    });
                //}

                //get_api();
            }

            // Данный флаг предотвращает ѝробатывание лишних запроѝов get_device_status
            let stop_get_device_status = false;
            // Функциѝ длѝ проверки ѝтатуѝа уѝтройѝтва
            const get_device_status = async (): Promise<boolean> => {
                // Возвращает True, еѝли проверка ѝтатуѝа уѝтройѝтва не вернула никаких ѝтатуѝов
                return new Promise<boolean>(resolve => {
                    setTimeout(() => {
                        if (stop_get_device_status) return;
                        window.ONLINE_API.getDevices(PiceaConnectionTypes.USB, async (error: any, type: any, data: any) => {
                            console.debug("%c" + "Picea® Online Services: ONLINE_API.getDevices (2)", consoleStyles.piceaCollback);
                            console.info({ error, type, data });
                            dispatch(actionCreators.addLog("PICEA", "[verify] ONLINE_API.getDevices (2)", { error, type, data }));
                            // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                            if (error || data?.status !== 0) {
                                dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                                //stop(error);
                            } else {
                                const _identification = getState().process.current?.identification;
                                if (!_identification) return;
                                // Запрашиваем ѝтатуѝ уѝтройѝтва
                                window.ONLINE_API.getDeviceStatus(identification.stamp, "verify", (error: any, data: any) => {
                                    console.debug("%c" + "Picea® Online Services: getDeviceStatus ('" + identification.stamp + "', 'verify')", consoleStyles.piceaCollback);
                                    console.info({ error, data });
                                    if (stop_get_device_status) return;
                                    dispatch(actionCreators.addLog("PICEA", "[verify] ONLINE_API.getDeviceStatus ('" + identification.stamp + "', 'verify')", { error, data }));
                                    // Еѝли получена ошибка, то оѝтанавливаем процеѝѝ проверки
                                    if (error || data?.status < 0) {
                                        stop_get_device_status = true;
                                        dispatch(actionCreators.stopPiceaSoftwareModule(index, error, () => resolve(false)))
                                        // stop(error, () => {
                                        //     return resolve(false);
                                        // });
                                    } else {
                                        if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status !== InspectionStatuses.Run) {
                                            stop_get_device_status = true;
                                            return resolve(false);
                                        }
                                        // Еѝли ѝтатуѝ уѝтройѝтва вернул коды ошибок
                                        if (data.values) {
                                            const values = (data.values as number[]).sort((a, b) => a - b);
                                            const index30 = values.indexOf(30);
                                            if (index30 > -1) {
                                                values.splice(index30, 1);
                                            }
                                            const index19 = values.indexOf(19);
                                            if (index19 > -1) {
                                                values.splice(index19, 1);
                                            }
                                            if (values.length > 0) {
                                                if (!stop_get_device_status) {
                                                    dispatch(actionCreators.receiveInspectionModuleWarning(index, {
                                                        information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                                        message: deviceDescriber(values[0], true) + " " + deviceDescriber(values[0]),
                                                        code: values[0]
                                                    }));
                                                }
                                            } else {
                                                if (!stop_get_device_status) {
                                                    stop_get_device_status = true;
                                                    dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                    return resolve(true);
                                                }
                                            }
                                        } else {
                                            if (!stop_get_device_status) {
                                                stop_get_device_status = true;
                                                // Коды ошибок отѝутѝтвуют. Очищаем предупреждениѝ в блоке проверок
                                                dispatch(actionCreators.clearInspectionModuleWarning(index));
                                                // Начинаем процедуру проверки
                                                return resolve(true);
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }, 1000);
                });
            }

            // Device status check counter
            let count = 0;
            // This function starts a timer to check the device status and returns True if the status check did not return any blocking states,
            // and False if the user canceled the diagnostic procedure or took no action for 120 seconds.
            const device_monitoring = async (): Promise<boolean> => {
                return new Promise<boolean>(resolve => {
                    const timer = setInterval(async () => {
                        console.debug("%c" + "LOGIC device_monitoring()", consoleStyles.logic);
                        console.info({ stop_verify });
                        if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state?.status !== InspectionStatuses.Run || ++count > 600) {
                            clearInterval(timer);
                            resolve(false);
                        } 
                        else {
                            const successed = await get_device_status();
                            if (successed) {
                                clearInterval(timer);
                                resolve(true);
                            }
                        }
                        count++;
                    }, 2000);
                });
            }

            // In case USB connection
            if (identification.method === IdentificationMethods.PiceaUsb) {
                // Request list of all connected devices. It solves the issue when PC Connector doesn't see connected device.
                window.ONLINE_API.getDevices(PiceaConnectionTypes.USB, async (error: any, type: any, data: PiceaDevices) => {
                    console.debug("%c" + "Picea® Online Services: ONLINE_API.getDevices (2)", consoleStyles.piceaCollback);
                    console.info({ error, type, data });
                    dispatch(actionCreators.addLog("PICEA", "[verify] ONLINE_API.getDevices (2)", { error, type, data }));

                    if( error && error.details?.error === GenericErrors.EDP_NO_CLIENTS) {
                        //  Socket connection has failed. Happens when PC connector crashes/is closed. Maybe some other causes too?
                        //  Manually restarting connector makes no difference, somehow the whole API connection would need to be rebuilt.
                        //  Doesn't look like a recoverable situation.
                        dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                information: strings.PICEA.ERRORS.GENERIC.EDP_CONNECTION_TO_SERVER_FAILED,  // "Connection to engine failed"
                                message: strings.PICEA.ERRORS.GENERIC.EDP_UNKNOWN_ERROR,    // "Unknown error. Contact with technical support."
                                code: 400
                            }));
                            dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Software)}`, strings.PICEA.ERRORS.SOCKET.ERR_SOCKET_NOT_CONNECTED));
                        }))
                        // stop(undefined, () => {
                        //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        //         information: strings.PICEA.ERRORS.GENERIC.EDP_CONNECTION_TO_SERVER_FAILED,  // "Connection to engine failed"
                        //         message: strings.PICEA.ERRORS.GENERIC.EDP_UNKNOWN_ERROR,    // "Unknown error. Contact with technical support."
                        //         code: 400
                        //     }));
                        //     dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Software)}`, strings.PICEA.ERRORS.SOCKET.ERR_SOCKET_NOT_CONNECTED));
                        // });
                        return;
                    }
                    // In case of error stops verify operation
                    if (error || data?.status !== 0) {
                        dispatch(actionCreators.stopPiceaSoftwareModule(index, error))
                        //stop(error);
                    } 
                    else {
                        // Verification if correct device is connected.
                        const find = data.devices.find(d => d.imei === identification.device.attributes[DeviceAttributes.IMEI]);
                        if (data.devices.length > 0 && !find) {
                            dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                    information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                                    message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WRONG_DEVICE,
                                    code: 400
                                }));
                                dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Software)}`, strings.PICEA.WRONG_DEVICE_EVENT));
                            }))
                            // stop(undefined, () => {
                            //     dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            //         information: strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_ACTION_REQUIRED,
                            //         message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WRONG_DEVICE,
                            //         code: 400
                            //     }));
                            //     dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Software)}`, strings.PICEA.WRONG_DEVICE_EVENT));
                            // });
                            return;
                        }
                        // Requests status of device and starts verify operation
                        if (await device_monitoring()) {
                            start();
                        } 
                        else {
                            dispatch(actionCreators.stopPiceaSoftwareModule(index, undefined, () => {
                                if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state.status === InspectionStatuses.Run) {
                                    dispatch(actionCreators.clearInspectionModuleWarning(index));
                                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New));
                                }
                            }))
                            // stop(undefined, () => {
                            //     if (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === index)?.state.status === InspectionStatuses.Run) {
                            //         dispatch(actionCreators.clearInspectionModuleWarning(index));
                            //         dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New));
                            //     }
                            // });
                        }
                    }
                });
            } 
            else {
                // Еѝли уѝтройѝтво подключено через OTF, запуѝкаем процедуру проверки
                start();
            }
        }, 1000);
    },

    /** Stops PiceaOne Verify execution and returns result to the module  */
    stopPiceaSoftwareModule: (index: number, stopError?: any, callback?: () => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        /** Function push error to Verify module and tries to stop all verify processes */

        // Длѝ теѝтированиѝ. Сейчаѝ без закрытиѝ ѝеѝѝии процеѝѝ не работает.
        console.debug("%c" + "LOGIC [verify] stopPiceaSoftwareModule()", consoleStyles.piceaCollback);
        console.info({ stopError, is_start_verify, current_picea_api_verify });

        // if API object is received earlier, try to correctly close all started processes
        if (current_picea_api_verify) {
            // Getting sessionId
            // TODO: check session management
            const sessionId = current_picea_api_verify.sessionId();
            console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.piceaCollback);
            console.info({ sessionId });
            // If session exists (was opened earlier), try to correctly close all started processes in session
            if (sessionId && sessionId != 0) {
                // Check flag, which is set to true in onVerifyStarted() callback
                if (is_start_verify) {
                    // Stopping Verify
                    current_picea_api_verify.stopVerify((error: PiceaError, data: any) => {
                        dispatch(actionCreators.addLog("PICEA", "[verify] api.stopVerify ()", { sessionId, error, data }));
                        console.debug("%c" + "Picea® Online Services: api.stopVerify ()", consoleStyles.piceaCollback);
                        console.info({ error, data });
                        is_start_verify = false;
                        piceaOneExecutionContext = undefined
                        // Еѝли требуетѝѝ закрывать ѝеѝѝию, то пытаемѝѝ закрыть ѝеѝѝию
                        // Незавиѝимо от результата stopVerify, выводим ѝтоп-ошибку в блоке проверок, еѝли она имеетѝѝ
                        // if needed, trying to close session
                        // regardless of stopVerify() result, push stop error (if available) to the module
                        if (stopError) {
                            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                                message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                                code: stopError.details?.error ?? stopError.status
                            }));
                        }
                        // setting API object to null
                        current_picea_api_verify = null;
                        // Calling callback function (if provided) to indicate completion of the stop process 
                        if (callback) callback();
                    });
                } 
                else {
                    // Verify checks were not started in existing session
                    // push stop error (if available) to the module
                    if (stopError) {
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                            message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                            code: stopError.details?.error ?? stopError.status
                        }));
                    }
                    // setting API object to null
                    current_picea_api_verify = null;
                    // Calling callback function (if provided) to indicate completion of the stop process 
                    if (callback) callback();
                }
            } 
            else {
                // No session was opened
                // push stop error (if available) to the module
                if (stopError) {
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                        message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                        code: stopError.details?.error ?? stopError.status
                    }));
                }
                // setting API object to null
                current_picea_api_verify = null;
                // Calling callback function (if provided) to indicate completion of the stop process 
                if (callback) callback();
            }
        } 
        else {
            // if API object wasn't received earlier
            // push stop error (if available) to the module
            if (stopError) {
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.Error, {
                    message: stopError.details?.error ? errorDescriber(stopError.details?.error) : stopError.status ? errorDescriber(stopError.status) : strings.PICEA.UNKNOWN_ERROR_JAVASCRIPT,
                    code: stopError.details?.error ?? stopError.status
                }));
            }
            // Calling callback function (if provided) to indicate completion of the stop process 
            if (callback) callback();
        }

        if (stop_verify_cb) {
            stop_verify_cb();
            stop_verify_cb = undefined;
        }
    },
    
    /** Aborts execution of Picea Verify operation */
    abortPiceaVerifyModule: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction>>> => (dispatch, getState) => {
        // Длѝ теѝтированиѝ. Сейчаѝ без закрытиѝ ѝеѝѝии процеѝѝ не работает.
        console.debug("%c" + `LOGIC abortPiceaVerifyModule(index: ${index})`, consoleStyles.logic);
        console.info({ current_picea_api_verify, stop_verify, is_start_verify });
        dispatch(actionCreators.addLog("LOGIC", `[verify] LOGIC abortPiceaVerifyModule(index: ${index})`, { current_picea_api_verify, stop_verify, is_start_verify }));
        // Еѝли кнопка прерываниѝ уже была нажата, то предотвращаем повторную процедуру прерываниѝ
        if (stop_verify) return;
        // Уѝтанавливаем глобальный флаг, что процеѝѝ проверки прерван
        stop_verify = true;
        if (stop_verify_cb) {
            stop_verify_cb();
            stop_verify_cb = undefined;
        }
        // Запиѝываем в журнал, что проверка прервана оператором
        dispatch(actionCreators.addEvent(`index: ${index} ${inspectionDescriber(Inspections.Software)}`, strings.INSPECTIONS.SOFTWARE.OPERATOR_ABORT_VERIFY));
        // Информируем в блоке проверок, что идёт процедура прерываниѝ проверки
        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
            message: strings.PICEA.VERIFY.CANCEL_WAIT,
            code: 500
        }));

        // Еѝли объект api был ранее получен, то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы
        if (current_picea_api_verify) {
            // Получаем ID ѝеѝѝии
            const sessionId = current_picea_api_verify.sessionId();
            console.debug("%c" + "Picea® Online Services: api.sessionId()", consoleStyles.piceaCollback);
            console.info({ sessionId });
            // Еѝли ѝеѝѝиѝ ѝушеѝтвует (была ранее открыта), то попытаемѝѝ корректно закрыть вѝе начатые процеѝѝы в ѝеѝѝии
            if (sessionId && sessionId != 0) {
                // Проверѝем флаг, который выѝтавлѝетѝѝ в true, еѝли был callback onVerifyStarted
                if (is_start_verify) {
                    current_picea_api_verify.stopVerify((error: PiceaError, data: any) => {
                        dispatch(actionCreators.addLog("PICEA", "[verify] api.stopVerify ()", { sessionId, error, data }));
                        console.debug("%c" + "Picea® Online Services: api.stopVerify ()", consoleStyles.piceaCollback);
                        console.info({ error, data });
                        // Уѝтанавливаем глобальный флаг, что процеѝѝ проверки не запущен
                        is_start_verify = false;
                        piceaOneExecutionContext = undefined
                        current_picea_api_verify = null;
                        dispatch(actionCreators.clearInspectionModuleWarning(index));
                        dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                    });
                } else { // Проверка в рамках открытой ѝеѝѝии не была запущена
                    current_picea_api_verify = null;
                    dispatch(actionCreators.clearInspectionModuleWarning(index));
                    dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
                }
            } else {
                current_picea_api_verify = null;
                dispatch(actionCreators.clearInspectionModuleWarning(index));
                dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
            }
        } else {
            // Сообщаем о завершении оѝтановки процеѝѝа
            dispatch(actionCreators.clearInspectionModuleWarning(index));
            dispatch(actionCreators.changeInspectionModuleStatus(index, InspectionStatuses.New, undefined, true));
        }
    },

    /** Method requests Verify Operation results from session API
     * @param index module's index
     * */
    requestVerifyCheckSessionReport: (index: number): AppThunkAction<KnownAction | AppThunkAction<KnownAction | AppThunkAction<KnownAction |AppThunkAction<KnownAction>>>> => async (dispatch, getState) => {

        const state = getState();
        
        const operationUid = piceaOneExecutionContext?.operationUid ?? ""

        const handleOnError  = (message: string) => {
            dispatch(actionCreators.receiveInspectionModuleWarning(index, { message }));
            
            setTimeout(() => {
                dispatch(actionCreators.clearInspectionModuleWarning(index))
            }, 3000)
        } 
        
        // Show message about fetching diag results
        dispatch(actionCreators.receiveInspectionModuleWarning(index, {
            message: strings.ACTIONS_MESSAGES.PROCESS.PICEA.WAIT_GET_RESULT
        }));
                        
        console.debug("%c" + "LOGIC: getDiagnosticsResult(" + operationUid + ")", consoleStyles.logic);
        const response = await _api.v1.picea.getVerify(operationUid);

        if (!response.successed) {
            handleOnError(strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE)
            return;
        }
        
        try {
            if (response.data) {
                const [verifyCheckResultError, verifyCheckResult] = processPiceaOneVerifyResult(state, index, response.data)
                
                if (!verifyCheckResultError && verifyCheckResult) {
                    // Adding executed test cases to transaction user log
                    // TODO: check how it works, in particular localization
                    if (verifyCheckResult.checks?.length) {
                        const sender = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === index)?.config?.ui?.title ?? strings.INSPECTIONS.SOFTWARE.TITLE;
    
                        verifyCheckResult.checks.forEach(i => {
                            //If STOLEN_CHECK_FAILURE
                            if (i.status == VerifyStatuses.VerifyStatus_StolenCheckFailure) {
                                dispatch({type: 'SET_CURRENT_OPERATION', currentOperation: OperationTypes.STOLEN_CHECK});
                            }
                        });
                        
                        // logging Verify operations to transaction user log
                        dispatch(actionCreators.addEvents(verifyCheckResult.checks.map(i => {
                            return {
                                action: sender,
                                status: `${verifyDescriber(i.check)}: ${verifyStatusDescriber(i.status)}`
                            }
                        })));
                    }
    
                    dispatch(actionCreators.stopPiceaOneDiagnostics(index, undefined, () => {
                            dispatch(actionCreators.receiveInspectionModuleState(index, {...verifyCheckResult}))
                        })
                    )
                    return
                }
            }
            
        } catch (error) {
            handleOnError(strings.ACTIONS_MESSAGES.PROCESS.PICEA.TESTS_RESULTS_GET_FAILURE)
            return
        }

        handleOnError(strings.SHARED.SEARCH_NO_RESULTS)
       
    }, 
    
    setSessionState: (session_state: PiceaSessionState) : SetSessionStateAction => {
        return {type: 'PROCESS_SET_PICEA_SESSION_STATE', session_state};
    }
};

const executeImeiLockoutCheck = async (dispatch: any, imei: string, processState: IProcess, config: IImeiLockoutConfig): Promise<boolean> => {
    // Cache imei lock check for 10 seconds to remove duplicate calls for current IMEI.
    const checkTimeThresholdMilliseconds = 10000;
    const currentTimeMilliseconds = new Date().getTime();

    let currentImeiLockCheck = imei_lock_check_data.find(d => d?.imei === imei);

    if (currentImeiLockCheck && currentImeiLockCheck?.resultPromise === undefined && currentTimeMilliseconds - currentImeiLockCheck.lastCheckTime < checkTimeThresholdMilliseconds) {
        console.debug("%c" + `LOGIC ExecuteImeiLockoutCheck: imei '${imei}'. Locked: '${currentImeiLockCheck.locked}'. From cache.`, consoleStyles.logic);

        processImeiLockedResult(dispatch, config, processState, imei, currentImeiLockCheck.locked);

        return currentImeiLockCheck.locked;
    }

    console.debug("%c" + `LOGIC ExecuteImeiLockoutCheck: imei '${imei}', period: '${config.period}'. Start.`, consoleStyles.logic);

    const waitMessage = {
        text: strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_CHECKING_MESSAGE,
        type: MessageBarType.info,
        code: ProcessMessageCodes.imeiChecking,
        hideFooter: true,
        showSpinner: true,
        isBlocker: {
            dialogTitle: strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_CHECKING_TITLE,
            hideDialog: false
        }
    }

    dispatch({
        type: 'PROCESS_MESSAGE',
        message: waitMessage
    });

    if (!currentImeiLockCheck) {
        currentImeiLockCheck = {
            imei: imei,
            lastCheckTime: currentTimeMilliseconds,
            locked: false
        };

        imei_lock_check_data.push(currentImeiLockCheck);
    }

    if (!currentImeiLockCheck.resultPromise) {
        const resultPromise = new Promise<boolean>(async (resolve, reject) => {
            const result = await api.v1.identification.imeiLockoutCheck(imei, config.period);

            resolve(result.data === true);
        });

        currentImeiLockCheck.resultPromise = resultPromise;
    }

    const isLocked = await currentImeiLockCheck.resultPromise;

    currentImeiLockCheck.resultPromise = undefined;
    currentImeiLockCheck.lastCheckTime = currentTimeMilliseconds;
    currentImeiLockCheck.locked = isLocked;

    console.debug("%c" + `LOGIC ExecuteImeiLockoutCheck: imei '${imei}'. Locked: '${currentImeiLockCheck.locked}'. Finished.`, consoleStyles.logic);

    processImeiLockedResult(dispatch, config, processState, imei, currentImeiLockCheck.locked ?? false);

    return currentImeiLockCheck.locked;
}

const processImeiLockedResult = (dispatch: any, config: IImeiLockoutConfig, processState: IProcess, imei: string, imeiLocked: boolean) => {
    dispatch({ type: 'PROCESS_MESSAGE_CLEAR', force: true })
    dispatch({ type: 'PROCESS_IMEI_LOCKED', imeiLocked: imeiLocked })

    if (!imeiLocked) {
        return false;
    }

    if (processState.transaction.identity) {
        dispatch(actionCreators.addEvent(strings.ACTIONS_MESSAGES.DEVICE.IMEI_LOCKOUT, imei))
    } else {
        dispatch(actionCreators.addLog('DEVICE_IMEI_LOCKOUT', 'IMEI locked', { imei: imei }))
    }

    const message = {
        text: config?.message ?? strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_MESSAGE_DEFAULT,
        type: MessageBarType.error,
        code: ProcessMessageCodes.imeiLockout,
        isBlocker: {
            dialogTitle: config?.title ?? strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_TITLE_DEFAULT,
            dialogAction: () => {
                if ([ProcessStages.Resources, ProcessStages.Identification, ProcessStages.PreOffer].includes(processState?.stage ?? ProcessStages.Resources)) {
                    dispatch(actionCreators.setPreviousStage());
                    dispatch(actionCreators.clearProcessMessage(false, ProcessMessageCodes.imeiLockout));
                    dispatch(actionCreators.clearIdentification());
                    dispatch(actionCreators.clearOffers());
                    dispatch(actionCreators.receiveProviderPromotion());
                    dispatch(actionCreators.prepareOfferProvidersState());
                    dispatch(actionCreators.preparePromoProvidersState());
                    dispatch(actionCreators.clearControlledTransaction());
                } else {
                    dispatch({ type: 'PROCESS_MESSAGE_BLOCKER_DIALOG_TOGGLE' })
                }
            },
            nextAction: () => { dispatch({ type: 'PROCESS_MESSAGE_BLOCKER_DIALOG_TOGGLE' }) },
            hideDialog: false
        }
    }

    dispatch({
        type: 'PROCESS_MESSAGE',
        message: message
    })
}

const getStageLogTitle = (stageType?: ProcessStages): string => {
    switch (stageType) {
        case ProcessStages.Assessment: return "ASSESSMENT"
        case ProcessStages.Control: return "CONTROL"
        default: return ""
    }
}

const addNonConnectedDevice = (stage: any, method: any, stamp: any, device: any, state: any, dispatch: any, index: any, input: any, isAuthenticated: any, isConnected: any, warning: any, getState: any) => {
    // _api.v1.identification.check(input);
    if (![IdentificationMethods.PiceaOne, IdentificationMethods.PiceaUsb].includes(method) &&
        stage !== undefined && [ProcessStages.Identification].includes(stage) &&
        !stamp && window.ONLINE_API?.addNonConnectedDevice
    ) {
        interface NonConnectedPiceaDevice {
            /** Device type. */
            tid: string
            /** Device manufacturer. */
            manufacturer: string
            /** Device model. */
            model_name: string
            /** Device model code/number. */
            model_code?: string
            /** Device primary IMEI. */
            imei?: string
            /** Device secondary IMEI. */
            imei2?: string
            /** Device MEID value. */
            meid?: string
            /** Device serial number. */
            serial?: string,
            /*Device Group */
            group?: string
            /* Device storage */
            storage?: string
            /* Device product code/number */
            product_code?: string
        }

        let deviceStorage = Number.parseInt(device.attributes[DeviceAttributes.Capacity]);

        const config = {
            device: {
                imei: device.attributes[DeviceAttributes.IMEI] ?? "",
                imei2: device.attributes[DeviceAttributes.IMEI2] ?? "",
                model_name: device.name, // Mandatory
                serial: device.attributes[DeviceAttributes.SN] ?? "",
                manufacturer: device.manufacturer, // Mandatory
                storage: deviceStorage != 0 ? deviceStorage : undefined,
                tid: "0C28446F-85D3-43A6-A5EB-E3A2D42AC75A",
                group: device.group,
                product_code: device.attributes[DeviceAttributes.Product_code] ?? undefined,
            } as NonConnectedPiceaDevice
        }



        // const config = {
        //     device: {
        //         imei: "",
        //         imei2: "",
        //         meid: "",
        //         model_code: "",
        //         model_name: device.name,
        //         serial: "",
        //         manufacturer: device.manufacturer,
        //         tid: "0C28446F-85D3-43A6-A5EB-E3A2D42AC75A",
        //         color: "",
        //         brand_name: "",
        //         inventory_id: "",
        //         series: "",
        //         category: "",
        //         graphics: "",
        //         storage_technology: ""
        //     }
        // }

        interface AddNonConnectedDeviceCallbackData {
            device: {
                device_id: string
                device_info: {
                    device: {
                        memory_size: number
                    }
                }
                fid: string
                group?: string
                hardware_id?: string
                has_gsm: boolean
                has_euicc: boolean
                hw_model?: string
                imei: string
                imei2: string
                manufacturer: string
                memory_ram?: number
                model_code?: string
                product_code?: string
                model_name: string
                model_uid?: string
                meid?: string
                name: string,
                non_connected_device: boolean
                processor?: string
                screen_size?: string
                serial?: string
                storage: string
                sw_version?: string
                tid: string
                usb_serial?: string
                usb_vid?: string
            }
        }

        window.ONLINE_API.addNonConnectedDevice(config, (error: PiceaGetApiError, data: AddNonConnectedDeviceCallbackData) => {
            console.debug("%c" + "Picea™ Online Services: ONLINE_API.addNonConnectedDevice()", consoleStyles.piceaCollback);
            console.info({ config });
            console.info({ error, data });


            if (data?.device) {
                if(!data?.device.group && config.device){
                    data.device.group = config.device.group;
                }

                let callbackDevice: PiceaDevice = {
                    ...data.device,
                    image_url: data.device.model_uid
                        ? `${getApiHostname(state.environment.name)}/deviceinfo/v1/get_image?uid=${data.device.model_uid}`
                        : `${getApiHostname(state.environment.name)}/deviceinfo/v1/get_image?manufacturer=${data.device.manufacturer}&model_name=${data.device.model_name}`
                }

                if( !callbackDevice.storage && data.device.device_info?.device?.memory_size) {
                    callbackDevice.storage = data.device.device_info.device.memory_size.toString();
                }

                if(!config.device.storage && !callbackDevice.storage) {
                  callbackDevice.storage = device.attributes[DeviceAttributes.Capacity] ?? "0"
                }

                dispatch(actionCreators.addLog(`IDENTIFICATION_MODULE_INDEX_${index}`, "PROCESS_RECEIVE_IDENTIFICATION", input));
                dispatch({ type: 'PROCESS_RECEIVE_IDENTIFICATION', moduleIndex: index, device: deviceBuild(callbackDevice), method: method, isAuthenticated: isAuthenticated ?? false, isConnected: isConnected ?? false, stamp: data.device.device_id, warning: warning });
                dispatch(actionCreators.addEvents([
                    { action: `${strings.EVENTS.ACTION.DEVICE_IDENTIFICATION}: ${index}`, status: `${device.manufacturer} ${device.name} ${device.configuration} ${device.attributes.capacity ?? ""}` },
                    { action: `${strings.EVENTS.ACTION.IDENTIFICATION_METHOD}: ${index}`, status: getLocalizedIdentificationMethod(method) }
                ]));

                // start listening onDeviceUpdated
                window.PICEA.onDeviceUpdated((args) => {
                    if (args.connectionType === PiceaConnectionTypes.NCD) {
                        // when we got "device updated", NCD connection to device is ready
                        // we mark device as "isAuthenticated: true", to indicate that device has connected to NCD server and has provided it's details
                        console.log("NCD device connected!");
                        console.log("args: " + JSON.stringify(args));
                        const dev = deviceBuild(args.device);
                        console.log({dev});
                        const identity = getState().process.current.transaction.identity;
                        const areDevicesIdentical = mobileIdentificationGuard({...dev, storage: Number(args.device.storage)}, identity);
                        const diagVerifyIndeces = getState().process.current.transaction.assessment.modules.filter((m: IInspectionModuleState) => m.type === Inspections.Diagnostics || m.type === Inspections.Software);
                        if(areDevicesIdentical) {
                            dispatch({ type: 'PROCESS_RECEIVE_IDENTIFICATION', moduleIndex: index, device: {...getState().process.current.identification.device}, method: method, isAuthenticated: true, isConnected: isConnected ?? false, stamp: args.device.device_id, warning: warning });
                            diagVerifyIndeces.map((m: IInspectionModuleState) => {
                                if(m.state.warning?.message) {
                                    dispatch(actionCreators.clearInspectionModuleWarning(m.index));
                                }
                            })      
                        } else {
                            diagVerifyIndeces.map((m: IInspectionModuleState) => dispatch(actionCreators.receiveInspectionModuleWarning(m.index, {message: strings.IDENTIFICATION.TEXT_WRONG_DEVICE_CONNECTOD})))
                        }
                    }
                })

                // _api.v1.identification.check(input);
            } else {
                dispatch({
                    type: 'PROCESS_MESSAGE',
                    message: {
                        text: errorDescriber(error.details.error ?? GenericErrors.UNEXPECTED_ERROR),
                        type: MessageBarType.error,
                        onRetryAction: () => {
                            dispatch({ type: 'PROCESS_MESSAGE_CLEAR' });
                            dispatch(actionCreators.receiveIdentificationModule(index, device, method, isAuthenticated, isConnected, stamp, warning));
                        }
                    }
                });
            }
        })
    } else {
        if (stage !== undefined && [ProcessStages.Assessment, ProcessStages.Control].includes(stage)) {
            dispatch(actionCreators.updateTransactionIdentity(input, false, `AddNCDevice(Stage-${stage})`))
        }
        dispatch(actionCreators.addLog(`IDENTIFICATION_MODULE_INDEX_${index}`, "PROCESS_RECEIVE_IDENTIFICATION", input));
        dispatch({ type: 'PROCESS_RECEIVE_IDENTIFICATION', moduleIndex: index, device: device, method: method, isAuthenticated: isAuthenticated ?? false, isConnected: isConnected ?? false, stamp: stamp, warning: warning });
        dispatch(actionCreators.addEvents([
            { action: `${strings.EVENTS.ACTION.DEVICE_IDENTIFICATION}: ${index}`, status: `${device.manufacturer} ${device.name} ${device.configuration} ${device.attributes.capacity ?? ""}` },
            { action: `${strings.EVENTS.ACTION.IDENTIFICATION_METHOD}: ${index}`, status: getLocalizedIdentificationMethod(method) }
        ]));

        // _api.v1.identification.check(input);
    }
}

/**
 * Function process verify check data returned by PiceaOne app
 * @param state - state of the application. see {@link IStore}
 * @param module_index - index of verify module
 * @param data - result of verify checks to process
 * 
 * @returns Processed verify check details(see {@link ISoftware}) or error if data can't be processed.
 * */
const processPiceaOneVerifyResult = (state: IStore, module_index: number, data: IPiceaVerify[]) : [string | undefined, ISoftware | undefined] => {

    if (!data)
        return [strings.SHARED.SEARCH_NO_RESULTS, undefined]

    /** Verify execution results */
    // filter out not supported checks, since they are considered as failed which is incorrect
    let verifyResult: IPiceaVerify[] = data
        .filter((c: any) => c.status !== VerifyStatuses.VerifyStatus_NotSupported)
        .map((c: any) => (
            {
                check: c.check_id,
                successed: c.status == VerifyStatuses.VerifyStatus_Passed,
                status: c.status,
                error_code: c.error_code,
                data: {...c.data}
            } as IPiceaVerify))

    if (verifyResult.length === 0) { //Nothing done
        return [undefined, {
            status: InspectionStatuses.Done,
            grade: undefined,
            grades: [],
            checks: []
        }]
    }

    // Block further execution if Cancel (Abort) button was pressed
    if (stop_verify) return [strings.INSPECTIONS.SOFTWARE.CHECKS_ABORT, undefined];

    const softwareConfig = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === module_index)?.config as ISoftwareConfig;
    
    /** TODO: how to get list of grades in case of using Grade Categories may be {Category}-{Grade}? */
    const grades = state.process.current?.workflow.useGradesCategories
    ? (state.process.current.workflow.gradesCategories?.find(gc => gc.code === softwareConfig.gradesCategory)?.grades ?? [])
    : state.process.current?.workflow?.grades;

    /** Grade categories of the flow */
    const categories = state.process.current?.workflow.gradesCategories;

    /** Resulting grades by categories. This array is used even grade categories are not used in flow,
     * in this case category = ""
     */
    const {gradeCategories, receiveGrade} = useGrades(grades, categories)

    /** Flag indicates that all Verify checks are passed */
    const successed = verifyResult.filter(r => r.status === 1).length === verifyResult.length;

    // if some checks were failed, and downgrade option is set,
    // push this downgrade grade to results
    if (!successed && softwareConfig.config?.downGrade !== undefined) {
        //TODO: remove grade category setting from verify module in Configuration Tool, and 
        // implement direct selection Category => Grade in downgrade option
        const downCategory = softwareConfig.config?.downGrade.indexOf("-") === -1 ? categories ? softwareConfig.gradesCategory : "" : softwareConfig.config?.downGrade.split("-")[0];
        const downGrade = softwareConfig.config?.downGrade.indexOf("-") === -1 ? softwareConfig.config?.downGrade : softwareConfig.config?.downGrade.split("-")[1];
        receiveGrade(downCategory ?? "", downGrade);
    }

    // assigning grades to failed Verify checks
    verifyResult.map((c) => {
        /** Piceasoft Verify check */
        const check_config = softwareConfig.config?.checks.find(cc => cc.id === c.check);
        c.successed = c.status === 1
        if (!c.successed && check_config?.grade !== undefined) {
            //TODO: check if it is safe to rely on "-" as delimiter for category and grade. It can be used in grade code... 
            const checkCategory = check_config.grade.indexOf("-") === -1 ? categories ? softwareConfig.gradesCategory : "" : check_config.grade.split("-")[0];
            const checkGrade = check_config.grade.indexOf("-") === -1 ? check_config.grade : check_config.grade.split("-")[1];
            c.grade = categories ? `${checkCategory}-${checkGrade}` : check_config.grade;
            // push grade to results
            receiveGrade(checkCategory ?? "", checkGrade);
        }
    });
    /** variable to store the result grade for Verify module */
    let verifyGrade: string | undefined
    // if grade categories used in the flow
    if (categories) {
        // Еѝли ѝреди категорий вѝтречаетѝѝ пуѝтой грейд (запрет приёма), то проваливаем веѝь результат проверок
        // TODO: if decline option works
        if (gradeCategories.length === 0) { //no failed checks
            verifyGrade = undefined
        } else if (gradeCategories.find(gc => gc.grade === "")) {
            verifyGrade = ""; // decline?? check if it works
        } else {
            //several grades can be returned by the module, form combined grade for display purposes
            /* 
            In case of Grade Categories, final grade is combined from grades in each category. 
            Final grade length is always fixed.
            If grade is missing for some categories, it is replaced with ? mark
            */
            verifyGrade = "";
            gradeCategories.forEach((gcat, index) => {
                verifyGrade += `${gcat.category}-${gcat.grade}`
                if (!(index + 1 >= gradeCategories.length)) {
                    //no delimiter after last category
                    verifyGrade += ", "
                }
            })
            //verifyGrade = gradeCategories.find(gc => gc.category === softwareConfig.gradesCategory)?.grade ?? "";
        }
        // grade categories not used in flow
    } 
    else {
        if (gradeCategories.length === 0) {
            verifyGrade = undefined
        } else if (gradeCategories.find(gc => gc.category === "")?.grade === "") {
            verifyGrade = "";
        } else {
            verifyGrade = gradeCategories.find(gc => gc.category === "")?.grade ?? "";
        }
    }

    // ???
    if (verifyGrade === "" && softwareConfig.config?.allowFail) {
        verifyGrade = undefined
    }

    return [undefined, {
        status: (successed || softwareConfig.config?.allowFail || verifyGrade) ? InspectionStatuses.Done : InspectionStatuses.Fail,
        grade: verifyGrade,
        grades: gradeCategories,
        //uid: data.operation_status.uid,
        checks: verifyResult
    }]
}

/**
 * Function process diagnostics data returned by PiceaOne app 
 * @param {IStore} state - state of the application. see {@link IStore}
 * @param {number} module_index - index of diagnostics module
 * @param {IDiagnosticsPiceaTestConfig} testConfig - configuration of diagnostics module. see {@link IDiagnosticsPiceaTestConfig}
 * @param {number} resultTestIndex 
 * @param {IDiagnosticCaseResult[]} data - Result of test cases. see {@link IDiagnosticCaseResult}
 * 
 * @returns Processed diagnostics details(see {@link IDiagnostics}) or error if data can't be processed.
 * */
const processPiceaOneDiagnosticsResult = (state: IStore, module_index: number, testConfig: IDiagnosticsPiceaTestConfig, resultTestIndex: number, data?: IDiagnosticCaseResult[] ) : [string | undefined, IDiagnostics | undefined] => {
    
    // Validate if we received data
    if (!data) return [strings.SHARED.SEARCH_NO_RESULTS, undefined]

    // filter out not supported test cases, since they can be considered as failed which is incorrect
    let diagnosticResult = data?.filter(
        c => c.status !== DiagnosticStatuses.TCR_NOT_SUPPORTED_BY_THE_DEVICE);

    // Is there are no supported test return empty result which will be treated as Done
    if (!diagnosticResult || diagnosticResult.length === 0)
        return [undefined, {
            status: InspectionStatuses.Done,
            grade: undefined,
            grades: [],
            tests: []
        }]

    // Block further execution if diag is stopping
    if (stop_diagnostics) return [strings.INSPECTIONS.DIAGNOSTICS.OPERATOR_ABORT_DIAGNOSTICS, undefined];

    /** configuration of the module */
    const moduleConfig = selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === module_index)?.config as IDiagnosticsConfig

    /** configuration for Piceaone diagnostic module */
    const diagnosticsConfig = moduleConfig.config as IDiagnosticsPiceaConfig

    /** Array of results of diag templates execution */
    const testsResult = (selector.process.getCurrentStageIndexInspectionModules(state.process.current)?.find(m => m.index === module_index)?.state as IDiagnostics).tests

    /** List of grades, used in the flow */
    const grades = state.process.current?.workflow.useGradesCategories
        ? (state.process.current.workflow.gradesCategories?.find(gc => gc.code === moduleConfig.gradesCategory)?.grades ?? [])
        : state.process.current?.workflow?.grades;

    /** Grade categories, if used in the flow */
    const categories = state.process.current?.workflow.gradesCategories;

    //let gradeCategories: IGradeWithCategory[] = []
    const { gradeCategories, receiveGrade, worstGrades, bestGradeIndex } = useGrades(grades, categories)

    /** Status of the whole Diag execution (passed or not) */
    let passed = true;
    /** Array variable to store Piceasoft test sets results */
    let sets: IDiagnosticSetResult[] = [];

    // Forming result structure for further handling of diag results
    // Filling sets with data from diagnosticsResults
    diagnosticResult.map((cr: IDiagnosticCaseResult) => {
        const s = definitions.diagnostic.getSetByCase(cr.case_id);
        if (s) {
            if (sets[s.id] === undefined) {
                sets[s.id] = { set_id: s.id, cases: [], successed: false };
            }
            sets[s.id].cases.push(cr);
        }
    });
    sets = sets.filter(s => s); //removing empty, null and undefined entries

    // assigning grades to test sets and test cases
    sets.map((s) => {
        if (s.cases.length > 0) {
            /** Piceasoft test set */
            const set_config = testConfig.sets.find(sc => sc.id === s.set_id);
            // If all test cases of the test set are not failed, consider test set as succeded
            /** Success flag for test set */
            let succeed = (s.cases.filter(c => ![DiagnosticStatuses.TCR_PASSED, DiagnosticStatuses.TCR_NOT_SUPPORTED_BY_THE_DEVICE].includes(c.status)).length ?? 0) === 0;

            let setCategory : string | undefined = undefined
            let setGrade: string | undefined = undefined

            // If test case is failed, and a failed grade assigned on test set level,  
            // set grade and push it to results
            if (!succeed && set_config?.grade !== undefined) {
                //TODO: implement grade category selection on test set level in Configuration Tool
                setCategory = categories ? moduleConfig.gradesCategory : "";
                setGrade = set_config.grade;
                s.grade = categories ? `${setCategory}-${setGrade}` : set_config.grade;
                // push grade to result
                receiveGrade(setCategory ?? "", setGrade);
            }

            // Set grades for failed test cases 
            if (!succeed) {
                s.cases.map((c) => {
                    /** Piceasoft test case */
                    const case_config = set_config?.cases.find(cc => cc.id === c.case_id);
                    if (c.status !== DiagnosticStatuses.TCR_PASSED && case_config?.grade !== undefined) {
                        //TODO: check if it is safe to rely on "-" as delimiter for cathegory and grade. It can be used in grade code... 
                        let caseCategory = case_config.grade.indexOf("-") === -1 ? (categories ? moduleConfig.gradesCategory : "") : case_config.grade.split("-")[0];
                        let caseGrade = case_config.grade.indexOf("-") === -1 ? case_config.grade : case_config.grade.split("-")[1];
                        c.grade = categories ? `${caseCategory}-${caseGrade}` : case_config.grade;
                        // push grade to result
                        receiveGrade(caseCategory ?? "", caseGrade);
                        // if test set and test case category are equal, update set grade to the lowest one
                        if (setCategory === caseCategory) {
                            s.grade = worstGrades(setCategory ?? "", setGrade ?? "", caseGrade);
                        }
                    }
                });
            }

            s.successed = succeed;

            if (!succeed)
                passed = false;
        }
    })

    //TODO: decline doesn't work in Diag, nor for grades nor categories
    /** variable to store the result grade for diag module */
    let diagnosticGrade: string | undefined
    // if grade categories used in the flow
    if (categories) {
        // If among the categories we encounter an empty grade (prohibited reception), then we invalidate the entire diagnostic result.
        if (gradeCategories.length === 0) { //no failed test sets or test cases
            diagnosticGrade = undefined // TODO: VALIDATE THIS CASE; was  diagnosticGrade === undefined
        } else if (gradeCategories.find(gc => gc.grade === "")) {
            diagnosticGrade = ""; // decline?? this doesn't work
        } else {
            //several grades can be returned by the module, form combined grade for display purposes
            /** 
                In case of Grade Categories, final grade is combined from grades in each categories. 
                Final grade length is always fixed.
                If grade is missing for some categorie, it is replaced with ? mark
            */
            diagnosticGrade = "";
            gradeCategories.forEach((gcat, index) => {
                diagnosticGrade += `${gcat.category}-${gcat.grade}`
                if (!(index + 1 >= gradeCategories.length)) {
                    //no delimiter after last category
                    diagnosticGrade  += ", "
                }
            })
        }
        // grade categories not used in flow
    } else {
        if (gradeCategories.length === 0) {
            diagnosticGrade = undefined // TODO: VALIDATE THIS CASE; was  diagnosticGrade === undefined
        } else if (gradeCategories.find(gc => gc.category === "")?.grade === "") {
            diagnosticGrade = "";
        } else {
            diagnosticGrade = gradeCategories.find(gc => gc.category === "")?.grade ?? "";
        }
    }

    // success grade is not set implemented for Grade cathegories
    // TODO: remove this option from Configuration Tool if using Grade Categories
    if ((!categories) && passed && diagnosticGrade === undefined && typeof diagnosticsConfig.setSuccessGrade === 'string') {
        if (diagnosticsConfig.setSuccessGrade === "") {
            diagnosticGrade = grades?.find(g => g.index === bestGradeIndex)?.code ?? ""
        } else {
            diagnosticGrade = diagnosticsConfig.setSuccessGrade;
        }
    }

    /** Result of the executed diag template */
    const currentTest: IPiceaDiagnosticTest = {
        grade: diagnosticGrade ?? "",
        grades: gradeCategories,
        index: resultTestIndex,
        name: testConfig.name,
        description: testConfig.description,
        //???
        // uid: operation_uid,
        cases: diagnosticResult,
        sets: sets
    }

    /** Updating array of results of executed Diag templates */
    let updatedTests: IPiceaDiagnosticTest[] = [
        ...(testsResult?.filter(t => t.index !== resultTestIndex) ?? []),
        currentTest
    ];

    // Completing Diag execution and send results to module
    return [undefined, {
        status: passed || diagnosticGrade ? InspectionStatuses.Done : InspectionStatuses.Fail,
        grade: diagnosticGrade,
        grades: gradeCategories,
        tests: updatedTests
    }]
}

/** 
 * Function creates Diagnostics Test Config which
 * @param {IStore} state - Current state of application. see {@link IStore}
 * @param {number} module_index - Index of the module in the list of inspections
 * @param {number} chosenTestIndex - Index of selected template (-1 in case multiple test cases are selected) 
 * @param {number[]} selectedTests - List of selected templates (if applicable)
 * 
 * @returns Return error if configuration is wrong otherwise returns diagnostics configuration and Index of the template (it will be -1 in case the complex template)
 * */
const prepareDiagnosticsTestConfig = (state: IStore, module_index: number, chosenTestIndex?: number, selectedTests?: number[]) : [error: string | undefined,  result: { testConfig: IDiagnosticsPiceaTestConfig, resultTestIndex: number} | undefined ] => {

    /** Identification state */
    const identification = state.process.current?.identification;
    
    /** Configuration of diagnostics module */
    const moduleConfig = (selector.workflow.getCurrentStageIndexInspectionConfig(state.process.current)?.modules.find(m => m.index === module_index)?.config as IDiagnosticsConfig)

    /** List of grades, used in the flow */
    const grades = state.process.current?.workflow.useGradesCategories ? (state.process.current.workflow.gradesCategories?.find(gc => gc.code === moduleConfig.gradesCategory)?.grades ?? []) : state.process.current?.workflow?.grades;

    /** configuration for PiceaOne diagnostic module */
    const diagnosticsConfig = moduleConfig.config as IDiagnosticsPiceaConfig

    /** Diag template index to start diagnostic with */
    let testIndex: number | undefined = diagnosticsConfig?.tests.find((_, index) => index === diagnosticsConfig.defaultTestIndex)?.index

    // Diag template index for Android device
    const deviceOs = (identification?.device?.attributes[DeviceAttributes.OS] as string)?.toUpperCase()
    if (diagnosticsConfig.androidTestIndex !== undefined && deviceOs === 'ANDROID') {
        // preSets = diagnosticsConfig?.tests?.find(t => t.index === diagnosticsConfig.androidTestIndex)?.sets;
        testIndex = diagnosticsConfig?.tests.find((_, index) => index === diagnosticsConfig.androidTestIndex)?.index
    }
    // Diag templale index for iOS device
    if (diagnosticsConfig.iosTestIndex !== undefined && deviceOs === 'IOS') {
        // preSets = diagnosticsConfig?.tests?.find(t => t.index === diagnosticsConfig.iosTestIndex)?.sets;
        testIndex = diagnosticsConfig?.tests.find((_, index) => index === diagnosticsConfig.iosTestIndex)?.index
    }

    if (diagnosticsConfig.allowChooseTest && chosenTestIndex) {
        // preSets = diagnosticsConfig?.tests?.find(t => t.index === choosedTestIndex)?.sets;
        testIndex = chosenTestIndex
    }

    /** Final diag template index to start diag with */
    const resultTestIndex = testIndex ?? diagnosticsConfig?.tests[0]?.index; // using first template if default not specified

    /** Final diag template settings */
    let testConfig = diagnosticsConfig.tests.find(t => t.index === resultTestIndex) as IDiagnosticsPiceaTestConfig

    // prepare running multi-template diag
    if (!testConfig && selectedTests && selectedTests.length > 0) {
        // initializing testConfig for multi-template testinh
        testConfig = {
            description: strings.INSPECTIONS.DIAGNOSTICS.COMPLEX.DESCRIPTION,
            index: -1,
            name: strings.INSPECTIONS.DIAGNOSTICS.COMPLEX.NAME,
            sets: []
        }

        /** Array of Piceasost test sets with test cases for multi-template diag execution*/
        let complexSets: IDiagnosticsPiceaSetConfig[] = []
        selectedTests.forEach(i => {
            /** Picesoft test sets in current diag template */
            const sets = diagnosticsConfig.tests.find(t => t.index === i)?.sets ?? []
            sets.forEach(set => {
                /** Piceasoft test set which already exists in multi-template (complexSets) */
                const currentSet = complexSets.find(complexSet => complexSet.id === set.id)
                if (!currentSet) { //adding new Piceasoft test set
                    complexSets.push(set);
                    return;
                }
                // TODO: add support for grade cathegories
                if (set.grade === "" || set.grade === undefined) {
                    currentSet.grade = "" // Unclear.... why test set grade is cleared?
                }
                if (currentSet.grade !== "") {
                    const currentSetGradeIndex = grades?.find(i => i.code === currentSet.grade)?.index ?? -1
                    const setGradeIndex = grades?.find(i => i.code === set.grade)?.index ?? -1
                    //TODO: use worstGrades function and make compatible for grade categories
                    //TODO: discuss with Denis probably errors in below code
                    if (currentSetGradeIndex > setGradeIndex) {
                        set.grade = grades?.find(i => i.index === setGradeIndex)?.code as string
                    }
                }
                let cases = new Set(currentSet.cases);
                set.cases.forEach(setCase => cases.add(setCase))
                complexSets = complexSets.filter(i => i.id !== set.id)
                complexSets.push({ ...currentSet, cases: Array.from(cases) })
            })
        });

        // return error if empty test sets found
        if (!complexSets || complexSets.find(i => i.cases.length === 0)) {
            return [strings.PROCESS.TRANSACTION.ALERT_ERROR_CONFIG, undefined]
        }

        testConfig.sets = complexSets; //assigning complexSets for execution
    }
    // return error if empty diag template
    if (!testConfig) {
        return [strings.PROCESS.TRANSACTION.ALERT_ERROR_CONFIG, undefined]
    }
    
    return [undefined, {testConfig, resultTestIndex}]
} 

export const getConnectionTypeByIdentificationMethod = (method: IdentificationMethods): PiceaConnectionTypes => {
    switch (method) {
        case IdentificationMethods.PiceaOne: return PiceaConnectionTypes.OTF;
        case IdentificationMethods.PiceaUsb: return PiceaConnectionTypes.USB;
        default: return PiceaConnectionTypes.NCD;
    }
}
